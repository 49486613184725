import React from "react";
import styled from "styled-components";
import Tooltip from "../lib/tooltip";
import {hoursToLocalTime, localTimeToHours, roundTimeToNearestTimeRes, timeRegex} from "../lib/common";
import ErrorMessage from "./error-message";
import {StyledInputMask} from "../lib/styled-components";

export const LocalRules = ({
  register,
  config,
  errors,
  setValue
}) => {
  return (
    <div>
      <InputRow>
        <InputDivider>
          <p><label htmlFor={"freeMinBetweenTime"}>Antall timer mellom to vakter</label></p>
            <StyledInputMask
                id={"freeMinBetweenTime"}
                key={"freeMinBetweenTime"}
                {...register("freeMinBetweenTime", {pattern: timeRegex()})}
                alwaysShowMask={true}
                mask={[/^([0-2])/, /([0-9])/, ":", /[0-5]/, /[0-9]/]}
                maskPlaceholder="-"
                defaultValue={hoursToLocalTime(config.freeMinBetween)}
                data-for="freeMinBetweenTime"
                data-tip="Skriv antall timers hviletid mellom to vakter som gjelder i din avdeling. Minsteverdi er 8 or maksverdi er 11. Du kan oppgi minutter, men kun i 15 minutters interval"
                onChange={(e) => {
                    let time = e.target.value;
                    if (!timeRegex().test(time)) return;

                    let hours = localTimeToHours(time);
                    if(hours > 11.0) {
                        setValue("freeMinBetweenTime", "11:00");
                        setValue(`config.freeMinBetween`, 11.0);
                        return;
                    } else if(hours < 8.0) {
                        setValue("freeMinBetweenTime", "08:00");
                        setValue(`config.freeMinBetween`, 8.0);
                        return;
                    }

                    let rounded = roundTimeToNearestTimeRes(time, 15);
                    if(rounded !== time) {
                        setValue("freeMinBetweenTime", rounded);
                        setValue(`config.freeMinBetween`, localTimeToHours(rounded));
                        return;
                    }

                    setValue(`config.freeMinBetween`, localTimeToHours(time));
                }}
            ></StyledInputMask>
            <div>{ErrorMessage(errors?.freeMinBetweenTime, 'pattern', 'Verdi må ha format HH:MM')}</div>
        </InputDivider>
        <Tooltip id="freeMinBetweenTime" />

        <InputDivider>
          <p><label htmlFor={"shiftMaxLoadRollingPeriod"}>Maks antall arbeidstimer over 7 sammenhengende dager </label></p>
          <select className={"BpSelect"}
            id={`config.shiftMaxLoadRollingPeriod`}
            key={`config.shiftMaxLoadRollingPeriod`}
            defaultValue={config.shiftMaxLoadRollingPeriod}
            {...register(`config.shiftMaxLoadRollingPeriod`, { valueAsNumber: true })}
            data-for="shiftMaxLoadRollingPeriod"
            data-tip="Velg fra rullegardinen det antall arbeidstimer over 7 sammenhengende dager som gjelder for din avdeling ved gjennomsnittsberegning av turnus over 8 uker"
          >
            <option value="48">48</option>
            <option value="49">49</option>
            <option value="50">50</option>
            <option value="51">51</option>
            <option value="52">52</option>
            <option value="53">53</option>
            <option value="54">54</option>
          </select>
        </InputDivider>

        <Tooltip id="shiftMaxLoadRollingPeriod" />
      </InputRow>
    </div >
  );
};

const InputRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  column-gap: 2em;
  background-color: #E2ECED;
  padding-left: 20px;
  padding-bottom: 10px;
`

const InputDivider = styled.div`
  min-width: 300px;
`