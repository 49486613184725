import moment from "moment";
import { dbPortalTimeDiffMinutes, taskTimeoutMinutes } from "../api/dynoape";
import styled from "styled-components";
import {Alert, Card, Divider, Flex, Spinner, Txt} from "rendition";
import {
    schemeBuGn,
    schemeRdBu,
    schemeYlOrRd,
    schemeReds,
    schemeBlues,
    schemeGreens,
    schemeGreys
} from 'd3-scale-chromatic';
import { timeMonday, timeMonth} from '@visx/vendor/d3-time';
import { rollup, group } from '@visx/vendor/d3-array';
import {PatternCase} from "./pattern-case";
import React from "react";
import ErrorPanel from "../components/sticky-error-panel";
import sanitizeHtml from "sanitize-html";

export const fullSchemeRed = [
    ["#de2d26"],
    ["#ffffff", "#de2d26"],
    schemeReds[3],
    schemeReds[4],
    schemeReds[5],
    schemeReds[6],
    schemeReds[7],
    schemeReds[8],
    schemeReds[9]
];

export const fullSchemeGreen = [
    ["#31a354"],
    ["#ffffff", "#31a354"],
    schemeGreens[3],
    schemeGreens[4],
    schemeGreens[5],
    schemeGreens[6],
    schemeGreens[7],
    schemeGreens[8],
    schemeGreens[9]
];

export const fullSchemeBlueGreen = [
    ["#26565B"],
    ["#ffffff", "#26565B"],
    schemeBuGn[3],
    schemeBuGn[4],
    schemeBuGn[5],
    schemeBuGn[6],
    schemeBuGn[7],
    schemeBuGn[8],
    schemeBuGn[9]
];

export const fullSchemeBlue = [
    ["#3182bd"],
    ["#ffffff", "#3182bd"],
    schemeBlues[3],
    schemeBlues[4],
    schemeBlues[5],
    schemeBlues[6],
    schemeBlues[7],
    schemeBlues[8],
    schemeBlues[9]
];

export const fullSchemeGrey = [
    ["#636363"],
    ["#ffffff","#636363"],
    schemeGreys[3],
    schemeGreys[4],
    schemeGreys[5],
    schemeGreys[6],
    schemeGreys[7],
    schemeGreys[8],
    schemeGreys[9],
]

export const fullSchemeRdBu = [
    ["#ef8a62"],
    ["#ef8a62", "#67a9cf"],
    schemeRdBu[3],
    schemeRdBu[4],
    schemeRdBu[5],
    schemeRdBu[6],
    schemeRdBu[7],
    schemeRdBu[8],
    schemeRdBu[9],
    schemeRdBu[10],
    schemeRdBu[11]
];

export const fullSchemeYlOrRd = [
    ["#bd0026"],
    ["#ffeda0", "#bd0026"],
    schemeYlOrRd[3],
    schemeYlOrRd[4],
    schemeYlOrRd[5],
    schemeYlOrRd[6],
    schemeYlOrRd[7],
    schemeYlOrRd[8],
    schemeYlOrRd[9],
];

/**
 * Returns the day of the week for a given date. If non-Date or Invalid date
 * is supplied returns 0.
 *
 * @param {Date} date - The date for which to determine the day of the week.
 * @return {number} The day of the week as a number, where Sunday is 6 and Monday is 0.
 */
export function getDayOfWeek(date) {
    return (date instanceof Date && !isNaN(date)) ? (date.getDay() + 6) % 7 : 0;
}

/**
 * Calculates the week number inside the month for a given date. If non-Date or
 * Invalid date is supplied returns 0.
 * 
 * Possible maximum of week numbers varies from month to month:
 * - 4 (range 0-3) for 28-days fabruary and Monday is on 1st of February
 * - 5 (range 0-4) for most typical months
 * - 6 (range 0-5) for longer months starting on weekend
 *
 * @param {Date} date - The date for which to calculate the week number.
 * @return {number} The week number ranging from 0 to 5.
 */
export function getMonthWeek(date) {
    return (date instanceof Date && !isNaN(date)) ? timeMonday.count(timeMonth(date), date) : 0;
}

/**
 * Returns the number of days in a given month.
 *
 * @param {string} dateString - The date string in the format "YYYY-MM".
 * @return {number} The number of days in the given month.
 */
export function daysInMonth (dateString) {
    const [year, month] = dateString.split('-');
    return new Date(parseInt(year), parseInt(month), 0).getDate();
}

/**
 * Retrieves the date pattern by validating the provided data using Dynomite.
 *
 * @param {Dynomite} dynomite - The Dynomite instance used for validation.
 * @param {object} data - The data to be validated.
 * @return {DatePattern} - The validated date pattern.
 */
export function getDatePattern(dynomite, data, logErrors = true) {
    let retData = null;
    try {
        retData = dynomite.dynomite.parseDatePattern(JSON.stringify(data))
    } catch (e) {
        if(logErrors) {
            if (e.payload) {
                console.error("dynomite found errors", e.payload);
            } else {
                console.error("dynomite had errors", e);
            }
        }
    }
    return retData;
}

/**
 * Get days all days from date pattern in a given period
 * @param {Dynomite} dynomite - The Dynomite instance used for validation.
 * @param {Object} pattern - The date pattern
 * @param {moment} start
 * @param {moment} end
 * @returns {[{DatePattern}]|*[]}
 */
export const getDaysFromDatePattern = (dynomite, pattern, start, end, logErrors=true) => {
    try {
        return dynomite.dynomite.daysFromDatePattern(
            getDatePattern(dynomite, pattern),
            moment(start).format('YYYY-MM-DD'),
            moment(end).format('YYYY-MM-DD'),
            ['d', 'a', 'l', 'n']);
    } catch(err) {
        if(logErrors) {
            if (err.payload) {
                console.error("dynomite found errors", err.payload);
            } else {
                console.error("dynomite had errors", err);
            }
        }
        return []
    }
}

/**
 * Get days all days from date patterns in a given period
 * @param {Dynomite} dynomite - The Dynomite instance used for validation.
 * @param {Object} patterns - The date patterns
 * @param {String} kind
 * @param {moment} start
 * @param {moment} end
 * @returns {[{DatePattern}]|*[]}
 */
export const getDaysFromDatePatterns = (dynomite, patterns, start, end, kind, logErrors=true) => {
    try {
        return dynomite.dynomite.daysFromDatePatterns(
            dynomite.dynomite.parseDatePatterns(JSON.stringify(patterns)),
            moment(start).format('YYYY-MM-DD'),
            moment(end).format('YYYY-MM-DD'),
            kind,
            ['d', 'a', 'l', 'n']).map(l => l[0]).flat()
    } catch(err) {
        if(logErrors) {
            if (err.payload) {
                console.error("dynomite found errors", err.payload);
            } else {
                console.error("dynomite had errors", err);
            }
        }
        return []
    }
}

/**
 * Helper function for shortening a string
 * @param str
 * @param size
 * @returns {string|*}
 */
export const shortenStr = (str, size=10) => {
    if(!str) return "";
    if(!size || size < 1) return str;
    return str.length > size + 1 ? str.slice(0, size).concat(".") : str;
}

/**
 * Function that takes a list of dates as parameter and returns a structure first indexed by year and then month
 * with value as a list of moments (days) in the given month and year
 * @param dates
 * @returns {*}
 */
export function fromDaysToYearsMonthsAndDays(dates) {
    if(!dates) return {};
    if(!dates.every(d => moment(d).isValid())) return{}
    return dates.reduce((acc, day) => {
        const date = moment(day);
        const year = date.year();
        const month = date.month()

        acc[year] ||= {};
        acc[year][month] ||=[]
        acc[year][month].push(date);
        return acc;
    }, {});
}

export const monthsShort = ["Jan", "Feb", "Mar", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Des"];

/**
 * Function that takes two parameters; task object and an optional list of week day indices. The following indices apply:
 *  - 0 = Sunday
 *  - 1 = Monday
 *  - 2 = Tuesday
 *  - ...
 * The function returns all days in the period of the task that matches the weekDayIndices. If weekDayIndices is empty,
 * all days in the period is returned.
 * @param task
 * @param weekDayIndices
 * @returns {moment.Moment[]}
 */
export function getWeekdaysFromTask (task, weekDayIndices)  {
    if(!(task && task.config.nrOfWeeks && task.config.startDate)) return []
    if(weekDayIndices && weekDayIndices.length > 0 &&
        !weekDayIndices.every(i => Number.isInteger(i) && i >= 0 && i <= 6)) {
        return []
    }
    const startDate = moment(task.config.startDate);
    const nrOfWeeks = parseInt(task.config.nrOfWeeks);
    if(!startDate || !nrOfWeeks || !startDate.isValid() || isNaN(nrOfWeeks) || nrOfWeeks < 0) return [];
    const endDate = moment(startDate).add(nrOfWeeks * 7, 'days');

    const days = Array.from(
        { length: endDate.diff(startDate, 'days') },
        (_, index) => startDate.clone().add(index, 'days').startOf('day')
    );
    return weekDayIndices && weekDayIndices.length > 0 ? days.filter(d => weekDayIndices.includes(moment(d).day())) : days;
}

/**
 * Method for creating a single blocked day date pattern
 * @param title
 * @param dayStr
 * @param emplId
 * @returns {{summary, optimize: boolean, series: {unit: string, nth: number, toggled: boolean, days: {sunday: boolean}, from, until: string}, count: {toggled: boolean, value: number}, groups: {toggled: boolean, value: number}, days: *[], shifts: string[], disabled: boolean, type: string, targets: [{id, type: string}], consecutive: {toggled: boolean, value: number}}}
 */
export const singleDaysDatePattern = (title, daysStr, patternCase, type="notWorking") => {
    return {
        "summary":title,
        "series":{
            "toggled":false,
            "from": daysStr ? daysStr[0]: null,
            "until":"",
            "nth":1,
            "mask": [true, true, true, true, true, true, true]
        },
        "includeDays":daysStr,
        "excludeDays": [],
        "daySegments":["D", "A", "N", "L"],
        "disabled":false,
        "optimize":false,
        "kind":type,
    }
}

export const vacationWeekDatePattern = (emplId, from, to) => {
    return {
        "summary":"Ferie " + from + " - " + to,
        "targets":[
            {
                "type":"employee",
                "id":emplId
            }
        ],
        "groups":{"toggled":false, "value":1},
        "count":{"toggled":false, "value":1},
        "consecutive":{"toggled":false, "value":1},
        "series":{
            "toggled":true,
            "from":from,
            "until":to,
            "nth":1,
            "unit":"weeks",
            "days":{"monday":true, "tuesday":true, "wednesday":true, "thursday":true, "friday":true, "saturday":true, "sunday":true
            }
        },
        "days":[],
        "shifts":["D", "A", "N", "L"],
        "disabled":false,
        "optimize":false,
        "type":"vacation",
        "patternCase": PatternCase.VACATION_WEEK
    }
}


/**
 * Union multiple day-string arrays into InternMap.
 * 
 * Union means, that in case the same day have multiple "notWorking" values,
 * it'll be merged into one. Union is intended to be used with only on the
 * same target.
 *
 * @param {Array[]} dataArrs - An array of arrays, where each inner array represents a dataset.
 * @return {InternMap} - Nested Map, with each data point grouped by month and date.
 */
export function unionDatasets(dataArrs) {
    return rollup(
        [...group(dataArrs.flat(), d => d.Date).values()].map(element => {
            return ({
                Date: element[0].Date,
                Value: [...new Set(element.map(els => els.Value))]
                    .toSorted()
                    .join(", ")
            })
        }),
        d => d[0].Value,
        d => d.Date.slice(0, 7),
        d => d.Date
    );
}

/**
 * Accumulate list of array of days into InternMap grouped by month and date.
 * Accumulation means, that multiple days will be merged into one with value
 * of count.
 *
 * @param {Array} dataArrs - An array of datasets.
 * @return {InternMap} - The accumulated data.
 */
export function accumulateDatasets(dataArrs) {
    return rollup(
        [...group(dataArrs.flat(), d => d[0])].map(([key, value]) => ({
            Date: key,
            Value: (new Set(value.map(([ , name]) => name).flat()))
        })),
        d => d[0].Value.size,
        d => d.Date.slice(0, 7),
        d => d.Date
    );
}

function stringToDate(dateString) {
    return new Date(dateString);
}

function formatDate(date) {
    return Intl.DateTimeFormat("no-NB", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit"
    }).format(date);
}

function formatDateTime(date) {
    return Intl.DateTimeFormat("no-NB", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: '2-digit',
        minute: '2-digit',
        timeZone: 'Etc/GMT-2'
    }).format(date);
}

function formatStringDate(dateString) {
    return formatDate(stringToDate(dateString));
}

function formatStringDateTime(dateString) {
    return formatDateTime(stringToDate(dateString));
}

function debounce(func, wait, immediate) {
    var timeout;

    return function executedFunction() {
        var context = this;
        var args = arguments;

        var later = function () {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };

        var callNow = immediate && !timeout;

        clearTimeout(timeout);

        timeout = setTimeout(later, wait);

        if (callNow) func.apply(context, args);
    };
}

function taskStuck(task) {
    if (!task.started) {
        return false
    }
    let taskStarted = moment(task.started, "YYYY-MM-DD HH:mm:ss").add(dbPortalTimeDiffMinutes, "minutes")
    let dur = moment.duration(moment().diff(taskStarted)).asMinutes();
    return dur >= Math.floor(taskTimeoutMinutes) + 1;
}

function maxNrOfDecimalRegex(limit) {
    let limit_str = "1"
    for (var i = 2; i <= limit; i++) {
        limit_str += "," + i;
    }
    let str = "^((\\d|[1-9]\\d+)([.,]\\d{" + limit_str + "})?|[.,]\\d{" + limit_str + "})$"
    return new RegExp(str);
}

function noDecimalRegex() {
    return /^[0-9]\d*$/;
}

function timeRegex() {
    return /^([0-1][0-9]|2[0-3]):([0-5][0-9])$/;
}

function validCharactersPattern() {
    return /^[a-zA-Z0-9 éÉæøåÆØÅäöüÄÖÜß\-_()/.,:%+\n]*$/;
}

function validCharactersRegex() {
    return RegExp('^[a-zA-Z0-9 éÉæøåÆØÅäöüÄÖÜß\\-_()/.,:%+\\n]*$');
}

function numbersAndLettersRegex() {
    return RegExp('^[a-zA-Z0-9]*$');
}

function localTimeToHours(time) {
    let hours = parseInt(time.split(":")[0]);
    let minutes = parseInt(time.split(":")[1]);
    return hours + (minutes / 60.0);
}

function hoursToLocalTime(hours) {
    return moment.utc(parseFloat(hours) * 3600 * 1000).format('HH:mm')
}

/**
 * Method for rounding a time string (val) in HH:MM format to number of hours with a minute resolution specified by arg 'res'
 * @param val
 * @param res
 * @returns {string}
 */
function roundTimeToNearestTimeRes(val, res) {
    let parts = val.split(":");
    let h = parts[0]
    let m = Math.round(parseInt(parts[1]) / res) * res

    if(m >= 60) {
        h = parseInt(h) + 1;
        m = 0;
    }
    if(h >= 24) {
        return "00:00";
    } else if (h === 23 && m >= 60 - Math.floor(res / 2)) {
        return "00:00"
    }
    return (h.toString().length < 2 ? "0" + h : h) + ":" + (m.toString().length < 2 ? "0" + m : m);
}
function emplShiftMinMaxSeqErrorMessage(enabled, minSeq, maxSeq, dayMinSeq, dayMaxSeq, eveningMinSeq, eveningMaxSeq, longMinSeq,
                                        longMaxSeq, nightMinSeq, nightMaxSeq, weekendMaxSeq, emplShiftTypes, shiftTypes) {

    let worksDay = emplShiftTypes.filter(code => shiftTypes.filter(sh => sh.code === code && sh.daySegment === 'D').length > 0).length > 0;
    let worksEvening = emplShiftTypes.filter(code => shiftTypes.filter(sh => sh.code === code && sh.daySegment === 'A').length > 0).length > 0;
    let worksLong = emplShiftTypes.filter(code => shiftTypes.filter(sh => sh.code === code && sh.daySegment === 'L').length > 0).length > 0;
    let worksNight = emplShiftTypes.filter(code => shiftTypes.filter(sh => sh.code === code && sh.daySegment === 'N').length > 0).length > 0;

    if (enabled && maxSeq === 0) {
        return "Ansatte som er tilgjengelig for vakter må ha “Maks antall vakter på rad” til en verdi høyere enn “0”";
    }

    if(worksDay && dayMaxSeq < 1) {
        return "'Maks. dagvakter på rad' må være høyere enn 0 dersom den ansatte kan jobbe dag";
    }

    if(worksEvening && eveningMaxSeq < 1) {
        return "'Maks. kveldsvakter på rad' må være høyere enn 0 dersom den ansatte kan jobbe kveld";
    }

    if(worksLong && longMaxSeq < 1) {
        return "'Maks. langvakter/mellomvakter på rad' må være høyere enn 0 dersom den ansatte kan jobbe langvakt/mellomvakt";
    }

    if(worksNight && nightMaxSeq < 1) {
        return "'Maks. nattevakter på rad' må være høyere enn 0 dersom den ansatte kan jobbe natt"
    }

    let msg = "Ingen vaktsekvenser kan overgå maks antall vakter på rad. \n " +
        "Følgende har for høy verdi: \n";
    let errors = false;
    if(enabled && maxSeq < minSeq) {
        msg += "'Min. antall vakter på rad' (avanserte innstillinger) \n";
        errors = true;
    }
    if(enabled && maxSeq < dayMinSeq) {
        msg += "'Min. dagvakter på rad'\n";
        errors = true;
    }
    if(enabled && maxSeq < dayMaxSeq) {
        msg += "'Maks. dagvakter på rad'\n";
        errors = true;
    }
    if (enabled && maxSeq < longMinSeq) {
        msg += "'Min. langvakter/mellomvakter på rad'\n";
        errors = true;
    }
    if (enabled && maxSeq < longMaxSeq) {
        msg += "'Maks. langvakter/mellomvakter på rad'\n";
        errors = true;
    }
    if (enabled && maxSeq < eveningMinSeq) {
        msg += "'Min. kveldsvakter på rad'\n";
        errors = true;
    }
    if (enabled && maxSeq < eveningMaxSeq) {
        msg += "'Maks. kveldsvakter på rad'\n";
        errors = true;
    }
    if (enabled && maxSeq < nightMinSeq) {
        msg += "'Min. nattevakter på rad'\n";
        errors = true;
    }
    if (enabled && maxSeq < nightMaxSeq) {
        msg += "'Maks. nattevakter på rad'\n";
        errors = true;
    }
    if (enabled && weekendMaxSeq > maxSeq) {
        msg += "'Maks. antall vakter på rad rundt helg'";
        errors = true;
    }

    return errors ? msg : "";
}
function emplWorksOnlyWeekendsErrorMessage(maxSeq, eveningMaxSeq, longMaxSeq, nightMinSeq, nightMaxSeq, enabled, worksOnlyWeekend) {
    if (!worksOnlyWeekend || !enabled) {
        return "";
    }
    if (maxSeq > 3) {
        return "Vennligst sett 'Maks antall vakter på rad' for denne personen til 2 eller 3. Ansatte som bare jobber helg vil ikke få tildelt flere enn 3 vakter på en helg";
    }
    if ((nightMinSeq !== 0 && nightMinSeq !== 3) || nightMinSeq !== nightMaxSeq) {
        return "Ansatte som bare jobber helg, og som har nattevakter MÅ ha minimum og maksimum antall nattevakter på rad = 3";
    }
    if (eveningMaxSeq > 3) {
        return "Ansatte som bare jobber helg kan ikke ha flere enn 3 kveldsvakter på rad";
    }
    return "";
}

const TaskCardSpinner = styled(Spinner)`
    margin-top: 0px;
`;

const TaskCardWaitingSpinner = styled(Spinner)`
    margin-top: 0px;
        div{
            color: #CA9B42;
    }
`;

function norwegianDays(day) {
    if (day === 'MONDAY') return 'Mandag';
    if (day === 'TUESDAY') return 'Tirsdag';
    if (day === 'WEDNESDAY') return 'Onsdag';
    if (day === 'THURSDAY') return 'Torsdag';
    if (day === 'FRIDAY') return 'Fredag';
    if (day === 'SATURDAY') return 'Lørdag';
    if (day === 'SUNDAY') return 'Søndag';
    if (day === 'HOLIDAYS') return "Helligdager"
}

function norwegianShiftNames(shiftCode) {
    if (shiftCode === 'D') return 'Dagvakt';
    if (shiftCode === 'A') return 'Kveldsvakt';
    if (shiftCode === 'L') return 'Langvakt/Mellomvakt';
    if (shiftCode === 'N') return 'Nattevakt';
}

function getErrorMessage(error) {
    if (error === undefined || error.data.body === undefined)
        return "Det oppstod en feil, vennligst prøv igjen senere.";

    if (error.config.url.includes("/task/") && error.data.body.errorCode === "GENERIC_ERROR")
        return "";

    if (error.data === "")
        return "Det oppstod en feil, vennligst prøv igjen senere.";

    let departmentId = error.config.url.split("/").length > 4 ? error.config.url.split("/")[4] : "";

    switch (error.data.body.errorCode) {
        case "GENERIC_ERROR": return "Det oppstod en feil, vennligst prøv igjen senere.";
        case "SAME_NAME_NOT_ALLOWED": return "Dette navnet er allerede brukt. Vennligst velg et annet. ";
        case "READ_DEPARTMENT_FORBIDDEN": return "Du har ikke tilgang til denne avdelingen.";
        case "CREATE_DEPARTMENT_LIMIT_REACHED": return "Du kan ikke opprette flere avdelinger, Kontakt support@dynamon dersom du har behov for å opprette flere avdelinger.";
        case "UPDATE_DEPARTMENT_FORBIDDEN": return "Du har ikke tilgang til å oppdatere denne avdelingen.";
        case "DEPARTMENT_NOT_FOUND": return "Denne avdelingen finnes ikke.";
        case "DELETE_DEPARTMENT_NOT_ALLOWED": return "Du kan ikke slette denne avdelingen.";
        case "READ_EMPLOYEE_FORBIDDEN": return "Du har ikke tilgang til denne ansatte.";
        case "UPDATE_EMPLOYEE_FORBIDDEN": return "Du har ikke tilgang til å oppdatere denne ansatte.";
        case "EMPLOYEE_NOT_FOUND": return "Denne ansatte finnes ikke.";
        case "READ_SHIFT_GENERATOR_TASK_FORBIDDEN": return "Du har ikke tilgang til denne bemanningsplanen.";
        case "UPDATE_SHIFT_GENERATOR_TASK_FORBIDDEN": return "Du har ikke tilgang til å oppdatere denne bemanningsplanen.";
        case "SHIFT_GENERATOR_TASK_NOT_FOUND": return "Denne bemanningsplanen finnes ikke.";
        case "UPDATE_PROCESSED_SHIFT_GENERATOR_TASK_FORBIDDEN": return "Du kan ikke oppdatere en bemanningsplan som holder på å generere.";
        case "SHIFT_GENERATOR_TASK_INVALID_CONFIG": return "Bemanningsplanen er ugyldig, vennligst fyll inn gyldige verdier i bemanningsplanen og generer";
        case "READ_SHIFT_GENERATOR_RESULT_FORBIDDEN": return "Du har ikke tilgang til dette resultatet.";
        case "UPDATE_SHIFT_GENERATOR_RESULT_FORBIDDEN": return "Du har ikke tilgang til å oppdatere dette resultatet.";
        case "SHIFT_GENERATOR_RESULT_NOT_FOUND": return "Resultat ikke funnet.";
        case "FORBIDDEN": return "Tilgang forbudt.";
        case "MISSING_ROLE": return "Bruker mangler roller.";
        case "USER_NOT_AUTHORIZED": return "Bruker har ikke tilgang.";
        case "TOKEN_EXPIRED": return "Autentisering utløpt, vennligst logg inn på nytt.";
        case "EMAIL_NOT_VERIFIED": return "Epostadressen er ikke verifisert, vennligst følg lenken i eposten du mottok for å fortsette.";
        case "INVALID_CHARACTERS_IN_INPUT": return "Du har brukt noen ugyldige tegn.";
        case "DELETE_USED_EMPLOYEE_POSITION_FORBIDDEN": return "Kan ikke slette en stilling som er tilknyttet ansatte";
        case "DELETE_USED_TRAIT_FORBIDDEN": return "Kan ikke slette en spesialkompetanse som er tilknyttet ansatte";
        case "LICENSE_MAX_EMPLOYEES_REACHED": return "Du har brukt opp ansattkvoten din. Kontakt kundeservice (support@dynamon.no) for å oppgradere lisensen din. Husk og oppgi avdelingsId: " + departmentId;
        case "LICENSE_MAX_GENERATIONS_REACHED": return "Du har brukt opp genereringskvoten din. Kontakt kundeservice (support@dynamon.no) for å oppgradere lisensen din. Husk og oppgi avdelingsId: " + departmentId;
        case "ONLY_ONE_TASK_STARTING_UP_AT_THE_SAME_TIME": return "Du har allerede køet en generering. Vennligst vent til den har startet opp før du starter en ny.";
        case "ORG_EMPLOYEE_CREATE_FORBIDDEN": return "Du har ikke rettigheter til å knytte ansatte";
        case "ORG_EMPLOYEE_EMPLOYEE_LIMIT_REACHED": return "Grensen for antall knyttede ansatte er nådd";
        case "EMPLOYEE_ALREADY_CONNECTED_TO_ORG_EMPLOYEE": return "Den ansatte er allerede knyttet en annen";
        case "UPDATE_ORG_EMPLOYEE_FORBIDDEN": return "Du har ikke rettigheter til å knytte ansatte";
        case "READ_ORG_EMPLOYEE_FORBIDDEN": return "Du har ikke rettigheter til å knytte ansatte";
        case "ORG_EMPLOYEE_SAME_DEPARTMENT_NOT_ALLOWED": return "Ansatte fra samme avdeling kan ikke være knyttet";
        case "RUNNER_CREATION_FORBIDDEN": return "Du har ikke rettigheter til å opprette en bemanningsplan-liste";
        case "RUNNER_MAX_SIZE_REACHED": return "En bemanningsplan-list kan ikke inneholder mer en 2 bemanningsplaner";
        case "TASK_ALREADY_CONNECTED_TO_RUNNER": return "En eller flere av de valgte bemanningsplanene er allerede knyttet til en liste";
        case "RUNNER_NOT_FOUND": return "Fant ikke bemanningsplan-listen";
        case "UPDATE_RUNNER_FORBIDDEN": return "Du har ikke rettigheter til å oppdatere denne bemanningsplan-listen";
        case "READ_RUNNER_FORBIDDEN": return "Du har ikke rettigheter til å se denne bemanningsplan-listen";
        default: return "Det oppstod en feil, vennligst prøv igjen senere.";
    }
}

const dateInNorwegianTimezone = function (date) {
    if (!date) {
        return "";
    }
    let mom = moment(date, "YYYY-MM-DD HH:mm:ss").add(dbPortalTimeDiffMinutes, "minutes");
    return mom.toDate().toISOString().slice(0, 10) + " " + mom.toDate().toLocaleTimeString();
}

function addWeeks(numOfWeeks, date = new Date()) {
    date.setDate(date.getDate() + ((numOfWeeks * 7) - 1));

    return date;
}

const readOnlyTaskStatuses = ['QUEUED', 'STARTING', 'PROCESSING', 'FINISHED', 'FROZEN'];

export const daySegmentsPrioritized = ["D", "A", "N", "L"];

function sortShiftCodesByDaySegment(codesToBeSorted, allShiftTypes) {
    return codesToBeSorted?.sort(function (a, b) {
        let aDaySegment = allShiftTypes?.filter(sh => sh.code === a)?.map(sh => sh.daySegment)[0];
        let bDaySegment = allShiftTypes?.filter(sh => sh.code === b)?.map(sh => sh.daySegment)[0];
        if(aDaySegment === bDaySegment) {
            return a.localeCompare(b, 'no', { numeric: true, sensitivity: 'base' })
        }
        return String(daySegmentsPrioritized.indexOf(aDaySegment))
            .localeCompare(String(daySegmentsPrioritized.indexOf(bDaySegment)), 'no', { numeric: true, sensitivity: 'base' });
    })
}

function sortShiftTypesByDaySegment(shiftTypes) {
    return shiftTypes?.sort(function (a, b) {
        if(a.daySegment === b.daySegment) {
            return a.code.localeCompare(b.code, 'no', { numeric: true, sensitivity: 'base' })
        }
       return String(daySegmentsPrioritized.indexOf(a.daySegment)).localeCompare(
           String(daySegmentsPrioritized.indexOf(b.daySegment))
       );
    });
}


function isTaskReadOnly(task) {
    return readOnlyTaskStatuses.includes(task?.status);
}
function isTaskStatusReadOnly(status) {
    return readOnlyTaskStatuses.includes(status)
}
function isTaskStatusInProcessState(status) {
    return ['QUEUED', 'STARTING', 'PROCESSING'].includes(status)
}
function isTaskInProcessState(task) {
    return isTaskStatusInProcessState(task?.status);
}

export const allTaskResponseOptionsQueryParam = "?includeTaskEmployees=true&" +
    "includeTaskShiftTypes=true&" +
    "includeTaskTraits=true&" +
    "includeTaskPositions=true&" +
    "includeTaskDatePatterns=true&" +
    "includeTaskHolidays=true";

function emptyDemand(daySegment, traits={}) {
    return {
        "minimumNrOfEmployees":0,
        "maximumNrOfEmployees":0,
        "minimumResponsibleEmployees":0,
        "traitRequirements":traits,
        "positionRequirements":{}
    }
}

function withoutShiftDefinition (t) {
    const copy = JSON.parse(JSON.stringify(t));
    Object.keys(copy?.config?.weeklyCoverDemands)?.forEach(day => {
        Object.keys(copy.config.weeklyCoverDemands[day]).forEach(sh => {
            delete copy.config.weeklyCoverDemands[day][sh]['shiftDefinition'];
        })
    });
    if(copy.config.dailyCoverPatches) {
        Object.keys(copy?.config?.dailyCoverPatches)?.forEach(day => {
            Object.values(copy.config.dailyCoverPatches[day]).forEach((patch, idx) => {
                if (patch.op === "add" && patch.path.split("/").length === 2) {
                    delete copy.config.dailyCoverPatches[day][idx].value['shiftDefinition']
                }
            })
        });
    }
    return copy;
}

function withShiftDefinition(t) {
    const copy = JSON.parse(JSON.stringify(t));
    const shTypes = copy.config.shiftTypes;
    Object.keys(copy.config.weeklyCoverDemands).forEach(day => {
        Object.keys(copy.config.weeklyCoverDemands[day]).forEach(sh => {
            copy.config.weeklyCoverDemands[day][sh]['shiftDefinition'] = {
                'start': shTypes[sh].start,
                'coreTimeStart': shTypes[sh].coreTimeStart,
                'shiftHoursMax': shTypes[sh].shiftHoursMax,
                'shiftHoursMin': shTypes[sh].shiftHoursMin,
                'coreTimeEnd': hoursToLocalTime(parseFloat(localTimeToHours(shTypes[sh].coreTimeStart)) + parseFloat(shTypes[sh].shiftHoursMin)),
                'end': hoursToLocalTime(parseFloat(localTimeToHours(shTypes[sh].start)) + parseFloat(shTypes[sh].shiftHoursMax))
            };
        })
    });
    Object.keys(copy.config.dailyCoverPatches).forEach(day => {
        Object.values(copy.config.dailyCoverPatches[day]).forEach((patch, idx) => {
            if((patch.op === "add" || patch.op === "replace") && patch.path.split("/").length === 2) {
                let sh = patch.path.split("/")[1];
                copy.config.dailyCoverPatches[day][idx].value = {
                    "shiftDefinition": {
                        'start': shTypes[sh].start,
                        'coreTimeStart': shTypes[sh].coreTimeStart,
                        'shiftHoursMax': shTypes[sh].shiftHoursMax,
                        'shiftHoursMin': shTypes[sh].shiftHoursMin,
                        'coreTimeEnd': hoursToLocalTime(parseFloat(localTimeToHours(shTypes[sh].coreTimeStart)) + parseFloat(shTypes[sh].shiftHoursMin)),
                        'end': hoursToLocalTime(parseFloat(localTimeToHours(shTypes[sh].start)) + parseFloat(shTypes[sh].shiftHoursMax))
                    },
                    ...copy.config.dailyCoverPatches[day][idx].value
                }
            }
        })
    });
    return copy;
}

function getDaySegment(task, sh) {
    return task.config.shiftTypes[sh]?.daySegment;
}

function shiftTypeLimitInOrdinaryBp() {
    return 14;
}

function shiftTypeLimitInCustomBp() {
    return shiftTypeLimitInOrdinaryBp() + 4;
}

const daySegmentNames = {
    "D": "Dagvakt",
    "A": "Kveldsvakt",
    "N": "Nattevakt",
    "L": "Langvakt/mellomvakt"
};

export const formatSyntaxError = (err) => {
    if (!err) {
        return <Txt>
            Vi kunne ikke validere. Dette er en feil i Dynamon.
        </Txt>
    }
    return <div>
        <Txt>
            Vi kunne ikke validere. Dette er en feil i Dynamon.
        </Txt>
        <details style={{ border: "none" }}>
            <summary style={{ fontSize: "14px" }}>detaljer</summary>
            <Card small style={{
                background: "black",
                // backdropFilter: "invert() hue-rotate(180deg) brightness(350%) contrast(70%) saturate(300%)",
                color: "white",
            }}>
            </Card>
        </details>
    </div>
}

/**
 * Helper method for converting extended employees rest data to valid dynomite extended employees config
 * @param department
 * @param employees
 * @param restTraits
 * @param restPositions
 * @param restShiftTypes
 * @param startDate
 * @param nrOfWeeks
 * @returns {{traits: *, holidays: (string[]|*[]), nrOfWeeks: number, positions: *, shiftTypes: *, employees: *[], startDate: string}}
 */
export const convertToExtendedEmployeeConfig = ({department, employees, restTraits, restPositions,
                                                    restShiftTypes,
                                                    startDate,
                                                    nrOfWeeks}) => {
    return {
        employees: (employees ?? []).filter(e => e.enabled),
        shiftTypes: (restShiftTypes ?? []).reduce((acc, type) => {
            acc[type.code] = type;
            return acc;
        }, {}),
        positions: (restPositions ?? []).reduce((acc, position) => {
            acc[position.id] = position.name;
            return acc;
        }, {}),
        traits: (restTraits ?? []).reduce((acc, trait) => {
            acc[trait.id] = trait.name;
            return acc;
        }, {}),
        nrOfWeeks: nrOfWeeks ?? 52,
        holidays: department?.countryRules?.holidays ? Object.keys(department.countryRules.holidays) : [],
        startDate: startDate && moment(startDate).isValid() ? moment(startDate).format('YYYY-MM-DD') : moment().startOf('isoWeek').add(1, 'week').format('YYYY-MM-DD')
    }
}

export const dynomiteErrorPanel = (dynomite, configErrors, dynomiteInternalError, nrOfErrorPanelsOpen=0, setNrOfErrorPanelsOpen=() => {}, isWarning=false) => {
    const errorText = isWarning ? "advarsler" : "problemer";
    return <ErrorPanel
        nrOfErrorPanelsOpen={nrOfErrorPanelsOpen}
        setNrOfErrorPanelsOpen={setNrOfErrorPanelsOpen}
        okLabel={<Alert success plaintext>Ingen {errorText} funnet</Alert>}
        errLabel={<Flex>
            <Alert danger={!isWarning} warning={isWarning} plaintext />
            {configErrors && (
                dynomiteInternalError
                    ? <Txt>Tolkning av data feilet (du får fortsatt lov til å lagre)</Txt>
                    : <Txt bold>{configErrors.length} {errorText} (du får fortsatt lov til å lagre)</Txt>
            )
            }
        </Flex>}
        isError={configErrors.length > 0 || dynomiteInternalError}
        isWarning={isWarning && configErrors.length > 0}
    >
        {configErrors &&
            <div style={{ overflow: "scroll", height: "250px" }}>
                <div style={{ margin: "32px 44px" }}>
                    { configErrors.length > 0 ?
                        configErrors.map((err, i) => <>
                            {i !== 0 && <Divider />}
                            <Alert plaintext>
                                {err.tag !== "syntax"
                                    ? <div
                                        key={err}
                                        dangerouslySetInnerHTML={{
                                            __html: sanitizeHtml(
                                                dynomite.dynomite.formatError(err)
                                            )
                                        }}
                                    />
                                    : formatSyntaxError(err)
                                }
                            </Alert>
                        </>)
                        : <div>Kontakt kundesupport support@dynamon.no</div>
                    }
                </div>
            </div>
        }
    </ErrorPanel>
}

export const scrollHeight = (timeout=200) => {
    setTimeout(() => {
        window.scrollTo({
            top: document.documentElement.scrollHeight,
            behavior: 'smooth'
        });
    }, timeout)
}

export {
    stringToDate, formatDate, formatStringDate, formatStringDateTime, debounce, taskStuck, noDecimalRegex, isTaskInProcessState,
    maxNrOfDecimalRegex, timeRegex, validCharactersRegex, validCharactersPattern, emplShiftMinMaxSeqErrorMessage, emplWorksOnlyWeekendsErrorMessage, TaskCardSpinner, TaskCardWaitingSpinner, localTimeToHours,
    norwegianDays, norwegianShiftNames, getErrorMessage, dateInNorwegianTimezone, addWeeks, isTaskReadOnly, isTaskStatusReadOnly,
    numbersAndLettersRegex, emptyDemand, getDaySegment, shiftTypeLimitInOrdinaryBp, shiftTypeLimitInCustomBp, sortShiftCodesByDaySegment,
    sortShiftTypesByDaySegment, hoursToLocalTime, withShiftDefinition, withoutShiftDefinition, daySegmentNames, roundTimeToNearestTimeRes,
    isTaskStatusInProcessState
};

