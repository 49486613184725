import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { Button } from "rendition";
import { Card, CardRow } from "../lib/styled-components";
import {isTaskInProcessState, TaskCardSpinner, TaskCardWaitingSpinner, taskStuck} from "../lib/common";
import Tooltip from "../lib/tooltip";

const TaskCard = props => {
  return (
    <TaskCardContainer style={{ backgroundColor: props.task.status === "FROZEN" ? "#a5c5d9": "white" }}>
      <TaskCardContainerRow>
        <TaskName>{props.task.name} {props.task.status === "FROZEN" ? " (fryst for redigering)" : ""}</TaskName>
        <div style={{ marginLeft: "20px", marginTop: "5px", position: "relative" }}>
          {props.task.status === "PROCESSING" && !taskStuck(props.task) && <TaskCardSpinner label="Generering pågår"></TaskCardSpinner>}
          {props.task.status === "QUEUED" && <TaskCardWaitingSpinner label="Venter på at generering skal starte"></TaskCardWaitingSpinner>}
          {props.task.status === "STARTING" && <TaskCardWaitingSpinner label="Starter generering"></TaskCardWaitingSpinner>}
        </div>
        <div style={{ marginLeft: "auto", position: "relative", backgroundColor: props.task.status === "FROZEN" ? "#a5c5d9": "white" }}>
          <Button primary onClick={() => props.setShowTaskModal({ "show": true, "copyFromId": props.task.id })} mr="10px" data-for="copy" data-tip="Dersom du ønsker å kopiere en eksisterende Bemanningsplan klikker du på “kopier” den aktuelle Bemanningsplanen">Kopier</Button><Tooltip id="copy" />
          {/* {(props.task.status === "PREPARING" || props.task.status === "ERROR_IN_CONFIG" || props.task.status === "FINISHED_WITH_NO_RESULTS") && <Button mr="10px" primary onClick={() => props.setShowConfirmGenerateModal({ "show": true, "generateId": props.task.id })}>Generer</Button>} */}
          {props.task.status === "PROCESSING" && <ResultPage departmentId={props.departmentId} taskId={props.task.id} text="Se fremgang" type="progressing"></ResultPage>}
          {(props.task.status === "PROCESSING" && taskStuck(props.task)) && <Button mr="10px" danger onClick={() => props.showStopped()}>Generering har stoppet</Button>}
          {props.task.status === "ERROR_IN_CONFIG" && <Button danger mr="10px" onClick={() => props.showError(props.task)}>Generering feilet</Button>}
          {props.task.status === "ERROR_GENERIC" && <Button danger mr="10px">Generering stoppet uventet</Button>}
          {props.task.status === "FINISHED_WITH_NO_RESULTS" && <NavLink style={{ marginLeft: "0px" }} className="nav-link" to={`/avdelinger/${props.departmentId}/bemanningsplaner/${props.task.id}`}><Button danger primary>Ferdig uten resultat</Button></NavLink>}
          {props.task.status === "FINISHED" && <ResultPage departmentId={props.departmentId} taskId={props.task.id} text="Generering ferdig" type="success"></ResultPage>}
          <NavLink style={{ marginLeft: "0px" }} className="nav-link" to={`/avdelinger/${props.departmentId}/bemanningsplaner/${props.task.id}`} data-for="copy" data-tip="Dersom du vil se på Bemanningsplanen klikker du her. Du kan ikke legge til endringer i en allerede generert turnus. Da må du kopiere den og legge inn endringer, og generere på nytt"><Button primary>Åpne</Button></NavLink><Tooltip id="open" />
          {(!isTaskInProcessState(props.task) || taskStuck(props.task)) && <Button danger onClick={() => props.setShowDeleteTaskModal({"show": true, "taskId": props.task.id, "name": props.task.name})}>Slett</Button>}

        </div>
      </TaskCardContainerRow>
    </TaskCardContainer>
  );
};

export default TaskCard;

const TaskCardContainer = styled(Card)`
  min-height: 100px;
  margin-bottom: 15px;
`;

const TaskCardContainerRow = styled(CardRow)`
  padding-top: 30px;
  padding-right: 20px;
`;

const TaskName = styled.h3`
  margin-top: 5px;
`;

const ResultPage = props => {
  return (<NavLink style={{ marginLeft: "0px" }}
    className="nav-link"
    to={`/avdelinger/${props.departmentId}/turnusplaner/${props.taskId}`}>
    {props.type === 'success' && <Button success data-for={props.taskId} data-tip="Generering av turnus er ferdig. Ved å klikke her kommer du direkte til det beste resultatet som Dynamon har generert">{props.text}</Button>}
    {props.type === 'progressing' && <Button info data-for={props.taskId} data-tip="Når Dynamon genererer turnus vil du kunne til enhver tid se de 10 beste resultatene den har funnet her">{props.text}</Button>}
    <Tooltip id={props.taskId} />
  </NavLink>)
}
