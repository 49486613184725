import React, { useState } from "react";
import styled from "styled-components";
import { Collapsible } from 'grommet';
import { Card, Flex } from "rendition";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons/faChevronUp';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown';

// const ErrorPanel =

const ErrorPanel = styled.div`
  border-radius: 10px;
  background: ${props => props.isError && !props.open
    ? (props.isWarning ? "rgba(254, 235, 235, 0.4)": "rgba(254, 235, 235, 0.4)")
    : "rgba(255, 255, 255, 0.4)"
  };
  backdrop-filter: blur(15px) contrast(50%) brightness(130%);
  border: 1px solid white;
  outline: ${props => props.isError && !props.open
    ? (props.isWarning ? "2px solid #FFA500" : "2px solid #cc2424")
    : "1px solid #e5e7f0"
  };
  position: sticky;
  z-index: ${props => props.open ? "1000" : "0"};
  width: ${props => props.open ? "80%" : "20%"};
  bottom: ${props => props.isError
    ? props.open ? "-10px" : "25px"
    : "25px"
  };
  padding: 25px 25px 25px 25px;
  float: right;
  margin: 0 25px 25px auto;
  box-shadow: ${props => props.open
    ? "0 31px 41px 0 rgb(32 42 53 / 20%), 0 2px 16px 0 rgb(32 42 54 / 8%)"
    : "0 3px 6px 0 rgb(23 24 24 / 8%), 0 4px 10px 0 rgb(23 24 24 / 12%)"
  };
  transition: all 0.2s cubic-bezier(.25,.8,.25,1);
`

const ErrorLabel = styled.div`
  opacity: ${props => props.visible ? 1 : 0};
  grid-row: 1;
  grid-column: 1;
  transition: all 0.2s cubic-bezier(.25,.8,.25,1); 
`

export default props => {
  const [openState, setOpen] = useState(false);
  const open = openState && props.isError;
  return <ErrorPanel open={open} isError={props.isError} isWarning={props.isWarning}>
    <Flex
      alignItems="center"
      justifyContent="space-between"
      style={{
        cursor: props.isError ? "pointer" : "unset",
        minHeight: "20px",
        margin: "-25px",
        padding: "25px"
      }}
      onClick={() => {
        if(!open) props.setNrOfErrorPanelsOpen(props.nrOfErrorPanelsOpen + 1);
        else props.setNrOfErrorPanelsOpen(props.nrOfErrorPanelsOpen - 1);
        props.isError && setOpen(!open);
      }}
    >
      <div style={{display: "grid"}}>
        <ErrorLabel visible={!props.isError}>
          {props.okLabel}
        </ErrorLabel>
        <ErrorLabel visible={props.isError}>
          {props.errLabel}
        </ErrorLabel>
      </div>
      <FontAwesomeIcon
          color='#ccc'
          icon={open ? faChevronUp : faChevronDown}
          style={{
            paddingLeft: "1em",
            opacity: props.isError ? 1 : 0
          }} />
    </Flex>
    <div style={{pointerEvents: "none"}}>
      <Collapsible open={open}>
        <div style={{paddingTop: "25px"}}>
          <div style={{pointerEvents: "auto"}}>
            <Card style={{padding: 0, background: "transparent", backdropFilter: "brightness(102%) blur(20px)"}}>
              {props.children}
            </Card>
          </div>
        </div>
      </Collapsible>
    </div>
  </ErrorPanel>
}