import {InputRow} from "../lib/styled-components";
import React from "react";
import styled from "styled-components";
import {daySegmentNames, hoursToLocalTime, localTimeToHours, sortShiftCodesByDaySegment} from "../lib/common";


export const ShiftStats = ({task, employees}) => {

    const weeklyCoverDemandsDays = Object.keys(task.config.weeklyCoverDemands).filter(day => day !== "HOLIDAYS");

    const shiftCodes = sortShiftCodesByDaySegment([...new Set(weeklyCoverDemandsDays.flatMap(day => {
        return Object.keys(task.config.weeklyCoverDemands[day]);
    }))], Object.values(task.config.shiftTypes));
    const shiftCodeElements = shiftCodes.map(sh =>
        <div key={sh}>
            <p><label>{sh}</label></p>
        </div>
    );

    const unusedShiftCodes = sortShiftCodesByDaySegment(Object.keys(task.config.shiftTypes).filter(sh => !shiftCodes.includes(sh)),
        Object.values(task.config.shiftTypes));
    const unusedShiftCodesElements = unusedShiftCodes.map(sh =>
        <div key={sh}>
            <p><label>{sh}</label></p>
        </div>
    );
    const unusedDaySegmentElements = unusedShiftCodes.map(sh =>
        <div key={"unusedDs" + sh}>
            <p><label>{daySegmentNames[task.config.shiftTypes[sh].daySegment]}</label></p>
        </div>
    );

    const fromElementsGen = (prefix, codes) => {
        return codes.map(sh =>
            <div key={prefix + sh}>
                <p><label>{task.config.shiftTypes[sh].coreTimeStart}</label></p>
            </div>
        );
    }

    const toElementsGen = (prefix, codes) => {
        return codes.map(sh => {
            let type = task.config.shiftTypes[sh];
            let start = localTimeToHours(type.coreTimeStart);
            let end = parseFloat(start) + type.shiftHoursMin;
            return <div key={prefix + sh}>
                <p><label>{hoursToLocalTime(end)}</label></p>
            </div>
        });
    }

    const fromElements = fromElementsGen("from", shiftCodes);
    const toElements = toElementsGen("to", shiftCodes);
    const unusedFromElements = fromElementsGen("unused-from", unusedShiftCodes);
    const unusedToElements = toElementsGen("unused-to", unusedShiftCodes);

    const days = ["MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY", "SUNDAY"];

    const weekly = new Map(days.map(day => [day, shiftCodes.map(sh => {
        let val = "0 (0)";
        if(weeklyCoverDemandsDays.includes(day)) {
            if(Object.keys(task.config.weeklyCoverDemands[day]).includes(sh)) {
                val =  task.config.weeklyCoverDemands[day][sh]["minimumNrOfEmployees"] + " (" +
                    task.config.weeklyCoverDemands[day][sh]["maximumNrOfEmployees"] + ")";
            }
        }
        return <p key={day.concat(sh)}><label>{val}</label></p>
    })]));

    const weeklySum = shiftCodes.map(sh => {
        let min = 0;
        let max = 0;
        weeklyCoverDemandsDays.forEach(day => {
            if(Object.keys(task.config.weeklyCoverDemands[day]).includes(sh)) {
                min += parseInt(task.config.weeklyCoverDemands[day][sh]["minimumNrOfEmployees"]);
                max += parseInt(task.config.weeklyCoverDemands[day][sh]["maximumNrOfEmployees"]);
            }
        })
        return <p key={"weeklySum".concat(sh)}><label>{min + " (" + max + ")"}</label></p>
    })

    const weeklyPerSegment = new Map(days.map(day => {
        let minSum = 0;
        let maxSum = 0;
        let sums = [day, ["D", "A", "N", "L"].map(ds => {
            let min = 0;
            let max = 0;
            Object.keys(task.config.weeklyCoverDemands[day]).forEach(sh => {
                if(task.config.shiftTypes[sh].daySegment === ds) {
                    min += task.config.weeklyCoverDemands[day][sh]["minimumNrOfEmployees"];
                    max += task.config.weeklyCoverDemands[day][sh]["maximumNrOfEmployees"];
                }
            })
            minSum += min;
            maxSum += max;
            return <p key={"ds".concat(day).concat(ds)}><label>{min + " (" + max + ")"}</label></p>;
        })];
        sums[1].push(<p key={"ds".concat(day).concat("sum")}><label>{minSum + " (" + maxSum + ")"}</label></p>);
        return sums;
    }));

    const weeklySumPerSegment = ["D", "A", "N", "L"].map(ds => {
        let min = 0;
        let max = 0;
        weeklyCoverDemandsDays.forEach(day => {
           Object.keys(task.config.weeklyCoverDemands[day]).forEach(sh => {
               let type = task.config.shiftTypes[sh];
               if(type.daySegment === ds) {
                   min += task.config.weeklyCoverDemands[day][sh]["minimumNrOfEmployees"];
                   max += task.config.weeklyCoverDemands[day][sh]["maximumNrOfEmployees"];
               }
           })
        });
        return {ds: ds, min: min, max: max};
    });

    const weekHours = task.config.fullVacancyRateHours;
    let manYears = 0.0;

    const manYearsPerSegment = ["D", "A", "N", "L"].map(ds => {
        let hours = 0.0;
        weeklyCoverDemandsDays.forEach(day => {
            Object.keys(task.config.weeklyCoverDemands[day]).filter(sh => task.config.shiftTypes[sh].daySegment === ds).forEach(sh => {
                let type = task.config.shiftTypes[sh];
                hours += (type.shiftHoursMin - (parseFloat(type.breakMinutes) / 60)) * parseInt(task.config.weeklyCoverDemands[day][sh].minimumNrOfEmployees);
            })
        });
        const min = (hours/weekHours).toFixed(2);
        manYears += parseFloat(min);
        return <p key={"manYearsPerDs".concat(ds)}><label>{min}</label></p>;
    });

    let upperManYears = 0.0;

    const upperManYearsPerSegment = ["D", "A", "N", "L"].map(ds => {
        let maxHours = 0.0;
        weeklyCoverDemandsDays.forEach(day => {
            Object.keys(task.config.weeklyCoverDemands[day]).filter(sh => task.config.shiftTypes[sh].daySegment === ds).forEach(sh => {
                let type = task.config.shiftTypes[sh];
                maxHours += (type.shiftHoursMax - (parseFloat(type.breakMinutes) / 60)) * parseInt(task.config.weeklyCoverDemands[day][sh].maximumNrOfEmployees);
            })
        });
        const max = (maxHours/weekHours).toFixed(2);
        upperManYears += parseFloat(max);
        return <p key={"upperManYearsPerDs".concat(ds)}><label>{max}</label></p>;
    })

    const employeeManYears = parseFloat(employees.filter(e => e.enabled)
        .map(e => e.vacancyRate).reduce((acc, curr) => acc + curr, 0) / 100).toFixed(2);

    const empty = <p><label>-</label></p>;

    const manYearsSummary = employeeManYears > upperManYears ? +1 : (employeeManYears < manYears ? -1 : 0)

    return (
        <div>
            <label>Vaktkategori sammendrag</label>
            <p></p>
            <InputRow>
                <InputDivider style={{minWidth: "230px"}}>
                    <p><label><b>Vaktkategori</b></label></p>
                    <p><label>Dagvakt</label></p>
                    <p><label>Kveldsvakt</label></p>
                    <p><label>Nattevakt</label></p>
                    <p><label>Langvakt/mellomvakt</label></p>
                    <p><label>Sum</label></p>
                </InputDivider>
                <InputDivider></InputDivider>
                <InputDivider></InputDivider>
                <InputDivider>
                    <p><label><b>Man</b></label></p>
                    {weeklyPerSegment.get("MONDAY")}
                </InputDivider>
                <InputDivider>
                    <p><label><b>Tir</b></label></p>
                    {weeklyPerSegment.get("TUESDAY")}
                </InputDivider>
                <InputDivider>
                    <p><label><b>Ons</b></label></p>
                    {weeklyPerSegment.get("WEDNESDAY")}
                </InputDivider>
                <InputDivider>
                    <p><label><b>Tors</b></label></p>
                    {weeklyPerSegment.get("THURSDAY")}
                </InputDivider>
                <InputDivider>
                    <p><label><b>Fre</b></label></p>
                    {weeklyPerSegment.get("FRIDAY")}
                </InputDivider>
                <InputDivider>
                    <p><label><b>Lør</b></label></p>
                    {weeklyPerSegment.get("SATURDAY")}
                </InputDivider>
                <InputDivider>
                    <p><label><b>Søn</b></label></p>
                    {weeklyPerSegment.get("SUNDAY")}
                </InputDivider>
                <InputDivider></InputDivider>
                <InputDivider>
                    <p><label><b>Sum</b></label></p>
                    {weeklySumPerSegment.map(dsSum => <p key={"weeklySumPerSegment"}><label>{dsSum.min + " (" + dsSum.max + ")"}</label></p>)}
                    <Hr ml="-786px" w="850px"></Hr>
                    <p><label>{weeklySumPerSegment.reduce((acc, obj) => [acc[0] + obj.min, acc[1] + obj.max], [0, 0]).join(' (')})</label></p>
                </InputDivider>
            </InputRow>
            <p></p>
            <label>Årsverk (ferie og helligdager ikke inkludert)</label>
            <p></p>
            <InputRow>
                <InputDivider style={{minWidth: "230px"}}>
                    <p><label><b>Vaktkategori</b></label></p>
                    <p><label>Dagvakt</label></p>
                    <p><label>Kveldsvakt</label></p>
                    <p><label>Nattevakt</label></p>
                    <p><label>Langvakt/mellomvakt</label></p>
                    <p><label>Sum</label></p>
                </InputDivider>
                <InputDivider>
                    <p><label><b>Minimumsbehov</b></label></p>
                    {manYearsPerSegment}
                    <p><label>{manYears.toFixed(2)}</label></p>
                </InputDivider>
                <InputDivider></InputDivider>
                <InputDivider>
                    <p><label><b>Maksimumsbehov</b></label></p>
                    {upperManYearsPerSegment}
                    <p><label>{upperManYears.toFixed(2)}</label></p>
                </InputDivider>
                <InputDivider></InputDivider>
                <InputDivider>
                    <p><label><b>Tilgjengelig årsverk</b></label></p>
                    {empty}
                    {empty}
                    {empty}
                    {empty}
                    <p><label>{employeeManYears}</label></p>
                </InputDivider>
                <InputDivider></InputDivider>
                <InputDivider>
                    <p><label><b>Oppsummering</b></label></p>
                    {empty}
                    {empty}
                    {empty}
                    {empty}
                    <Hr ml="-795px" w="940px"></Hr>
                    <p style={{color: manYearsSummary > 0 ? "blue" : (manYearsSummary < 0 ? "red": "green")}}>
                        <label>{manYearsSummary > 0 ? "OVERBEMANNET" : (manYearsSummary < 0 ? "UNDERBEMANNET": "BALANSE")}</label>
                    </p>
                </InputDivider>
            </InputRow>
            <p></p>
            <label>Vaktkode sammendrag</label>
            <p></p>
            <InputRow>
                <InputDivider>
                    <p><label><b>Kode</b></label></p>
                    {shiftCodeElements}
                </InputDivider>
                <InputDivider>
                    <p><label><b>Kjernetid start</b></label></p>
                    {fromElements}
                </InputDivider>
                <InputDivider>
                    <p><label><b>Kjernetid slutt</b></label></p>
                    {toElements}
                </InputDivider>
                <InputDivider></InputDivider>
                <InputDivider>
                    <p><label><b>Man</b></label></p>
                    {weekly.get("MONDAY")}
                </InputDivider>
                <InputDivider>
                    <p><label><b>Tir</b></label></p>
                    {weekly.get("TUESDAY")}
                </InputDivider>
                <InputDivider>
                    <p><label><b>Ons</b></label></p>
                    {weekly.get("WEDNESDAY")}
                </InputDivider>
                <InputDivider>
                    <p><label><b>Tors</b></label></p>
                    {weekly.get("THURSDAY")}
                </InputDivider>
                <InputDivider>
                    <p><label><b>Fre</b></label></p>
                    {weekly.get("FRIDAY")}
                </InputDivider>
                <InputDivider>
                    <p><label><b>Lør</b></label></p>
                    {weekly.get("SATURDAY")}
                </InputDivider>
                <InputDivider>
                    <p><label><b>Søn</b></label></p>
                    {weekly.get("SUNDAY")}
                </InputDivider>
                <InputDivider></InputDivider>
                <InputDivider>
                    <p><label>Sum</label></p>
                    {weeklySum}
                </InputDivider>
            </InputRow>
            <p></p>
            <label>Ubrukte vaktkoder</label>
            <p></p>
            <InputRow>
                <InputDivider>
                    <p><label><b>Kode</b></label></p>
                    {unusedShiftCodesElements}
                </InputDivider>
                <InputDivider>
                    <p><label><b>Vaktkategori</b></label></p>
                    {unusedDaySegmentElements}
                </InputDivider>
                <InputDivider>
                    <p><label><b>Kjernetid start</b></label></p>
                    {unusedFromElements}
                </InputDivider>
                <InputDivider>
                    <p><label><b>Kjernetid slutt</b></label></p>
                    {unusedToElements}
                </InputDivider>
            </InputRow>
            <p></p>
        </div>
    );
}
const InputDivider = styled.div`
  min-width: 70px;
  margin-bottom: 10px;
`
const Hr = styled.hr`
    width: ${props => props.w};
    margin-left: ${props => props.ml};
    margin-top: -5px;
    position: absolute;
`;