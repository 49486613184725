import React, {useContext, useEffect, useState} from "react";
import dynomiteContext from "./dynomite";
import _ from "lodash";
import moment from "moment";
import { getDatePattern } from "../lib/common";
import { applyPatch } from "rfc6902";
import GroupStackBar from '../components/vis-components/group-stack-bar';
import styled from "styled-components";
import { Flex, Button, ButtonGroup } from "rendition";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';
import {dynoapeAPI} from "../api/dynoape";

const TaskWeekendVisualization = ({
    departmentId,
    taskId
}) => {
    const dynomite = useContext(dynomiteContext);
    const [selectedDS, setSelectedDS] = useState('d');
    const [currentOffset, setCurrentOffset] = useState(0);
    const weekOffset = 12;
    const [fetching, setFetching] = useState(true);
    const [config, setConfig] = useState({});
    const taskOptions = "includeTaskEmployees=true&includeTaskShiftTypes=true&includeTaskTraits=true&includeTaskPositions=true" +
        "&includeTaskDatePatterns=true&includeTaskHolidays=true"

    const getData = async () => {
        let t = await dynoapeAPI.get(`/api/v1/department/${departmentId}/task/${taskId}?${taskOptions}`);
        setConfig(t.config);
        setFetching(false);
    }

    useEffect(() => {
        getData();
    }, [])

    const getDaySegmentPositions = (selectedDaySeg, startDate, nrOfWeeks) => {
        const endDate = moment(startDate).add(nrOfWeeks, 'weeks').subtract(1, 'day').format('YYYY-MM-DD');
        const weekendDp = dynomite.dynomite.parseDatePattern(JSON.stringify({
            summary: '',
            kind: 'working',
            series: {
                toggled: true,
                from: moment(startDate).startOf('isoWeek').format('YYYY-MM-DD'),
                until: endDate,
                nth: 1,
                mask: [false, false, false, false, false, false, true]
            },
            daySegments: ['D', 'A', 'N', 'L'],
            includeDays: [],
            excludeDays: [],
            disabled: false,
            optimize: false
        }));
        let sundays = dynomite.dynomite.employeeSundays(
            {
                enabled: true,
                id: "",
                position: "",
                traits: [],
                shiftTypeCodes: [],
                vacancyRate: 100.0,
                weekendPatterns: [weekendDp],
                blockedPatterns: [],
                holidayPatterns: [],
                vacationPatterns: []
            },
            moment(startDate).format('YYYY-MM-DD'),
            endDate
        );
        let calc_sundays = sundays.reduce(
            (calc_sundays, sunday) => {
                // patch Sunday
                let custom_sunday = config.dailyCoverPatches[sunday] ?? [];
                let sunday_descr = _.cloneDeep(config.weeklyCoverDemands['SUNDAY']);
                applyPatch(sunday_descr, custom_sunday);

                // create data structure that is digestible by visualization:
                // {
                //     'yyyy-mm-dd': { --sunday date
                //         'min': { 'min': min_acc_per_day_segment },
                //         'max': { 'max': max_acc_per_day_segment },
                //         'avail': { 'pos_id': 0 }, --prepared, to be calculated
                //         'positions': { 'pos_id': max_pos_per_day_segment },
                //     }
                // }
                calc_sundays[sunday] = Object.keys(sunday_descr).reduce(
                    (sunday_ds, shift_type) => {
                        let ds = config.shiftTypes[shift_type].daySegment.toLowerCase();
                        if(ds === selectedDaySeg){
                            Object.entries(
                                sunday_descr[shift_type].positionRequirements
                            ).forEach(([key, value]) => {
                                sunday_ds.positions[key] += value
                            })
                            sunday_ds.min += sunday_descr[shift_type].minimumNrOfEmployees;
                            sunday_ds.max += sunday_descr[shift_type].maximumNrOfEmployees;
                        }
                        return sunday_ds;
                    },
                    {
                        min: 0,
                        avail: Object.keys(config.positions).reduce(
                            (positions, position) => {
                                positions[position] = 0;
                                return positions;
                            },
                            {}
                        ),
                        positions: Object.keys(config.positions).reduce(
                            (positions, position) => {
                                positions[position] = 0;
                                return positions;
                            },
                            {}
                        ),
                        max: 0
                    }
                );
                return calc_sundays;
            },
            {}
        );
        
        // calculate employee availability
        config.employees.forEach(empl => {

            // get employee blocked Sundays - applicable only for selected day segment
            let empl_blocks = new Set(
                [
                    ...empl.weekendPatterns
                        .filter(dp =>
                            dp.kind === 'notWorking')
                        .map(dp => dynomite.dynomite.daysFromDatePattern(getDatePattern(dynomite, dp), startDate, endDate)),
                    ...empl.blockedPatterns
                        .map(dp => dynomite.dynomite.daysFromDatePattern(getDatePattern(dynomite, dp), startDate, endDate)),
                    ...empl.vacationPatterns
                        .map(dp => dynomite.dynomite.daysFromDatePattern(getDatePattern(dynomite, dp), startDate, endDate))
                ]
            );

            // If employee works on selected day segment, add to availability
            // However, only if it's not blocked
            if(empl.shiftTypeCodes
                .filter(shiftType => config.shiftTypes[shiftType].daySegment.toLowerCase() === selectedDaySeg)
                .length > 0
            ) {
                const employee_copy = _.cloneDeep(empl)
                employee_copy.weekendPatterns = employee_copy.weekendPatterns.map(dp => getDatePattern(dynomite, dp))
                employee_copy.blockedPatterns = employee_copy.blockedPatterns.map(dp => getDatePattern(dynomite, dp))
                employee_copy.holidayPatterns = employee_copy.holidayPatterns.map(dp => getDatePattern(dynomite, dp))
                employee_copy.vacationPatterns = employee_copy.vacationPatterns.map(dp => getDatePattern(dynomite, dp))
                dynomite.dynomite.employeeSundays(employee_copy, moment(startDate).format('YYYY-MM-DD'), endDate)
                    .filter(sunday => !empl_blocks.has(sunday))
                    .forEach(sunday => {
                        calc_sundays[sunday].avail[employee_copy.position] += 1
                    });
            }
        })
    
        // At the end, translate pos_id into pos_name.
        // Make sure Sundays are sorted and presented as array.
        return Object.keys(calc_sundays).toSorted().map(sunday => ({
            date: sunday,
            min: {min: calc_sundays[sunday].min},
            avail: Object.keys(calc_sundays[sunday].avail).reduce(
                (avail, position) => {
                    avail[config.positions[position]] = calc_sundays[sunday].avail[position];
                    return avail  
                },
                {}
            ),
            pos: Object.keys(calc_sundays[sunday].positions).reduce(
                (positions, position) => {
                    positions[config.positions[position]] = calc_sundays[sunday].positions[position];
                    return positions
                },
                {}
            ),
            max: {max: calc_sundays[sunday].max}
        }));
    }

    const AddWeekOffsetToLocalDate = (date, offset) => {
        return new Intl.DateTimeFormat('nb-NO', {
            day: 'numeric',
            month: 'short',
            year: 'numeric'
        }).format(moment(date).add(offset, 'w').toDate());
    }

    const AddWeekOffsetToDate = (date, offset) => {
        return moment(date).add(offset, 'w').format('YYYY-MM-DD');
    }

    return (!fetching &&
        <RuleCard style={{ borderTopLeftRadius: "0px" }}>
            <InputRow>
                <InputDivider>
                    <p><label>Velg vakttype</label></p>
                    <ButtonGroup>
                        <Button primary={selectedDS === 'd'} onClick={() => setSelectedDS('d')}>Dag</Button>
                        <Button primary={selectedDS === 'a'} onClick={() => setSelectedDS('a')}>Kveld</Button>
                        <Button primary={selectedDS === 'l'} onClick={() => setSelectedDS('l')}>Lang</Button>
                        <Button primary={selectedDS === 'n'} onClick={() => setSelectedDS('n')}>Natt</Button>
                    </ButtonGroup>
                </InputDivider>
                <InputDivider>
                    <p><label>Velg 12-ukers periode</label></p>
                    <Flex 
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Button
                            style={{ "padding": "1em" }}
                            disabled={(currentOffset - weekOffset) < 0}
                            onClick={() => setCurrentOffset(currentOffset - weekOffset)}>
                            <FontAwesomeIcon icon={faChevronLeft} />
                        </Button>
                        <h4 style={{ "margin": 0, "textAlign": "center", "width": 175}}>
                            {AddWeekOffsetToLocalDate(config.startDate, currentOffset)}
                        </h4>
                        <h4 style={{ "margin": 0}}>:</h4>
                        <h4 style={{ "margin": 0, "textAlign": "center", "width": 175}}>
                            {AddWeekOffsetToLocalDate(config.startDate, currentOffset + weekOffset)}
                        </h4>
                        <Button
                            style={{ "padding": "1em" }}
                            disabled={(currentOffset + weekOffset) > config.nrOfWeeks}
                            onClick={() => setCurrentOffset(currentOffset + weekOffset)}>
                            <FontAwesomeIcon icon={faChevronRight} />
                        </Button>
                    </Flex>
                </InputDivider>
                <InputDivider>
                    <p style={{"width": 450, "margin-bottom": 0}}><label><strong>NB:</strong> Kolonnene i midten representerer tilgjengelighet og krav til tilgjengelighet.</label></p>
                </InputDivider>
            </InputRow>
            <GroupStackBar
                data={
                    getDaySegmentPositions(
                        selectedDS,
                        AddWeekOffsetToDate(config.startDate, currentOffset),
                        Math.min(config.nrOfWeeks- currentOffset, weekOffset)
                    )
                }
                width={1350}
                height={350}
                hidden={false}
                margin={{
                    top: 80,
                    left: 40,
                    right: 20,
                    bottom: 60
                }}
            />
        </RuleCard>
    )
  };
  
export default TaskWeekendVisualization;

const RuleCard = styled.div`
    background-color: #FFF;
    border-radius: 10px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
    margin-bottom: 25px;
    padding: 25px;
    width: 1350px;
    button:first-child {
        padding-left: 30px;
    }
    button:last-child {
        padding-right: 30px;
    }
`;
const InputRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    column-gap: 2em;
    background-color: #E2ECED;
    padding-left: 20px;
    padding-bottom: 10px;
    margin-bottom: 25px;
`
const InputDivider = styled.div`
    min-width: 300px;
    margin-bottom: 10px;
`