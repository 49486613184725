/* eslint-disable no-unused-vars */
import React, {useState, useEffect, useRef} from "react";
import { useParams } from "react-router-dom";
import _ from 'lodash';
import { dynoapeAPI } from "../api/dynoape";
import { Modal, Tabs, Tab, Button } from "rendition";
import styled from "styled-components";
import { DayInputCustom } from "./day-input-custom";
import Calendar from 'rc-year-calendar';
import '../lib/i18n/rc-year-calendar.no';
import moment from 'moment';
import { isTaskReadOnly } from "../lib/common";
import {createPatch} from "rfc6902";

const TaskWeekCustom = ({
  days,
  task,
  setTask,
  positions,
  traitNames,
  department,
  doPatch,
  validateButton,
  setUpdateTaskOngoing
}) => {
  const [customDate, setCustomDate] = useState(false);
  const [selectedDay, setSelectedDay] = useState(false);
  const [selectedShift, setSelectedShift] = useState(null);
  const [selectedYear, setSelectedYear] = useState(moment(task.config.startDate).format("YYYY"));
  const [markedDates, setMarkedDates] = useState([]);
  const [customizedDays, setCustomizedDays] = useState([]);
  const [redDays, setRedDays] = useState([]);
  const [weekDates, setWeekDates] = useState([]);
  const [displayCalendar, setDisplayCalendar] = useState(true);
  const [displayDay, setDisplayDay] = useState(false);
  const [turnusDates, setTurnusDates] = useState([]);
  const [showResetWeekModal, setShowResetWeekModal] = useState(false);
  const [weekCustomized, setWeekCustomized] = useState(false);
  const { departmentId, taskId } = useParams();
  const dateRef = useRef();
  const yearRef = useRef();
  const taskRef = useRef();

  dateRef.current = customDate;
  yearRef.current = selectedYear;
  taskRef.current = task;

  const dayIsEdited = (d) => {
    return Object.keys(task.config.dailyCoverPatches).includes(d);
  }

  const resetWeek = async (weekDates) => {
    setUpdateTaskOngoing(true);
    setSelectedShift(null);
    setSelectedDay(false);
    let newDailyCoverPatches = _.cloneDeep(task.config.dailyCoverPatches);
    weekDates.forEach(date => {
      delete newDailyCoverPatches[date];
    });

    let patch = createPatch(task.config.dailyCoverPatches, newDailyCoverPatches).map(p => {
      p.path = "/config/dailyCoverPatches" + p.path;
      return p;
    })
    const response = await dynoapeAPI.patch(`/api/v1/department/${departmentId}/task/${taskId}`, patch);
    setTask(response);
    setCustomDate(false)
    setDisplayCalendar(true);
    setUpdateTaskOngoing(false);
  }

  //needed to reset the view after first render
  useEffect(() => {
    setCustomDate(false);
  }, []);

  const setWeeklyCoverDemands = (dailyCoverPatches) => {
    let newTask = { ...task };
    newTask.config.dailyCoverPatches = dailyCoverPatches;
    setTask(newTask)
  }

  const selectDay = (date) => {
    setDisplayDay(false);

    setCustomDate(date);
    setSelectedDay(moment(date).format("dddd").toUpperCase());

    setTimeout(() => {
      setDisplayDay(true);
      setDisplayCalendar(false);
    }, 0);

  };

  const onTabActivate = [];
  const onActive = async (i) => {
    for (let i = 0; i < onTabActivate.length; i++) {
      let hook = onTabActivate[i];
      if (!await hook()) {
        return;
      }
    }
    selectDay(weekDates[i]);
  };

  useEffect(() => {
    if (task.config.dailyCoverPatches)
      setCustomizedDays(Object.keys(task.config.dailyCoverPatches).map(date => {
        return {
          startDate: moment(date).toDate(),
          endDate: moment(date).toDate()
        }
      }));

    if (department.countryRules.holidays)
      setRedDays(Object.keys(department.countryRules.holidays).map(date => {
        return {
          startDate: moment(date).toDate(),
          endDate: moment(date).toDate()
        }
      }));

    setMarkedDates([...customizedDays, ...redDays, ...[{
      startDate: moment(customDate).toDate(),
      endDate: moment(customDate).toDate()
    }]]);

    let customizedDates = Object.keys(task.config.dailyCoverPatches).map(date => {
      return date
    });

    if (_.intersection(customizedDates, weekDates).length) {
      setWeekCustomized(true);
    } else {
      setWeekCustomized(false);
    }
  }, [customDate, displayCalendar, task]);

  useEffect(() => {
    let start = moment(task.config.startDate);
    let dates = []
    for (let d = 0; d < task.config.nrOfWeeks * 7; d++) {
      let date = start.clone()
      dates.push(moment(date.add(d, 'd')).format('YYYY-MM-DD'));
    }
    setTurnusDates(dates);
  }, [])

  const dayTabs = Object.keys(days).map((day, index) =>
    <HighlightedTab edited={dayIsEdited(moment(weekDates[index]).format('YYYY-MM-DD'))} key={day} title={`${days[day]} ${moment(weekDates[index]).format('D/M')}`} className="test">
      {displayDay ? <DayInputCustom
        doPatch={doPatch}
        isRedDay={Object.keys(department.countryRules.holidays).includes(moment(weekDates[index]).format('YYYY-MM-DD'))}
        date={moment(weekDates[index]).format('YYYY-MM-DD')}
        day={Object.keys(department.countryRules.holidays).includes(moment(weekDates[index]).format('YYYY-MM-DD')) ? "HOLIDAYS" : day}
        selectedShift={selectedShift}
        setSelectedShift={setSelectedShift}
        task={task}
        paramDailyCoverPatches={task.config.dailyCoverPatches}
        positions={positions}
        traitNames={traitNames}
        customDate={customDate}
        setTask={setTask}
        validateButton={validateButton}
        setUpdateTaskOngoing={setUpdateTaskOngoing}
        setCoverDemands={setWeeklyCoverDemands} /> : <DayCard
        />}
    </HighlightedTab>
  );

  return (
    <div style={{ maxWidth: "1400px" }}>
      {displayCalendar ? <div className="calendar-task">
        <Calendar
          minDate={moment(turnusDates[0]).toDate()}
          maxDate={moment(turnusDates[turnusDates.length - 1]).toDate()}
          language="no"
          year={selectedYear}
          onYearChanged={({ currentYear }) => setSelectedYear(currentYear)}
          displayWeekNumber={true}
          style="custom"
          dataSource={turnusDates.map(d => {
            return {
              startDate: moment(d).toDate(),
              endDate: moment(d).toDate()
            }
          })}
          onDayClick={({ date }) => {
            selectDay(moment(date).format("YYYY-MM-DD"));
            setWeekDates(Object.keys(days).map(day => moment(date).week(moment(date).week()).isoWeekday(day).format('YYYY-MM-DD')));
          }}
          customDataSourceRenderer={(day) => {
            const monthNr = parseInt(day.parentNode.parentNode.parentNode.parentNode.getAttribute("data-month-id"));
            const dayNr = day.innerHTML;
            const date = `${yearRef.current}-${String(`${monthNr + 1}`).padStart(2, '0')}-${String(`${dayNr}`).padStart(2, '0')}`;

            if (date === dateRef.current)
              day.classList.add('day-selected');

            if (Object.keys(taskRef.current.config.dailyCoverPatches).includes(date))
              day.classList.add('day-customized');

            if (Object.keys(department.countryRules.holidays).includes(date))
              day.classList.add('day-red');
          }}
        />
      </div> :
        <WeekContainer>
          {customDate && <h3>UKE {moment(customDate, "YYYY-MM-DD").isoWeek()} {customDate.split("-")[0]}</h3>}
          <Button primary onClick={() => { setDisplayCalendar(true); }}>
            &lt;- Tilbake til kalender
          </Button>
        </WeekContainer>
      }
      {customDate &&
        <div style={{ paddingTop: "25px" }}>
          <Tabs activeIndex={Object.keys(days).indexOf(selectedDay)} onActive={onActive}>
            {dayTabs}
            <div style={{ marginLeft: "auto" }} />
            {(!isTaskReadOnly(task) && weekCustomized) && <Button tertiary plain style={{ height: 0, zIndex: 1 }}
              onClick={() => setShowResetWeekModal(true)}
            >
              <div style={{
                display: "flex",
                alignItems: "center",
                color: "#527699",
                gap: "1em",
              }}>
                <img src="/assets/icons/roll.png" />
                Tilbakestill uke
              </div>
              {showResetWeekModal && <Modal
                title="Er du sikker på at du vil fjerne tilpasningene for denne uken?"
                cancel={() => setShowResetWeekModal(false)}
                done={() => {
                  resetWeek(weekDates);
                  setShowResetWeekModal(false);
                }}
                action="Tilbakestill"
                cancelButtonProps={{
                  children: 'Avbryt',
                }}
              />}
            </Button>}
            <div style={{ width: "35px" }} />
          </Tabs>
        </div>
      }
    </div>
  )
};

export default TaskWeekCustom;

const WeekContainer = styled.div`
  display: flex;
  background: white;
  align-items: center;
  flex-direction: column;
  padding-bottom: 25px;
  margin-bottom: 12px;
`;

const DayCard = styled.div`
  background-color: #FFF;
  width: 1400px;
  height: 1100px;
`;
const HighlightedTab = styled(Tab).attrs({ className: 'StyledTab' })`
  font-weight: ${props => (props.edited ? '800' : 'normal')};
  font-style: ${props => (props.edited ? 'italic' : 'normal')};
`;