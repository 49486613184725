
export class PatternCase {
    static WEEKEND_PATTERN = "weekendPatterns";
    static BLOCKED_PATTERN = "blockedPatterns";
    static VACATION = "vacationPatterns";
    static HOLIDAY_PATTERN = "holidayPatterns"

    static translate(patternCase) {
        switch (patternCase) {
            case this.WEEKEND_PATTERN: return "Helgeplanlegger";
            case this.BLOCKED_PATTERN: return "Annet fravær";
            case this.VACATION: return "Ferieplanlegger";
            case this.HOLIDAY_PATTERN: return "Helligdagsplanlegger";
            default: return "Fravær";
        }
    }

    static getIncludeQueryParams(patternCase) {
        const all = "?includeWeekendPatterns=true&includeBlockedPatterns=true&includeVacationPatterns=true&includeHolidayPatterns=true";
        switch (patternCase) {
            case this.BLOCKED_PATTERN: return "?includeBlockedPatterns=true&includeWeekendPatterns=true&includeVacationPatterns=true";
            case this.WEEKEND_PATTERN: return all;
            case this.VACATION: return all;
            case this.HOLIDAY_PATTERN: return all;
            default: return "";
        }
    }
}