/* eslint-disable no-unused-vars */
import React, {useContext, useEffect, useState} from "react";
import {Box, Divider, Flex, Input, Txt} from "rendition";
import Accordion from "../accordion";
import BlockedPatternsPickers from "./blocked-patterns-picker";
import DatePicker from "react-datepicker";
import moment from "moment/moment";
import Icon from "../../lib/icon";
import Tooltip from "../../lib/tooltip";
import dynomiteContext from "../dynomite";
import {getDaysFromDatePattern} from "../../lib/common";

export default ({
                    departmentId,
                    employees,
                    setEmployees,
                    reset}) => {

    useEffect(() => {
        return () => {
            reset();
        }
    }, []);

    const dynomite = useContext(dynomiteContext);
    const [searchFilters, setSearchFilters] = useState({employee: undefined, startDate: undefined, endDate: undefined});

    /**
     * Method for applying search filters to the employees and date patterns
     * @param employeeList
     * @returns {*}
     */
    const applyFilters = (employeeList) => {
        return employeeList
            .filter(e => !searchFilters.employee || e.name.toLowerCase().includes(searchFilters.employee.toLowerCase()))
            .map(e => {
                const blocked = (e.blockedPatterns || [])
                    .filter(p =>
                        (!searchFilters.startDate && !searchFilters.endDate)
                        || getDaysFromDatePattern(dynomite, {...p, disabled: false}, searchFilters.startDate, searchFilters.endDate).length > 0)
                const weekendPatterns = (e.weekendPatterns || [])
                    .filter(p =>
                        (!searchFilters.startDate && !searchFilters.endDate)
                        || getDaysFromDatePattern(dynomite, {...p, disabled: false}, searchFilters.startDate, searchFilters.endDate).length > 0)
                const vacationPatterns = (e.vacationPatterns || [])
                    .filter(p =>
                        (!searchFilters.startDate && !searchFilters.endDate)
                        || getDaysFromDatePattern(dynomite, {...p, disabled: false}, searchFilters.startDate, searchFilters.endDate).length > 0)
                return {...e, blockedPatterns: blocked, weekendPatterns: weekendPatterns, vacationPatterns: vacationPatterns}
            });
    }

    return (
        <>
            <Flex style={{marginBottom: "20px"}}>
                <div data-for="employee-search" data-tip="Søk etter ansatte" style={{marginRight: "80px"}}><i>Søk etter ansatte</i></div>
                <Tooltip id="employee-search" />
                <Input style={{width: "200px"}} value={searchFilters.employee} placeholder="" onChange={e => setSearchFilters({...searchFilters, employee: e.target.value})} />
            </Flex>
            <Flex style={{marginBottom: "20px"}}>
                <div style={{marginRight: "87px"}}><i>Filtrer etter datoer</i></div>
                <Box>
                    <DatePicker
                        showWeekNumbers
                        locale="nb"
                        dateFormat="yyyy-MM-dd"
                        selected={searchFilters.startDate}
                        startDate={searchFilters.startDate}
                        endDate={searchFilters.endDate}
                        maxDate={moment().add(10, "years").toDate()}
                        selectsRange
                        shouldCloseOnSelect={false}
                        onChange={dates => {
                            const [start, end] = dates;
                            setSearchFilters({...searchFilters, startDate: start, endDate: end})
                        }}
                        customInput={
                            <Flex
                                style={{
                                    alignItems: "center",
                                    cursor: "pointer",
                                    justifyContent: "space-between",
                                    width: "100%",
                                }}>
                                <Icon name="date" f="right" />
                            </Flex>
                        }
                    >
                    </DatePicker></Box>
                {searchFilters.startDate && searchFilters.endDate && <div style={{marginLeft: "10px"}}>
                    {moment(searchFilters.startDate).format("DD.MM.YYYY")} - {moment(searchFilters.endDate).format("DD.MM.YYYY")}
                    <span style={{cursor: "pointer"}} onClick={() => setSearchFilters({...searchFilters, startDate: undefined, endDate: undefined})}
                    ><i>&emsp;fjern</i></span>
                </div>}
            </Flex>
            <Divider />
            <Box>
            <Accordion
                hidePanelDivider={true}
                keyFunc={(item) => item.id}
                items={applyFilters(employees).map(e => EmployeeRow({
                    startOpen: false, scrollIntoView: false, dirty: false, employee: e, departmentId: departmentId,
                    setEmployees: setEmployees
                }))}
            />
            </Box>
        </>
    )
};

/**
 * Accordion element where each element represents an employee row. And insie each employee, are the date patterns
 * @param startOpen
 * @param scrollIntoView
 * @param dirty
 * @param employee
 * @param setEmployees
 * @param departmentId
 * @param searchFilters
 * @param props
 * @returns {{scrollIntoView, label: JSX.Element, panel: JSX.Element, key, startOpen}}
 * @constructor
 */
const EmployeeRow = ({startOpen, scrollIntoView, dirty, employee, setEmployees, departmentId, searchFilters, ...props }) => {
    return {
        startOpen: startOpen,
        key: employee.id,
        label: <Flex alignItems="center" justifyContent="space-between" style={{fontFamily: "Montserrat Alternates"}}>
            <Box style={{ "flex": "1 0 10%"}}><Txt style={{ "marginBottom": "1em" }}>{employee.name}</Txt></Box>
            <Box style={{ "flex": "1 0 10%"}}><Txt style={{ "marginBottom": "1em" }}>{employee.vacancyRate + "%"}</Txt></Box>
            <Box style={{ "flex": "1 0 10%"}}><Txt style={{ "marginBottom": "1em" }}>{employee.restPosition?.name}</Txt></Box>
        </Flex>,
        scrollIntoView: scrollIntoView,
        panel: <BlockedPatternsPickers employee={employee} setEmployees={setEmployees} departmentId={departmentId} searchFilter={searchFilters}></BlockedPatternsPickers>
    }
}
