import React, { useEffect, useState } from "react";
import {Button, Modal, Spinner} from "rendition";
import {dynoapeAPI} from "../api/dynoape";
import { useParams } from "react-router-dom";
import { Table } from "rendition";
import {
    hoursToLocalTime,
    localTimeToHours, noDecimalRegex,
    numbersAndLettersRegex,
    sortShiftTypesByDaySegment,
    timeRegex
} from "../lib/common";
import Select from "react-select";
import Tooltip from "../lib/tooltip";
import {NumberInput, SolidInput, StyledInputMask} from "../lib/styled-components";
import {useForm} from "react-hook-form";
import styled from "styled-components";
import Icon from "../lib/icon";

const ShiftTypesTable = () => {
    const { setValue, register} = useForm({});
    const defaults = {
        "D": {
            "start": "07:00",
            "end": "15:00",
            "coreTimeStart": "08:00",
            "coreTimeEnd": "14:00",
            "daySegment": "D",
            "minuteResolution":15,
            "breakMinutes": 0
        },
        "A": {
            "start": "14:45",
            "end": "22:00",
            "coreTimeStart": "15:30",
            "coreTimeEnd": "21:30",
            "daySegment": "A",
            "minuteResolution":15,
            "breakMinutes": 0
        },
        "L": {
            "start": "08:00",
            "end": "21:30",
            "coreTimeStart": "09:00",
            "coreTimeEnd": "20:30",
            "daySegment": "L",
            "minuteResolution":15,
            "breakMinutes": 0
        },
        "N": {
            "start": "21:30",
            "end": "07:15",
            "coreTimeStart": "22:00",
            "coreTimeEnd": "07:00",
            "daySegment": "N",
            "minuteResolution":15,
            "breakMinutes": 0
        }
    }

    const emptyShiftType = defaults["D"];
    const { departmentId } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [showShiftTypeModal, setShowModal] = useState({ "show": false, "id": ""});
    const [showDeleteModal, setShowDeleteModal] = useState({ "show": false, "id": "" });
    const [showInvalidTypeError, setShowInvalidTypeError] = useState({ "show": false, "msg": "", "field": "none" });
    const [shiftType, setShiftType] = useState({})
    const [shiftTypes, setShiftTypes] = useState([]);

    const daySegmentLabels = (type) => {
        switch (type) {
            case "D": return "Dagvakt"
            case "A": return "Kveldsvakt"
            case "L": return "Langvakt/mellomvakt"
            case "N": return "Nattevakt"
        }
    }

    const daySegmentOptions = [
        { value: 'D', label: daySegmentLabels('D')},
        { value: 'A', label: daySegmentLabels('A')},
        { value: 'N', label: daySegmentLabels('N')},
        { value: 'L', label: daySegmentLabels('L')}
    ];

    const minuteResolutionOptions = [
        {value: '15', label: '15 minutter'},
        {value: '30', label: '30 minutter'},
        {value: '60', label: '60 minutter'}
    ]

    const columns = [
        {
            field: 'code',
            label: <span style={{ width: "80px", display: "block" }}>Kode</span>,
            sortable: false,
            render: function displayColumn(data) {
                return <span>{data}</span>
            }
        },
        {
            field: 'daySegment',
            label: <span style={{ width: "80px", display: "block" }}>Vaktkategori</span>,
            sortable: false,
            render: function displayColumn(data) {
                return <div>{daySegmentLabels(data)}</div>
            }
        },
        {
            field: 'start',
            label: <span style={{ width: "80px", display: "block" }}>Starter</span>,
            sortable: false,
            render: function displayColumn(data) {
                return <span>{data}</span>
            }
        },
        {
            field: 'coreTimeStart',
            label: <span style={{ width: "80px", display: "block" }}>Kjernetid start</span>,
            sortable: false,
            render: function displayColumn(data) {
                return <span>{data}</span>
            }
        },
        {
            field: 'shiftHoursMin',
            label: <span style={{ width: "80px", display: "block" }}>Kjernetid slutt</span>,
            sortable: false,
            render: function displayColumn(data, shType) {
                let coreStart = localTimeToHours(shType.coreTimeStart);
                let coreEnd = parseFloat(coreStart) + data
                return <div>{hoursToLocalTime(coreEnd)}</div>
            }
        },
        {
            id: "1",
            field: 'shiftHoursMax',
            label: <span style={{ width: "80px", display: "block" }}>Slutter</span>,
            sortable: false,
            render: function displayColumn(data, shType) {
                let start = localTimeToHours(shType.start);
                let end = parseFloat(start) + data
                return <div>{hoursToLocalTime(end)}</div>
            }
        },
        {
            field: 'breakMinutes',
            label: <span style={{ width: "80px", display: "block" }}>Ubetalt pause</span>,
            sortable: false,
            render: function displayColumn(data) {
                return <div>{data} minutter</div>
            }
        },
        {
            id: "2",
            field: 'shiftHoursMax',
            label: <span style={{ width: "80px", display: "block" }}>Vaktlengde</span>,
            sortable: false,
            render: function displayColumn(data, type) {
                return <div>{hoursToLocalTime(parseFloat(data) - parseFloat(type.breakMinutes) / 60)}</div>
            }
        },
        /*{
            field: 'minuteResolution',
            label: <span style={{ width: "100px", display: "block" }}>Tidsinterval</span>,
            sortable: false,
            render: function displayColumn(data) {
                return <div>{data} minutter</div>
            }
        },*/
        {
            field: 'id',
            label: ' ',
            render: function displayColumn(data, obj) {
                return <Button primary onClick={() => {
                    let end = hoursToLocalTime(localTimeToHours(obj.start) + parseFloat(obj.shiftHoursMax));
                    let coreTimeEnd = hoursToLocalTime(localTimeToHours(obj.coreTimeStart) + parseFloat(obj.shiftHoursMin));
                    let type = {"code": obj.code, "daySegment": obj.daySegment, "minuteResolution": obj.minuteResolution,
                        "start": obj.start, "coreTimeStart": obj.coreTimeStart, "end": end, "coreTimeEnd": coreTimeEnd,
                        "breakMinutes": obj.breakMinutes}
                    setShiftType(type);
                    setValue("start", type.start);
                    setValue("coreTimeStart", type.coreTimeStart);
                    setValue("coreTimeEnd", type.coreTimeEnd);
                    setValue("end", type.end);
                    setValue("breakMinutes", type.breakMinutes)
                    setShowModal({ "show": true, "id": data});

                }} style={{ float: "right"}}><Icon name="edit-w" /></Button>;
            }
        },
        {
            field: 'id',
            label: ' ',
            render: function displayColumn(data) {
                return <Button danger onClick={() => setShowDeleteModal({ "show": true, "id": data })} style={{ float: "right"}}><Icon name="trash-w"/></Button>;
            }
        }
    ];

    const closeModal = () => {
        setShowInvalidTypeError({ "show": false, "msg": "", "field": "none" });
        setShiftType({});
        setValue("code", emptyShiftType.code);
        setValue("start", emptyShiftType.start);
        setValue("coreTimeStart", emptyShiftType.coreTimeStart);
        setValue("coreTimeEnd", emptyShiftType.coreTimeEnd);
        setValue("end", emptyShiftType.end);
        setValue("daySegment", emptyShiftType.daySegment);
        setValue("breakMinutes", emptyShiftType.breakMinutes)
        setShowModal({ "show": false, "id": "" });
    }

    useEffect(() => {
        const setSortedShiftTypes = async () => {
            let sh = sortShiftTypesByDaySegment(await dynoapeAPI.get(`/api/v1/department/${departmentId}/shift-types`));
            setShiftTypes(sh);
            setIsLoading(false);
        }
        setSortedShiftTypes();
    }, []);

    const validTimes = {
        "D": {
            "earliestStart": "06:00",
            "latestStart": "11:00",
            "earliestEnd": "12:00",
            "latestEnd": "17:00"
        },
        "A": {
            "earliestStart": "12:00",
            "latestStart": "19:00",
            "earliestEnd": "19:00",
            "latestEnd": "00:00"
        },
        "L": {
            "earliestStart": "00:00",
            "latestStart": "17:00",
            "earliestEnd": "12:00",
            "latestEnd": "00:00"
        },
        "N": {
            "earliestStart": "17:00",
            "latestStart": "23:45",
            "earliestEnd": "01:00",
            "latestEnd": "09:00"
        }
    }

    const between = (val, min, max) => {
        return val >= min && val <= max;
    }

    function valid (type) {
        let code = type.code;
        if(!code || code.trim().length < 1) {
            setShowInvalidTypeError({"show": true, "msg": "Kode er påkrevd", "field": "code"});
            return false;
        }
        if(shiftTypes.filter(sh => !showShiftTypeModal.id || sh.id !== showShiftTypeModal.id).map(c => c.code).includes(type.code)) {
            setShowInvalidTypeError({"show": true, "msg": "Det finnes allerede en vaktkode med kode " + type.code, "field": "code"});
            return false;
        }
        if(! numbersAndLettersRegex().test(code)) {
            setShowInvalidTypeError({"show": true, "msg": "Kode kan kun inneholde tall og bokstaver", "field": "code"});
            return false;
        }

        let start = type.start;
        let startHours = parseFloat(localTimeToHours(start));
        if(isNaN(startHours) || isNaN(parseFloat(startHours)) || !timeRegex().test(start)) {
            setShowInvalidTypeError({"show": true, "msg": "'Starter' er ugyldig. Følgende format er påkrevd: HH:MM", "field": "start"});
            return false;
        }

        let coreTimeStart = type.coreTimeStart;
        let coreStartHours = parseFloat(localTimeToHours(coreTimeStart));
        if(isNaN(coreStartHours) || isNaN(parseFloat(coreStartHours)) || !timeRegex().test(coreTimeStart)) {
            setShowInvalidTypeError({"show": true, "msg": "'Kjernetid start klokken' er ugyldig. Følgende format er påkrevd: HH:MM", "field": "coreTimeStart"});
            return false;
        }

        let end = type.end;
        let endHours = parseFloat(localTimeToHours(end));
        if(isNaN(endHours) || isNaN(parseFloat(endHours)) || !timeRegex().test(end)) {
            setShowInvalidTypeError({"show": true, "msg": "'Slutter' er ugyldig. Følgende format er påkrevd: HH:MM", "field": "end"});
            return false;
        }

        let coreTimeEnd = type.coreTimeEnd;
        let coreEndHours = parseFloat(localTimeToHours(coreTimeEnd));
        if(isNaN(coreEndHours) || isNaN(parseFloat(coreEndHours)) || !timeRegex().test(coreTimeEnd)) {
            setShowInvalidTypeError({"show": true, "msg": "'Kjernetid slutt klokken' er ugyldig. Følgende format er påkrevd: HH:MM", "field": "coreTimeEnd"});
            return false;
        }

        if(type.daySegment === "N") {
            let genericNError = "Nattevakter må ha 'Kjernetid slutt klokken' and 'Slutter' dagen etter vaktstart, og vakten kan vare maksimalt 24 timer"
            if(startHours < endHours || coreStartHours < coreEndHours) {
                setShowInvalidTypeError({"show": true, "msg": genericNError, "field": "none"});
                return false;
            }
        } else {
            if(startHours > (endHours === 0 ? 24 : endHours)) {
                setShowInvalidTypeError({"show": true, "msg": "'Slutter' må være etter 'Starter'", "field": "end"});
                return false;
            }
            if(coreStartHours > (coreEndHours === 0 ? 24 : coreEndHours)) {
                setShowInvalidTypeError({"show": true, "msg": "'Kjernetid slutt klokken' må være etter 'Kjernetid start klokken'", "field": "coreTimeEnd"});
                return false;
            }
        }

        if(startHours > coreStartHours) {
            setShowInvalidTypeError({"show": true, "msg": "'Kjernetid start klokken' må være etter 'Starter'", "field": "coreTimeStart"});
            return false;
        }
        if((endHours === 0 ? 24 : endHours) < (coreEndHours === 0 ? 24 : coreEndHours)) {
            setShowInvalidTypeError({"show": true, "msg": "'Kjernetid slutt klokken' må være før 'Slutter'", "field": "coreTimeEnd"});
            return false;
        }

        let times = validTimes[type.daySegment];

        if(! (between(startHours, localTimeToHours(times.earliestStart), localTimeToHours(times.latestStart))
            && between(coreStartHours, localTimeToHours(times.earliestStart), localTimeToHours(times.latestStart)))) {
            setShowInvalidTypeError({"show": true, "msg":
                    "'Starter' og 'Kjernetid start klokken' må være mellom " + times.earliestStart + " og " + times.latestStart, "field": "none"});
            return false;
        }

        let earliestEnd = localTimeToHours(times.earliestEnd);
        let latestEnd = localTimeToHours(times.latestEnd);
        if(! (between((endHours === 0 ? 24 : endHours), (earliestEnd === 0 ? 24 : earliestEnd), (latestEnd === 0 ? 24 : latestEnd))
            && between((coreEndHours === 0 ? 24 : coreEndHours), (earliestEnd === 0 ? 24 : earliestEnd), (latestEnd === 0 ? 24 : latestEnd)))) {
            setShowInvalidTypeError({"show": true, "msg":
                    "'Slutter' og 'Kjernetid slutt klokken' må være mellom " + times.earliestEnd + " og " + times.latestEnd, "field": "none"});
            return false;
        }

        let breakMin = parseInt(type.breakMinutes)
        if(isNaN(breakMin) || !noDecimalRegex().test(type.breakMinutes)) {
            setShowInvalidTypeError({"show": true, "msg":
                    "Ubetalt pause må være et heltall" ,"field": "breakMinutes"});
            return false
        }


        setShowInvalidTypeError({"show": false, "msg": "", "field": "none"})
        fixResolutionAndReturnModel(type);
        return true;
    }

    const roundTimeToNearestTimeRes = (val) => {
        let timeRes = parseInt(shiftType.minuteResolution);
        let parts = val.split(":");
        let h = parts[0]
        let m = Math.round(parseInt(parts[1]) / timeRes) * timeRes

        if(m >= 60) {
            h = parseInt(h) + 1;
            m = 0;
        }
        if(h >= 24) {
            return "00:00";
        } else if (h === 23 && m >= 53) {
            return "00:00"
        }
        return (h.toString().length < 2 ? "0" + h : h) + ":" + (m.toString().length < 2 ? "0" + m : m);
    }

    const fixResolutionAndReturnModel = (type) => {
        type.start = roundTimeToNearestTimeRes(type.start);
        setValue("start", type.start);

        type.coreTimeStart = roundTimeToNearestTimeRes(type.coreTimeStart);
        setValue("coreTimeStart", type.coreTimeStart);

        type.coreTimeEnd = roundTimeToNearestTimeRes(type.coreTimeEnd);
        setValue("coreTimeEnd", type.coreTimeEnd);

        type.end = roundTimeToNearestTimeRes(type.end);
        setValue("end", type.end);

        return type;
    }

    const setShiftHoursAndClean = (type) => {
        let coreTimeEnd = parseFloat(localTimeToHours(type.coreTimeEnd));
        let end = parseFloat(localTimeToHours(type.end));
        let addHours = type.daySegment === "N" ? 24 : 0;
        type.shiftHoursMin = addHours + (coreTimeEnd === 0 ? 24 : coreTimeEnd) - parseFloat(localTimeToHours(type.coreTimeStart));
        type.shiftHoursMax = addHours + (end === 0 ? 24 : end) - parseFloat(localTimeToHours(type.start));
        delete type.coreTimeEnd;
        delete type.end;
        return type;
    }

    const onDaySegmentChange = (ds) => {
        return fixResolutionAndReturnModel(defaults[ds])
    }

    const post = async () => {
        if(!valid(shiftType)) {
            return;
        }
        let type = setShiftHoursAndClean(shiftType)

        await dynoapeAPI.post(`/api/v1/department/${departmentId}/shift-type`, type);

        let types = sortShiftTypesByDaySegment(await dynoapeAPI.get(`/api/v1/department/${departmentId}/shift-types`));
        setShiftTypes(types);
        setShiftType(emptyShiftType);
        closeModal();
    };

    const put = async (id) => {
        if(!valid(shiftType)) {
            return;
        }

        const type = setShiftHoursAndClean(shiftType)

        await dynoapeAPI.put(`/api/v1/department/${departmentId}/shift-type/${id}`, type);

        let types = sortShiftTypesByDaySegment(await dynoapeAPI.get(`/api/v1/department/${departmentId}/shift-types`));
        setShiftTypes(types);
        setShiftType(emptyShiftType)
        closeModal();
    };

    const del = async (id) => {
        try {
            await dynoapeAPI.delete(`/api/v1/department/${departmentId}/shift-type/${id}`);
            setShiftTypes(shiftTypes.filter(p => { return p.id !== id }));
        } catch (error) {
            console.error(error);
        }
        setShowDeleteModal({ show: false, id: "" });
    };

    return (
        <div>
            <Button icon={<p>+</p>} ml="auto" mb="10px" onClick={() => {
                setShiftType(emptyShiftType);
                setShowModal({ "show": true, "id": "" });
            }} primary>Legg til vaktkode</Button>
            {isLoading ? <Spinner show /> : !shiftTypes?.length ?
                <div style={{ background: "white", padding: "10px 20px 20px 10px" }}><h4>Ingen vaktkoder funnet</h4></div>
                : <Table columns={columns} data={shiftTypes} rowKey="id" />
            }
            {
                showShiftTypeModal.show && (
                    <Modal
                        style={{minHeight: "850px"}}
                        title={showShiftTypeModal.id !== "" ? "Endre vaktkode" : "Legg til ny vaktkode"}
                        cancel={() => {
                            closeModal();
                        }}
                        cancelButtonProps={{
                            children: 'Avbryt'
                        }}
                        done={() => {
                            if(showInvalidTypeError.show) {
                                return;
                            }
                            if (showShiftTypeModal.id !== "") {
                                return put(showShiftTypeModal.id);
                            }
                            return post();
                        }}
                        action={showShiftTypeModal.id !== "" ? "Lagre" : "Legg til"}
                    >
                        <label htmlFor={"code"}>Kode (kun tall og bokstaver tillatt)</label>
                        <SolidInput
                            placeholder={"For eks: D1"}
                            id="code"
                            readOnly={showShiftTypeModal.id !== ""}
                            style={{ width: "300px", marginBottom: "10px", marginTop: "10px", "backgroundColor":
                                    showShiftTypeModal.id !== "" ? "hsl(0, 0%, 95%)" : "white",
                                color: showShiftTypeModal.id !== "" ? "hsl(0, 0%, 60%)" : "black",
                                textTransform: "uppercase"}}
                            defaultValue={shiftType.code}
                            maxLength="4"
                            data-for="code"
                            data-tip="Her velger du koden for vaktkoden"
                            {...register("code")}
                            onChange={(e) => {
                                let newType = shiftType;
                                newType.code = e.target.value.toUpperCase();
                                if(!valid(newType)) {
                                    return;
                                }
                                setShiftType(newType);
                                setShowInvalidTypeError({ "show": false, "msg": "", "field": "none" });
                            }}
                        />
                        {(showInvalidTypeError.show && showInvalidTypeError.field === "code") &&
                            <Err>{showInvalidTypeError.msg}</Err>
                        }
                        <Tooltip id="code" />

                        <label htmlFor={"daySegment"}>Vaktkategori</label>
                        <div style={{ width: "300px", marginBottom: "20px", marginTop: "10px"}}>
                            <Select id="daySegment"
                                    data-for="daySegment"
                                    data-tip="Her velger du hvilken vaktkategori (D, A, L, N)"
                                    value={{value: shiftType.daySegment, label: daySegmentLabels(shiftType.daySegment)}}
                                    {...register("daySegment")}
                                    onChange={(e) => {
                                        let type = e.value.trim();
                                        let newType = {...shiftType,...onDaySegmentChange(type), breakMinutes: shiftType.breakMinutes};
                                        newType.daySegment = type;
                                        setShiftType(newType);
                                        valid(newType)
                                    }}
                                    options={daySegmentOptions}>
                            </Select>
                            <Tooltip id="daySegment" />
                        </div>

                        <div style={{display: "none"}}>
                            <label htmlFor={"minuteResolution"}>Tillatt vaktstart -og slutt tidsmargin</label>
                            <Select id="minuteResolution"
                                    value={{value: shiftType.minuteResolution, label: shiftType.minuteResolution + " minutter"}}
                                    data-for="minuteResolution"
                                    data-tip="Her velger du tillatt vaktstart -og slutt tidsmargin"
                                    {...register("minuteResolution")}
                                    onChange={(e) => {
                                        let res = e.value.trim();
                                        let newType = shiftType;
                                        newType.minuteResolution = res;
                                        if(!valid(newType)) return;
                                        setShiftType(newType);
                                    }}
                                    options={minuteResolutionOptions}>
                            </Select>
                            <Tooltip id="minuteResolution" />
                        </div>
                        <hr style={{border: "2px solid #EAEEF3", borderBottom: 0, margin: "20px 0"}} />
                        <label htmlFor={"start"}>Starter:</label>
                        <div style={{ width: "300px", marginBottom: "20px", marginTop: "10px"}}>
                            <StyledInputMask
                                id="start"
                                key="start"
                                defaultValue={shiftType.start}
                                mask={[/^([0-2])/, /([0-9])/, ":", /[0-5]/, /[0-9]/]}
                                maskPlaceholder="-"
                                alwaysShowMask={true}
                                data-for="start"
                                {...register("start" )}
                                data-tip="Her angir du når vakten tidligst kan starte"
                                onChange={(e) => {
                                    let newType = shiftType;
                                    newType.start = e.target.value;
                                    if(timeRegex().test(newType.start)) {
                                        newType.start = roundTimeToNearestTimeRes(newType.start);
                                        setValue("start", newType.start);
                                    }
                                    if(!valid(newType)) return;
                                    newType = fixResolutionAndReturnModel(newType);
                                    setShiftType(newType);
                                }}
                            />
                            <Tooltip id="start" />
                        </div>
                        {(showInvalidTypeError.show && showInvalidTypeError.field === "start") &&
                            <Err>{showInvalidTypeError.msg}</Err>
                        }
                        <label htmlFor={"coreTimeStart"}>Kjernetid start klokken:</label>
                        <div style={{ width: "300px", marginBottom: "20px", marginTop: "10px"}}>
                            <StyledInputMask
                                id="coreTimeStart"
                                defaultValue={shiftType.coreTimeStart}
                                mask={[/^([0-2])/, /([0-9])/, ":", /[0-5]/, /[0-9]/]}
                                maskPlaceholder="-"
                                alwaysShowMask={true}
                                data-for="coreTimeStart"
                                {...register("coreTimeStart")}
                                data-tip="Her angir du når vakten senest kan starte"
                                onChange={(e) => {
                                    let newType = shiftType;
                                    newType.coreTimeStart = e.target.value;
                                    if(timeRegex().test(newType.coreTimeStart)) {
                                        newType.coreTimeStart = roundTimeToNearestTimeRes(newType.coreTimeStart);
                                        setValue("coreTimeStart", newType.coreTimeStart);
                                    }
                                    if(!valid(newType)) return;
                                    newType = fixResolutionAndReturnModel(newType);
                                    setShiftType(newType);
                                }}
                            />
                            <Tooltip id="coreTimeStart" />
                        </div>
                        {(showInvalidTypeError.show && showInvalidTypeError.field === "coreTimeStart") &&
                            <Err>{showInvalidTypeError.msg}</Err>
                        }

                        <label htmlFor={"coreTimeEnd"}>Kjernetid slutt klokken:</label>
                        <div style={{ width: "300px", marginBottom: "20px", marginTop: "10px"}}>
                            <StyledInputMask
                                id="coreTimeEnd"
                                defaultValue={shiftType.coreTimeEnd}
                                mask={[/^([0-2])/, /([0-9])/, ":", /[0-5]/, /[0-9]/]}
                                maskPlaceholder="-"
                                alwaysShowMask={true}
                                data-for="coreTimeEnd"
                                {...register("coreTimeEnd")}
                                data-tip="Her angir du når vakten senest kan starte"
                                onChange={(e) => {
                                    let newType = shiftType;
                                    newType.coreTimeEnd = e.target.value;
                                    if(timeRegex().test(newType.coreTimeEnd)) {
                                        newType.coreTimeEnd = roundTimeToNearestTimeRes(newType.coreTimeEnd);
                                        setValue("coreTimeEnd", newType.coreTimeEnd);
                                    }
                                    if(!valid(newType)) return;
                                    newType = fixResolutionAndReturnModel(newType);
                                    setShiftType(newType);
                                }}
                            />
                            <Tooltip id="coreTimeEnd" />
                        </div>
                        {(showInvalidTypeError.show && showInvalidTypeError.field === "coreTimeEnd") &&
                            <Err>{showInvalidTypeError.msg}</Err>
                        }
                        <label htmlFor={"end"}>Slutter:</label>
                        <div style={{ width: "300px", marginBottom: "20px", marginTop: "10px"}}>
                            <StyledInputMask
                                id="end"
                                key="end"
                                defaultValue={shiftType.end}
                                mask={[/^([0-2])/, /([0-9])/, ":", /[0-5]/, /[0-9]/]}
                                maskPlaceholder="-"
                                alwaysShowMask={true}
                                data-for="end"
                                {...register("end" )}
                                data-tip="Her angir du når vakten senest kan slutte"
                                onChange={(e) => {
                                    let newType = shiftType;
                                    newType.end = e.target.value;
                                    if(timeRegex().test(newType.end)) {
                                        newType.end = roundTimeToNearestTimeRes(newType.end);
                                        setValue("end", newType.end);
                                    }
                                    if(!valid(newType)) return;
                                    newType = fixResolutionAndReturnModel(newType);
                                    setShiftType(newType);
                                }}
                            />
                            <Tooltip id="end" />
                        </div>
                        {(showInvalidTypeError.show && showInvalidTypeError.field === "end") &&
                            <Err>{showInvalidTypeError.msg}</Err>
                        }

                        <label htmlFor={"start"}>Ubetalt pause i minutter:</label>
                        <div style={{ width: "80px", marginBottom: "20px", marginTop: "10px"}}>
                            <NumberInput
                                id="breakMinutes"
                                key="breakMinutes"
                                type="number"
                                defaultValue={shiftType.breakMinutes}
                                data-for="breakMinutes"
                                {...register("breakMinutes" )}
                                data-tip="Her angir du den passive tiden"
                                onChange={(e) => {
                                    let newType = shiftType;
                                    newType.breakMinutes = e.target.value;
                                    if(!valid(newType)) return;
                                    newType = fixResolutionAndReturnModel(newType);
                                    setShiftType(newType);
                                }}
                            />
                            <Tooltip id="breakMinutes" />
                        </div>
                        {(showInvalidTypeError.show && showInvalidTypeError.field === "breakMinutes") &&
                            <Err>{showInvalidTypeError.msg}</Err>
                        }

                        <hr style={{border: "2px solid #EAEEF3", borderBottom: 0, margin: "20px 0"}} />
                        {(showInvalidTypeError.show && showInvalidTypeError.field === "none") && (
                            <Err>{showInvalidTypeError.msg}</Err>
                        )}
                    </Modal>
                )
            }
            {showDeleteModal.show && (
                <Modal
                    title="Slett vaktkode"
                    cancel={() => {
                        setShowDeleteModal({ show: false, id: "" });
                    }}
                    done={() => {
                        del(showDeleteModal.id);
                    }}
                    action="Slett vaktkode"
                    cancelButtonProps={{
                        children: 'Avbryt'
                    }}
                >
                    <p>Er du sikker på at du vil slette vaktkode <i>{shiftTypes.filter(sh => sh.id === showDeleteModal.id)[0].code}</i>?</p>
                </Modal>
            )}
        </div>
    );
};

const Err = styled.p`
  color: #BF3D2B
`;

export default ShiftTypesTable;
