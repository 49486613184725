import React, { useEffect, useState } from "react";
import Select from "react-select";
import moment from "moment";
import Papa from "papaparse";
import {Button, Modal, Spinner, Table} from "rendition";

import CalendarHeatmap from "../components/vis-components/calendar-heatmap";
import {
  accumulateDatasets,
  fullSchemeRed,
  norwegianDays,
} from "../lib/common";
import readXlsxFile from "read-excel-file";
import {dynoapeAPI} from "../api/dynoape";

const AbsencePredictor = ({departmentId}) => {
  const [data, setData] = useState([]);
  const [yearData, setYearData] = useState([]);
  const [combinedYearlyData, setCombinedYearlyData] = useState([]);
  const [displayData, setDisplayData] = useState(false);
  const [fteNumbers, setFteNumbers] = useState(0);
  const [absenceCount, setAbsenceCount] = useState(0);
  const [availableYears, setAvailableYears] = useState([2023]);
  const [selectedYear, setSelectedYear] = useState({
    label: "2023",
    value: 2023,
  });

  const [existingData, setExistingData] = useState([]);
  const [dataToStore, setDataToStore] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [showDeleteModal, setShowDeleteModal] = useState({
    show: false,
    id: "",
  });

  const getData = async () => {
    const resp = await dynoapeAPI.get(`/api/v1/department/${departmentId}/absence-datas`)
    setExistingData(resp)
    setIsLoading(false)
  }

  const storeData = async () => {
    const resp = await dynoapeAPI.post(`/api/v1/department/${departmentId}/absence-data`, {name: dataToStore.name, elements: dataToStore.data});
    if(resp) {
      setDataToStore(undefined);
      delete resp.elements
      setExistingData({...existingData, elements: [...existingData.elements, resp]});
    }
  }

  useEffect(() => {
    getData();
  }, [])

  useEffect(() => {
    if(dataToStore) {
      storeData()
    }
  }, [dataToStore])

  const columns = [
    {
      key: "1",
      field: 'name',
      label: <span style={{ width: "80px", display: "block" }}>Navn</span>,
      sortable: true,
      render: function displayColumn(data) {
        return <span>{data}</span>
      }
    },
    {
      key: "2",
      field: 'registered',
      label: <span style={{ width: "80px", display: "block" }}>Registrert</span>,
      sortable: true,
      render: function displayColumn(data) {
        return <span>{data}</span>
      }
    },
    {
      key: "3",
      field: 'id',
      label: <span style={{ width: "80px", display: "block" }}></span>,
      sortable: false,
      render: function displayColumn(data) {
        return <Button id={`${data}-generate`} success onClick={async () => {
          const resp = await dynoapeAPI.get(`/api/v1/department/${departmentId}/absence-data/${data}`)
          setDisplayData(true)
          setData(
              resp?.elements
                  .map(el => (
                      {...el,
                        employeeNr: (Math.random() + 1).toString(36).substring(2),
                        dateFrom: moment(el.dateFrom).format('YYYY.MM.DD'),
                        dateTo: moment(el.dateTo).format('YYYY.MM.DD')}))
          );
        }
        }>Generer</Button>
      }
    },
    {
      key: "4",
      field: 'id',
      label: <span style={{ width: "80px", display: "block" }}></span>,
      sortable: false,
      render: function displayColumn(data) {
        return <Button id={`${data}-delete`} danger onClick={() => setShowDeleteModal({show: true, id: data})}>Slett</Button>
      }
    }
  ];

  useEffect(() => {
    const yearEntries = fetchDataForYear(selectedYear.value);

    setYearData(yearEntries);
    setFteNumbers(
      yearEntries.reduce((sum, entry) => sum + (entry.fte || 0), 0)
    );
    setAbsenceCount(yearEntries.length);
  }, [selectedYear]);

  useEffect(() => {
    const yearsInData = data
        .map((entry) => entry.year)
        .reduce((unique, curr) => {
          if (!unique.includes(curr)) {
            unique.push(curr);
          }
          return unique;
        }, []);
    setAvailableYears(yearsInData.sort((a, b) => a - b));

    const yearEntries = fetchDataForYear(selectedYear.value);

    setYearData(yearEntries);
    setCombinedYearlyData(combineAllYearlyData());
    setFteNumbers(
      yearEntries.reduce((sum, entry) => sum + (entry.fte || 0), 0)
    );
    setAbsenceCount(yearEntries.length);
    {
      displayData && statistics();
    }
  }, [data]);

  const fetchDataForYear = (year) => {
    const years = splitData();
    let yearData = [];
    if (years[year] !== undefined) {
      yearData = years[year];
    }
    return yearData;
  };

  /**
   * Splits the data into separate arrays for each year,
   * counting the absence start day-number of each weekday,
   * logging some key statistics and sanity checks.
   *
   * @return {Object} An object with separate arrays for each year.
   */
  const splitData = () => {
    if (data === undefined) return;

    const copyData = data;
    let years = {};
    let errorEntries = 0;
    let yearsBefore = 0;
    let errorArray = [];
    let differentYears = 0;

    copyData.forEach((item) => {
      yearsBefore++;

      if (item.dateTo === "Invalid date" || item.dateFrom === "Invalid date") {
        errorEntries++;
        errorArray.push(item);
        return;
      }

      // Split the data into separate arrays for each year
      const year = new Date(item.dateFrom).getFullYear();
      if (
        new Date(item.dateFrom).getFullYear() !==
        new Date(item.dateTo).getFullYear()
      ) {
        differentYears++;
        const newYear = new Date(item.dateTo).getFullYear();
        const oldYear = new Date(item.dateFrom).getFullYear();
        const newYearDateToString = new Date(newYear, 0, 1, 3)
          .toISOString()
          .split("T")[0];
        const nextYearItem = {
          ...item,
          dateFrom: newYearDateToString,
        };
        if (!years[newYear]) {
          years[newYear] = [];
        }
        years[newYear].push(nextYearItem);
        const newDateToString = new Date(oldYear, 11, 31, 3)
          .toISOString()
          .split("T")[0];
        const oldItem = {
          ...item,
          dateTo: newDateToString,
        };
        if (!years[oldYear]) {
          years[oldYear] = [];
        }
        years[oldYear].push(oldItem);
        /*
         * Example of output:
         *
         *  item (original item):
         *  dateFrom: "2022.12.12"
         *  dateTo: "2023.01.08"
         *  employeeNr: 264
         *
         *  item gets splitted into two items: oldItem and nextYearItem
         *
         *  oldItem (item to go to the current year-array):
         *  dateFrom: "2022.12.12"
         *  dateTo: "2022-12-31"
         *  employeeNr: 264
         *
         *  nextYearItem (item to go to the next year-array):
         *  dateFrom: "2023-01-01"
         *  dateTo:"2023.01.08"
         *  employeeNr: 264
         *
         */
        return;
      }
      if (!years[year]) {
        years[year] = [];
      }
      years[year].push(item);
    });

    // Log the dataset-statistics and sanity checks
    function datasetStatistics() {
      const yearsLengths = Object.values(years).map((arr) => arr.length);
      const sumLengths = yearsLengths.reduce((a, b) => a + b, 0);

      console.log("Error array: ", errorArray);
      console.log(`Entries before check: ${yearsBefore}`);
      console.log(`- With errors: ${errorEntries}`);
      console.log(`+ With different start/end-years: ${differentYears}`);
      console.log(`= Entries after check: ${sumLengths}`);
      console.log(
        "Sanity check (should be 0):",
        yearsBefore - errorEntries + differentYears - sumLengths
      );
    }
    {
      displayData && datasetStatistics();
    }
    setAvailableYears(Object.keys(years).sort((a, b) => a - b));
    return years;
  };

  function statistics() {
    if (data === undefined) return;

    const copyData = data;

    const weekDayObject = {
      MONDAY: 0,
      TUESDAY: 0,
      WEDNESDAY: 0,
      THURSDAY: 0,
      FRIDAY: 0,
      SATURDAY: 0,
      SUNDAY: 0,
    };

    const weekdays = [
      "SUNDAY",
      "MONDAY",
      "TUESDAY",
      "WEDNESDAY",
      "THURSDAY",
      "FRIDAY",
      "SATURDAY",
    ];

    copyData.forEach((item) => {
      if (item.dateTo !== "Invalid date" && item.dateFrom !== "Invalid date") {
        // Count the absence start day-number of each weekday
        const dateFrom = new Date(item.dateFrom);
        const dayIndex = dateFrom.getDay() % 7; // Adjust for Sunday being 0 in JavaScript's Date.getDay()
        const dayOfWeek = weekdays[dayIndex];
        weekDayObject[dayOfWeek]++;
      }
    });

    return Object.entries(weekDayObject).map(([day, count]) => ({
      day,
      count,
      percentage:
        (
          (count / Object.values(weekDayObject).reduce((a, b) => a + b, 0)) *
          100
        ).toFixed(2) + "%",
    }));
  }

  /**
   * Combines data from all years into a single array,
   * with the dateFrom and dateTo properties formatted
   * to include the current year.
   *
   * @return {Array} The combined data array.
   */
  const combineAllYearlyData = () => {
    // Check if there is data uploaded
    if (!displayData) {
      return [];
    }

    // Split the data into separate arrays for each year
    const allYears = splitData();

    // Check if the year has entries from January to December
    const checkYearEntries = (yearData) => {
      const months = new Set(
        yearData.map((item) => new Date(item.dateFrom).getMonth() + 1)
      );
      for (let i = 1; i <= 12; i++) {
        if (!months.has(i)) {
          return false;
        }
      }
      return true;
    };

    const yearCompleteness = {};

    for (const year in allYears) {
      yearCompleteness[year] = checkYearEntries(allYears[year]);
    }

    /**
     * Removes the year part from the dateFrom and dateTo properties
     * of each item in the given yearData array.
     *
     * @param {Array} yearData - The array of items to process.
     * @return {Array} The processed array.
     */
    const removeYearsFromData = (yearData) => {
      return (yearData || []).map((item) => {
        const { dateFrom, dateTo, ...rest } = item || {};
        const newDateFrom = (dateFrom || "").slice(5);
        const newDateTo = (dateTo || "").slice(5);
        return { dateFrom: newDateFrom, dateTo: newDateTo, ...rest };
      });
    };

    const completeYearsData = [];

    for (const year in yearCompleteness) {
      if (yearCompleteness[year]) {
        const yearData = removeYearsFromData(allYears[year]);
        completeYearsData.push(...yearData);
      }
    }

    const currentYear = new Date().getFullYear();
    /**
     * Formats a date string to include the current year.
     *
     * @param {string} dateString - The date string in the format "MM.DD".
     * @return {string} The formatted date string with the current year.
     */
    const formatDateWithYear = (dateString) => {
      const [month, day] = (dateString || "").split(".");
      return `${currentYear}.${month}.${day}`;
    };

    // Apply the formatDateWithYear function to each date in each object
    return completeYearsData.map((obj) => ({
      ...obj,
      dateFrom: formatDateWithYear(obj.dateFrom),
      dateTo: formatDateWithYear(obj.dateTo),
    }));
  };

  const convertAbsenceJsonToDisplayable = (absenceJson) => {
    let data = absenceJson.map(({ employeeNr, dateFrom, dateTo }) => {
      const start = new Date(dateFrom);
      const end = new Date(dateTo);
      const dates = [];

      for (let date = start; date <= end; date.setDate(date.getDate() + 1)) {
        const dateString = date.toISOString().split("T")[0];
        dates.push([dateString, [employeeNr.toString()]]);
      }
      return dates;
    });
    return data;
  };

  const handleYearSelect = (selectedOption) => {
    setSelectedYear(selectedOption);
  };

  const handleFileInput = async (event) => {
    const file = event.target.files[0];
    const fileType = file.name;

    if(fileType.split('.').pop().toLowerCase() === 'xlsx') {
      const map = {
        Fradato: "dateFrom",
        Tildato: "dateTo",
        Årsak: "cause",
        "Årsak beskrivelse": "causeDesc",
        "Kal.dager": "calendarDays",
        DVerk: "fte"
      }
      readXlsxFile(file, {map}).then(data => {
        const formattedJSONData = data.rows.map((item) => {
          return {
            dateFrom: moment(item.dateFrom, "DD.MM.YYYY").format("YYYY-MM-DD"),
            dateTo: moment(item.dateTo, "DD.MM.YYYY").format("YYYY-MM-DD"),
            cause: String(item.cause),
            causeDesc: String(item.causeDesc),
            calendarDays: Number(item.calendarDays),
            fte: Number(item.fte),
          };
        });
        setDataToStore({data: formattedJSONData, name: file.name});
      })
    } else {
      const options = {
        complete: (results) => {
          const formattedJSONData = results.data.filter(item => item[0] !== 'Fradato').map((item) => {
            // set the data to the correct format
            return {
              dateFrom: moment(item[0], "DD.MM.YYYY").format("YYYY-MM-DD"),
              dateTo: moment(item[1], "DD.MM.YYYY").format("YYYY-MM-DD"),
              cause: String(item[2]),
              causeDesc: String(item[3]),
              calendarDays: Number(item[4]),
              fte: Number(item[5]),
            };
          });

          setDataToStore({data: formattedJSONData, name: file.name});
        },
        error: (err) => {
          console.error("Failed to parse CSV:", err);
        },
      };
      Papa.parse(file, options);
    }
  };

  const handleFileUpload = () => {
    const fileInput = document.getElementById("fileInput");
    fileInput.click();
  };

  const thStyle = {
    padding: "8px",
    borderBottom: "1px solid #ddd",
  };

  const tdStyle = {
    padding: "8px",
    borderBottom: "1px solid #ddd",
  };

  return (
    <div>
      <div>
        <div style={{marginBottom: "40px"}}>
          <i>Innholdet i filen som lastes opp, må inneholder følgende kolonner:
            <ul>
              <li>Fradato (format: DD.MM.YYYY)</li>
              <li>Tildato (format: DD.MM.YYYY)</li>
              <li>Årsak</li>
              <li>Årsak beskrivelse</li>
              <li>Kal.dager</li>
              <li>DVerk</li>
            </ul>
          </i>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            maxWidth: "100%",
            alignItems: "center",
          }}
        >
          <Button primary onClick={handleFileUpload}>
            Last opp fil
          </Button>
          <input
            type="file"
            id="fileInput"
            data-testid="fileInput"
            style={{
              display: "none",
            }}
            onChange={handleFileInput}
            accept=".csv,.txt,.xlsx"
          />
        </div>
        <div>
          <h3>Eksisterende datasett</h3>
        {isLoading
            ? <Spinner show />
            : <Table columns={columns} data={existingData?.elements ? existingData.elements : []} rowKey="id" />
        }
        </div>
        {displayData && (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                maxWidth: "800px",
                top: "50%",
              }}
            >
              <Select
                styles={{
                  container: (base) => ({
                    ...base,
                    width: "100px",
                    marginTop: "50px",
                    zIndex: 11,
                  }),
                }}
                closeMenuOnSelect={true}
                onChange={(opt) => handleYearSelect(opt)}
                options={availableYears.map((year) => ({
                  value: year,
                  label: year,
                }))}
                value={selectedYear}
                placeholder={`Velg år`}
              />
            </div>
            <span style={{ display: "flex", alignItems: "center" }}>
              <CalendarHeatmap
                data={accumulateDatasets(
                  convertAbsenceJsonToDisplayable(yearData)
                )}
                width={1000}
                height={500}
                margin={{
                  top: 20,
                  left: 20,
                  right: 0,
                  bottom: 0,
                }}
                quantileScale={true}
                colorScheme={fullSchemeRed}
                hidden={false}
              />
              <div
                style={{
                  marginLeft: "auto",
                  marginRight: "0",
                  border: "1px solid #ddd",
                  borderRadius: "4px",
                  padding: "10px",
                  width: "250px",
                  background:
                    "linear-gradient(0deg,rgba(255, 255, 255, 0.75),rgba(255, 255, 255, 0.75)), #67b59d",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <p>
                  Antall dagsverk rapportert: <strong>{fteNumbers}</strong>
                </p>
                <p>
                  Antall unike fraværsperioder: <strong>{absenceCount}</strong>
                </p>
                <p>
                  Dager fravær per periode:{" "}
                  <strong>
                    {Math.round((fteNumbers / absenceCount) * 100) / 100}
                  </strong>
                </p>
              </div>
            </span>

            <div style={{ marginTop: "100px" }}>
              <h1>Fraværprediksjon for året:</h1>
              <p>
                Ikke-sesongjustert. Tidsserie uten justering for
                sesongvariasjoner.
              </p>
              <CalendarHeatmap
                data={accumulateDatasets(
                  convertAbsenceJsonToDisplayable(combinedYearlyData)
                )}
                width={1000}
                height={500}
                margin={{
                  top: 20,
                  left: 20,
                  right: 0,
                  bottom: 0,
                }}
                quantileScale={true}
                colorScheme={fullSchemeRed}
                hidden={false}
              />
            </div>
            <div
              style={{
                marginLeft: "0",
                marginTop: "20px",
                marginRight: "auto",
                border: "1px solid #ddd",
                borderRadius: "4px",
                padding: "10px",
                width: "300px",
                background:
                  "linear-gradient(0deg,rgba(255, 255, 255, 0.75),rgba(255, 255, 255, 0.75)), #67b59d",
              }}
            >
              <table
                style={{
                  borderCollapse: "collapse",
                  width: "100%",
                  textAlign: "left",
                }}
              >
                <thead>
                  <tr>
                    <th style={thStyle}>Dag</th>
                    <th style={thStyle}>Antall</th>
                    <th style={thStyle}>Prosent</th>
                  </tr>
                </thead>
                <tbody>
                  {statistics().map((stat, index) => (
                    <tr key={index}>
                      <td style={tdStyle}>
                        {norwegianDays(stat.day.toUpperCase()) ===
                        "Søndag/Helligdager"
                          ? "Søndag"
                          : norwegianDays(stat.day.toUpperCase())}
                      </td>
                      <td style={tdStyle}>{stat.count}</td>
                      <td style={tdStyle}>{stat.percentage}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
      {showDeleteModal.show && (
          <Modal
              title="Slett datasett"
              cancel={() => {
                setShowDeleteModal({ show: false, id: "" });
              }}
              done={async () => {
                setDisplayData(false)
                await dynoapeAPI.delete(`/api/v1/department/${departmentId}/absence-data/${showDeleteModal.id}`);
                setExistingData({...existingData, elements: existingData.elements.filter(el => el.id !== showDeleteModal.id)});
                setShowDeleteModal({ show: false, id: "" });
              }}
              action="Slett datasett"
              primaryButtonProps={{
                id: `confirm-delete-${showDeleteModal.id}`,
                "data-testid": `confirm-delete-${showDeleteModal.id}`
              }}
              cancelButtonProps={{
                children: "Avbryt",
              }}
          >
            <p>Er du sikker på at du vil slette dette datasettet?</p>
          </Modal>
      )}
    </div>
  );
};

export default AbsencePredictor;
