/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from "react";
import { Button, Modal, Checkbox } from "rendition";
import styled from "styled-components";
import { createPatch } from "rfc6902";
import { CardColumn, InputDivider } from "../lib/styled-components";
import { dynoapeAPI } from "../api/dynoape";
import { useParams } from "react-router";
import { notifications } from "rendition";
import { isTaskStatusReadOnly, norwegianDays, norwegianShiftNames } from "../lib/common";
import Tooltip from "../lib/tooltip";
import Select from "react-select";
import Calendar from 'rc-year-calendar';
import moment from "moment";
import _ from 'lodash';

const CustomWeekCopy = ({
  department,
  errors,
  taskStatus,
  taskStartDate,
  taskWeeks,
  task,
  setTask,
  setUpdateTaskOngoing
}) => {
  const [showModal, setShowModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [selectedYear, setSelectedYear] = useState(moment(taskStartDate).format("YYYY"));
  const [originWeek, setOriginWeek] = useState();
  const [copyToWeeks, setCopyToWeeks] = useState([]);
  const { departmentId, taskId } = useParams();
  const [turnusDates, setTurnusDates] = useState([]);
  const yearRef = useRef();
  const originWeekRef = useRef();
  const copyToWeeksRef = useRef();

  yearRef.current = selectedYear;
  originWeekRef.current = originWeek;
  copyToWeeksRef.current = copyToWeeks;

  useEffect(() => {
    let start = moment(taskStartDate);
    let dates = []
    for (let d = 0; d < taskWeeks * 7; d++) {
      let date = start.clone()
      dates.push(moment(date.add(d, 'd')).format('YYYY-MM-DD'));
    }
    setTurnusDates(dates);
  }, [])

  const copyWeeks = async () => {
    setUpdateTaskOngoing(true)
    let existingPatches = _.cloneDeep(task.config.dailyCoverPatches);
    let fromDays = Object.keys(task.config.dailyCoverPatches).filter(k => {
      let date = moment(k);
      return date.isoWeek() === originWeek.w && date.year() === originWeek.y
    });

    let copies = {};

    fromDays.forEach(k => {
      let d = moment(k).isoWeekday();
      for (const copyTo of copyToWeeks) {
        let firstDayOfCopyToWeek = moment(`${copyTo.y}-W${String(copyTo.w).padStart(2, "0")}-1`);
        for (let m = firstDayOfCopyToWeek.clone(); m.isoWeek() == firstDayOfCopyToWeek.isoWeek(); m.add(1, 'd')) {
          delete existingPatches[m.format("YYYY-MM-DD")]
        }

        let newDateTmp = moment(moment().year(parseInt(copyTo.y))).isoWeek(parseInt(copyTo.w)).isoWeekday(parseInt(d))
          .format("YYYY-MM-DD");
        let newDate = moment(`${copyTo.y}-W${String(copyTo.w).padStart(2, "0")}-${d}`).format("YYYY-MM-DD");
        copies[newDate] = Object.keys(copies).includes(newDate) ? [...copies[newDate], existingPatches[k]] : existingPatches[k];
      }
    });

    let patch = createPatch(_.cloneDeep(existingPatches), Object.assign(existingPatches, copies)).map(p => {
      p.path = "/config/dailyCoverPatches" + p.path;
      return p;
    })
    const response = await dynoapeAPI.patch(`/api/v1/department/${departmentId}/task/${taskId}`, patch);
    if (response) {
      setTask(response);
    }

    setOriginWeek();
    setCopyToWeeks([]);
    setUpdateTaskOngoing(false)
  };

  const Errors = () => {
    if (errors.config.weeklyCoverDemands) {
      console.error(errors.config.weeklyCoverDemands);
      let errorList = Object.keys(errors.config.weeklyCoverDemands)?.map((day) => Object.keys(errors.config.weeklyCoverDemands[day])?.map((shift, index) => <p key={index}>Feil på {norwegianDays(day)} - {norwegianShiftNames(shift)}</p>));
      return (
        errorList
      );
    }
    return (<p>Feil på innstillinger</p>);
  };

  return (
    <div style={{ marginLeft: "auto", height: "65px"}}>
      <Button className={"BpButton"} type="submit" mr="10px" tertiary onClick={() => {
        if (Object.keys(errors).length === 0) {
          setShowModal(true);
        } else {
          setShowErrorModal(true);
        }
      }}>
        Kopier uke(r)
      </Button>
      {showModal && (
        <Modal
          style={{ width: "1700px" }}
          title="Kopier uke(r)"
          action="Kopier til dager"
          done={() => {
            copyWeeks();
            setShowModal(false);
          }}
          primaryButtonProps={{
            disabled: copyToWeeks.length === 0
          }}
          cancel={() => {
            setOriginWeek();
            setCopyToWeeks([]);
            setShowModal(false);
          }}
          cancelButtonProps={{
            children: 'Avbryt'
          }}
        >
          <CardColumn className="calendar-task">
            <span>Klikk i kalenderen på uken du ønsker å kopiere tilpassede vakter fra, klikk i kalenderen på uken(e) det skal kopieres til.
              <p style={{color: "#BF3D2B"}}>Har du husket å lagre all endringene dine? Når du kopier uker, kopieres kun de lagrede verdiene.</p>
            </span>
            <Calendar
              minDate={moment(turnusDates[0]).toDate()}
              maxDate={moment(turnusDates[turnusDates.length - 1]).toDate()}
              language="no"
              year={selectedYear}
              onYearChanged={({ currentYear }) => setSelectedYear(currentYear)}
              displayWeekNumber={true}
              style="custom"
              dataSource={turnusDates.map(d => {
                return {
                  startDate: moment(d).toDate(),
                  endDate: moment(d).toDate()
                }
              })}
              onDayClick={({ date }) => {
                let mom = moment(date);
                let obj = { "y": mom.year(), "w": mom.isoWeek() };

                if (!originWeekRef.current) {
                  setOriginWeek(obj);
                  return;
                }

                if (originWeekRef.current && JSON.stringify(originWeekRef.current) !== JSON.stringify(obj)) {
                  let newCopyToWeeks = copyToWeeksRef.current.filter(c => {
                    return JSON.stringify(c) !== JSON.stringify(obj);
                  })
                  setCopyToWeeks(newCopyToWeeks.length != copyToWeeksRef.current.length
                    ? newCopyToWeeks
                    : [...copyToWeeksRef.current, obj]);
                  return;
                }

                if (JSON.stringify(originWeekRef.current) === JSON.stringify(obj) && copyToWeeksRef.current.length === 0) {
                  setOriginWeek();
                }
              }}
              customDataSourceRenderer={(day) => {
                const monthNr = parseInt(day.parentNode.parentNode.parentNode.parentNode.getAttribute("data-month-id"));
                const dayNr = day.innerHTML;
                const date = `${yearRef.current}-${String(`${monthNr + 1}`).padStart(2, '0')}-${String(`${dayNr}`).padStart(2, '0')}`;
                const mom = moment(date);

                if (Object.keys(task.config.dailyCoverPatches).includes(date)) {
                  day.classList.add('day-customized');
                }

                if (Object.keys(department.countryRules.holidays).includes(date))
                  day.classList.add('day-red');

                if (originWeekRef.current && mom.year() === originWeekRef.current.y && mom.isoWeek() === originWeekRef.current.w) {
                  day.classList.add('day-background-yellow');
                }

                for (let copy of copyToWeeksRef.current) {
                  if (mom.year() === copy.y && mom.isoWeek() === copy.w) {
                    day.classList.add('day-background-green');
                  }
                }
              }}
            />
          </CardColumn>
        </Modal>
      )}
      {showErrorModal && (
        <Modal
          title="Feil i bemanningsplan"
          done={() => {
            setShowErrorModal(false);
          }}
          action="Lukk"
        >
          <p>Det er en feil i bemanningsplanen, vennligst fiks den før du prøver å kopiere dager</p>
          <Errors />
        </Modal>
      )}
    </div>
  );
};

export default CustomWeekCopy;