import React, {useEffect, useState} from "react";
import moment from "moment/moment";
import {
    dynomiteErrorPanel,
    getDaysFromDatePattern, getDaysFromDatePatterns,
    getWeekdaysFromTask,
    monthsShort,
    scrollHeight,
    shortenStr
} from "../../lib/common";
import {WeekJumper} from "../../lib/styled-components";
import {ReactGrid} from "@silevis/reactgrid";
import {CheckboxCellTemplate} from "../grid-components/checkbox-cell-template";
import {Button, Flex} from "rendition";
import Tooltip from "../../lib/tooltip";
import {dynoapeAPI} from "../../api/dynoape";
import {PlannerWrapper, ReactGridWrapper} from "./employee-date-pattern-cases";
import {PatternCase} from "../../lib/pattern-case";

export default ({
                    somePatternCaseIsDirty, setSomePatternCaseIsDirty, viewStartIndex, setViewStartIndex, setViewFlipSize, viewFlipSizeRef,
                    departmentId,
                    taskSelect, setSelectedTaskOption, setSelectedTask, selectedTask,
                    employees, setEmployees, patternWithinPeriod,
                    patternCase,
                    reset,
                    getReactGridRows, getReactGridHeaderRow,
                    showSavingModal, setShowSavingModal, saveModal,
                    dynomite, validate, configErrors, configWarnings, dynomiteInternalError,
                    startDate, setStartDate, nrOfWeeks, setNrOfWeeks,
                    searchBar, searchFilters, applySearchFiltersToEmployees, routerPrompt}) => {

    const [toSave, setToSave] = useState([]);
    const nrInView = 12;
    const [columns, setColumns] = useState(undefined);
    const [mondays, setMondays] = useState(undefined);
    const placeholderId = "placeholderId";

    /**
     * Method for creating a new vacation pattern. Period is set to from a certain Monday until Sunday the same week
     * @param monday
     * @returns {{summary: string, includeDays: *[], optimize: boolean, series: {nth: number, toggled: boolean, from: string, until: string, mask: boolean[]}, kind: string, daySegments: string[], disabled: boolean, id: string, excludeDays: *[], delete: boolean}}
     */
    const vacationPattern = (monday) => {
        const start = moment(monday).startOf('isoWeek').format('YYYY-MM-DD');
        const end = moment(monday).endOf('isoWeek').format('YYYY-MM-DD');
        return {
            id: placeholderId,
            delete: false,
            summary: "Ferie " + start + " - " + end,
            series: {
                toggled: true,
                from: start,
                until: end,
                nth: 1,
                mask: [true, true, true, true, true, true, true]
            },
            includeDays: [],
            excludeDays: [],
            daySegments: ["D", "A", "N", "L"],
            disabled: false,
            optimize: false,
            kind: "vacation"
        }
    }

    useEffect(() => {
        scrollHeight();
        return () => {
            reset();
        }
    }, []);

    useEffect(() => {
        if((somePatternCaseIsDirty && toSave.length > 0) || (!somePatternCaseIsDirty && toSave.length === 0)) return;
        setSomePatternCaseIsDirty(toSave.length > 0);
    }, [toSave])

    useEffect(() => {
        validate({employeesParam: applySearchFiltersToEmployees(employees).map(e => getLocalOrGlobalEmployee(e.id))});
    }, [toSave, employees, searchFilters])

    /**
     * Helper function for getting employee data. If there are unsaved employee data, we fetch it from local storage.
     * If not, the employee is returned
     * @param employeeId
     * @returns {*}
     */
    const getLocalOrGlobalEmployee = (employeeId) => {
        return toSave.find(e => e.id === employeeId) || employees.find(e => e.id === employeeId);
    }

    /**
     * Returns true if a certain employee is on vacation on a given week (Monday), false if not
     * @param employeeId
     * @param monday
     * @returns {boolean}
     */
    const isOnVacation = (employeeId, monday) => {
        const employee = getLocalOrGlobalEmployee(employeeId);
        const patterns = employee[patternCase].filter(p => p.delete !== true);
        if(patterns.length === 0) return false;
        return patterns.some(p => moment(p.series.from).diff(moment(monday)) <= 0 && moment(p.series.until).diff(moment(monday)) >= 0)
    }

    /**
     * Method for getting the number of vacation weeks a certain employee has in a given period
     * @param employeeId
     * @returns {number}
     */
    const resolveNrOfVacationWeeks = (employeeId) => {
        const employee = getLocalOrGlobalEmployee(employeeId);
        const endDate = selectedTask
            ? moment(startDate).clone().add(nrOfWeeks, 'weeks').subtract(1, 'day')
            : moment(startDate).clone().endOf('year');
        const patterns = employee.vacationPatterns.filter(p => p.delete !== true);
        return patterns.length === 0 ? 0 : getDaysFromDatePatterns(dynomite, patterns, startDate, endDate,  "vacation",true).length / 7;
    }

    /**
     * UseEffect for generating the columns in the vacation planner. The following columns are generated:
     * - Row nr
     * - Employee name
     * - Total number of vacation weeks for each employee in the given period
     * - One column per week in the given period
     */
    useEffect(() => {
        if(!mondays) return;
        const _employees = applySearchFiltersToEmployees(employees, mondays[0], mondays[mondays.length -1]);
        const cols =
            [
                { columnId: "nr", included: true, width: 40, text: "Rad", item: ({ idx }) => ({ type: "header", text: `${idx + 1}` }), total: "" },
                { columnId: "name", included: true, width: 260, text: "Navn", item: ({ employee }) => ({ type: "header", text: `${shortenStr(employee.name, 30)}` }), total: "Antall på ferie"},
                { columnId: "nrOfVacationWeeks", included: true, width: 80, text: "Antall ferieuker", item: ({ employee }) => ({ type: "header", text: `${selectedTask ? resolveNrOfVacationWeeks(employee.id) : ""}` }), total: "" },
            ]
                .concat(mondays.map(d => {
                    const id = d.format("YYYY-MM-DD");
                    const text = `Uke ${d.month() === 11 && d.isoWeek() === 1 ? 53 : d.isoWeek()} \n ${d.year()} ${monthsShort[d.month()]}`
                    return { columnId: id, included: true, width: 100, text: text,
                        item: ({ employee }) => ({ type: "checkbox", checked: isOnVacation(employee.id, d), checkedText: "Ja", uncheckedText: "Nei" }),
                        total: _employees.reduce((count, e) => {return count + (isOnVacation(e.id, d) ? 1 : 0);}, 0) +""}
                }))
        setColumns(cols)
    }, [mondays, employees, toSave, searchFilters])

    const removeSelectedTask = () => {
        setMondays(
            getWeekdaysFromTask({config: {startDate: moment().add(1, 'weeks').startOf('isoWeek'), nrOfWeeks: 157}}, [1])
                .slice(viewStartIndex, viewStartIndex + nrInView)
        )
    }

    /**
     * UseEffect for generating which weeks that should be present in the vacation planner.
     * Triggered whenever user viewStartIndex is changed (user selects to jump weeks)
     */
    useEffect(() => {
        setMondays(
            getWeekdaysFromTask({config: {startDate: startDate, nrOfWeeks: nrOfWeeks}}, [1])
                .slice(viewStartIndex, viewStartIndex + nrInView)
        )
    }, [viewStartIndex])

    /**
     * Method for handling the changes inside the vacation planner (react grid)
     * @param changes
     */
    const handleChanges = (changes) => {
        let localToSave = toSave;
        changes.forEach(ch => {
            const employee = (localToSave.find(e => e.id === ch.rowId) || employees.find(e => e.id === ch.rowId));
            const date = moment(ch.columnId).format('YYYY-MM-DD');
            let patterns = (employee.vacationPatterns || []);

            if (ch.newCell.checked === true) {
                const flaggedAsDelete = patterns.find(p => p.delete === true && patternWithinPeriod(p, date, date));
                const pattern = flaggedAsDelete ? {...flaggedAsDelete, delete: false} : vacationPattern(date);
                patterns = mergePatterns([...patterns.filter(p => p.id !== flaggedAsDelete?.id), pattern]);
            } else {
                patterns = removeOrSplitPatterns(patterns, date)
            }
            employee.vacationPatterns = patterns;
            localToSave = [...localToSave.filter(e => e.id !== employee.id), {...employee, vacationPatterns: patterns}]
        })
        setToSave(toSave.filter(e => !localToSave.find(le => le.id === e.id)).concat(localToSave))
    }

    /**
     * Function for merging patterns that proceed each other
     * @param patterns
     * @returns {*}
     */
    const mergePatterns = (patterns) => {
        const patternsToKeep = patterns.filter(p => p.delete !== true);
        const patternsToDelete = patterns.filter(p => p.delete === true);

        patternsToKeep.sort((a, b) => moment(a.series.from).diff(moment(b.series.from)));

        const mergedPatterns = patternsToKeep.reduce((acc, current) => {
            const last = acc[acc.length - 1];
            if (last && moment(current.series.from).diff(moment(last.series.until), 'days') === 1) {
                last.series.until = current.series.until;
                last.summary = "Ferie " + last.series.from + " - " + last.series.until
                last.update = true;
                if(!current.id?.includes(placeholderId)) patternsToDelete.push({...current, delete: true})
            } else {
                acc.push(current);
            }
            return acc;
        }, []);
        return mergedPatterns.concat(patternsToDelete);
    }

    /**
     * Function for removing and/or splitting vacation patterns. Scenarios:
     * 1. Single standalone vacation week is ticked off
     * 2. Vacation week at the start of a vacation period is removed
     *      - series.from in the pattern is moved one week ahead
     * 3. Vacation week at the end of a vacation period is removed
     *      - series.until in the pattern is moved one week back
     * 4. Vacation week in the middle of a vacation period is removed
     *      - the pattern is split into two patterns:
     *          - first pattern has series.from set to the initial patterns from date and series.until set to Sunday before the removed week
     *          - second pattern has series.from set to Monday week after the removed patterns week and series.until set to the initial patterns until
     * @param patterns
     * @param monday
     * @returns {*}
     */
    const removeOrSplitPatterns = (patterns, monday) => {
        const patternsToKeep = patterns.filter(p => p.delete !== true);
        const patternsMarkedAsDelete = patterns.filter(p => p.delete === true)
        const sunday = moment(monday).endOf('isoWeek');
        return patternsToKeep
            .reduce((acc, current) => {
                if (!patternWithinPeriod(current, monday, monday)) {
                    acc.push(current);
                    return acc;
                } else if (current.delete === true) {
                    return acc;
                }
                const days = getDaysFromDatePattern(
                    dynomite,
                    current,
                    moment(current.series.from),
                    moment(current.series.until)
                );
                if (days.length === 7) {
                    acc.push({...current, delete: true})
                } else {
                    if (moment(current.series.from).isSame(moment(monday))) {
                        const pattern = {
                            ...current,
                            update: true,
                            series: {...current.series, from: moment(monday).add(1, 'weeks').format('YYYY-MM-DD')}
                        }
                        pattern.summary = "Ferie " + pattern.series.from + " - " + pattern.series.until;
                        acc.push(pattern)
                    } else if (moment(current.series.until).diff(sunday, 'days') === 0) {
                        const pattern = {
                            ...current,
                            update: true,
                            series: {...current.series, until: moment(sunday).subtract(1, 'weeks').format('YYYY-MM-DD')}
                        };
                        pattern.summary = "Ferie " + pattern.series.from + " - " + pattern.series.until;
                        acc.push(pattern)
                    } else {
                        const pattern = {
                            ...current,
                            update: true,
                            series: {...current.series, until: moment(monday).subtract(1, 'days').format('YYYY-MM-DD')}
                        }
                        pattern.summary = "Ferie " + pattern.series.from + " - " + pattern.series.until;
                        acc.push(pattern)
                        const pattern2 = {
                            ...current,
                            id: placeholderId,
                            series: {...current.series, from: moment(monday).add(1, 'weeks').format('YYYY-MM-DD')}
                        };
                        pattern2.summary = "Ferie " + pattern2.series.from + " - " + pattern2.series.until;
                        acc.push(pattern2)
                    }
                }

                return acc;
            }, []).concat(patternsMarkedAsDelete);
    }

    /**
     * Method for posting/putting/deleting vacation patterns
     * @returns {Promise<void>}
     */
    const save = async () => {
        setShowSavingModal({ display: true, saveCount: 0, toSave: toSave.length });
        for(const e of toSave) {
            setShowSavingModal({ display: true, saveCount: showSavingModal.saveCount++, toSave: toSave.length, employee: e.name});
            for (const pattern of e.vacationPatterns) {
                const del = pattern.delete === true;
                const update = pattern.update === true;
                const id = pattern.id;
                ['id', 'registered', 'updated', 'delete', 'update'].forEach(field => delete pattern[field])

                if(!id.includes(placeholderId)) {
                    if(del) {
                        await dynoapeAPI.delete(`/api/v1/department/${departmentId}/employee/${e.id}/pattern/${id}?type=${patternCase}`);
                    } else {
                        if(update === true) {
                            await dynoapeAPI.put(`/api/v1/department/${departmentId}/employee/${e.id}/pattern/${id}?type=${patternCase}`, pattern);
                        }
                    }
                } else if(!del && id.includes(placeholderId)) {
                    await dynoapeAPI.post(`/api/v1/department/${departmentId}/employee/${e.id}/pattern?type=${patternCase}`, pattern);
                }
            }
        }
        setToSave([]);
        setShowSavingModal({ display: false, saveCount: 0, toSave: 0, employee: null })
        setEmployees((await dynoapeAPI.get(`/api/v1/department/${departmentId}/employees${PatternCase.getIncludeQueryParams(patternCase)}`)).filter(e => e.enabled).sort(function (a, b) {
            return a.priority === b.priority ? new Date(a.registered) - new Date(b.registered) : a.priority - b.priority
        }))
    }

    /**
     * Method for handling the select of a BP
     * @param selectedTaskOption
     * @returns {Promise<void>}
     */
    const handleSelectTaskChange = async (selectedTaskOption) => {
        if(!selectedTaskOption) {
            reset();
            return removeSelectedTask();
        }
        setColumns(undefined);
        let t = await dynoapeAPI.get(`/api/v1/department/${departmentId}/task/${selectedTaskOption.value}`);
        setStartDate(t.config.startDate);
        setNrOfWeeks(t.config.nrOfWeeks);
        const mon = getWeekdaysFromTask(t, [1]);
        setMondays(mon.slice(0, nrInView));
        setSelectedTask(t);
        setSelectedTaskOption({value: selectedTaskOption.value, label: selectedTaskOption.label});
        setViewStartIndex(0)
        scrollHeight();
    }

    return (
        <PlannerWrapper>
            <p><label style={{fontSize: "16px"}}>
                Her kan du planlegge hvilke uker hver ansatt skal ha ferie
            </label></p>
            <br/>
            <Flex flexDirection="row">
                <Button primary style={{marginTop: "0px" }} onClick={() => save()}
                        data-for="save"
                        disabled={toSave.length === 0}
                        data-tip="Lagre dine helge-spesifikasjoner for de ansatte">Lagre</Button>
                <Tooltip id="save" />
                {taskSelect(handleSelectTaskChange)}
                {searchBar()}
                {columns &&
                    <WeekJumper flipSize={viewFlipSizeRef.current} setFlipSize={setViewFlipSize}
                                viewStartIndex={viewStartIndex} setViewStartIndex={setViewStartIndex}
                                nrInView={nrInView} jumpTitle="Hopp antall uker" totalNrOfColumns={nrOfWeeks}>
                    </WeekJumper>
                }
            </Flex>
            {columns === undefined && <p>Laster...</p>}
            {columns && <ReactGridWrapper>
                <ReactGrid
                    customCellTemplates={{
                        'checkbox': CheckboxCellTemplate
                    }}
                    rows={[
                        getReactGridHeaderRow(columns, 'header', 'text', 60),
                        ...getReactGridRows(columns, employees.map(e => getLocalOrGlobalEmployee(e.id)), mondays[0], mondays[mondays.length -1]).map((row, idx) => idx % 2 !== 0 ?
                            { ...row, cells: row.cells.map(cell => ({ ...cell, style: {  background: 'rgba(0,0,0,0.07)', color: !row._employee.enabled ? "#888" : "black" } })) }
                            : { ...row, cells: row.cells.map(cell => ({ ...cell, style: { color: !row._employee.enabled ? "#888" : "black" } })) }
                        ),
                        getReactGridHeaderRow(columns, 'total', 'total')
                    ]}
                    columns={columns}
                    stickyTopRows={1}
                    stickyBottomRows={1}
                    stickyLeftColumns={3}
                    onCellsChanged={handleChanges}
                    enableFillHandle
                    enableRangeSelection
                    enableRowSelection
                />
            </ReactGridWrapper>}
            {showSavingModal.display && saveModal()}
            {routerPrompt(toSave.length > 0)}
            {dynomiteErrorPanel(dynomite, configWarnings, dynomiteInternalError, 0, () => {}, true)}
            {dynomiteErrorPanel(dynomite, configErrors, dynomiteInternalError)}
        </PlannerWrapper>
    )
}