import React, {useState} from "react";
import {Button} from "rendition";
import { DayShift } from "./day-shift";
import styled from "styled-components";
import Tooltip from "../lib/tooltip";
import Select from "react-select";
import {
  emptyDemand,
  isTaskReadOnly,
  shiftTypeLimitInOrdinaryBp,
  sortShiftCodesByDaySegment
} from "../lib/common";
import {ShiftStats} from "./shift-stats";

export const DayInput = ({
  register,
  unregister,
  task,
  employees,
  day,
  errors,
  positions,
  traitNames,
  watch,
  patchTask,
  doPatch,
  setValue
}) => {
  const [selectedShift, setShift] = useState(null);
  const [showStatsPage, setShowStatsPage] = useState(task.status !== "FROZEN");
  const shiftButtons = sortShiftCodesByDaySegment(Object.keys(task.config.weeklyCoverDemands[day]), Object.values(task.config.shiftTypes))?.map(shift =>
    <div key={shift} style={{float: "left", paddingBottom: "15px"}}>
      <Button primary={selectedShift === shift} data-for={shift} data-tip="Her ser du hvilken vakt du skal spesifisere/står i. Dersom du ikke har f.eks. “Langvakt/Mellomvakt”, så settes det null som tall i minimum- og maksimum antall ansatte"
              onClick={() => {setShift(shift); setShowStatsPage(false);}}>{shift}</Button>
      <Tooltip id={shift} />
    </div>
  );

  return (
    <div>
        <DayCard>
          <div style={{maxWidth: "850px", minWidth: "850px"}}>
            {shiftButtons}
          </div>
          {task.status !== "FROZEN" && <div style={{marginLeft: "30px"}}><Button primary={showStatsPage} success={!showStatsPage} onClick={() => {
            setShowStatsPage(!showStatsPage);
            setShift(null);
          }}>{!showStatsPage ? "Vis" : "Skjul"} sammendrag</Button></div>}
          {!isTaskReadOnly(task) && Object.keys(task.config.weeklyCoverDemands[day]).length < shiftTypeLimitInOrdinaryBp() && <div style={{marginLeft: "20px"}}>
                <Select
                    placeholder={`Legg til vaktkode (maks. ${shiftTypeLimitInOrdinaryBp()})`}
                    isSearchable={false}
                    closeMenuOnSelect={false}
                    controlShouldRenderValue={false}
                    options={sortShiftCodesByDaySegment(Object.keys(task.config.shiftTypes), Object.values(task.config.shiftTypes))
                        ?.filter(sh => !Object.keys(task.config.weeklyCoverDemands[day]).includes(sh))
                        ?.map(sh => ({label: sh, value: sh}))}
                    onChange={(e) => {
                        let baseSh = task.config.shiftTypes[e.value].daySegment;
                        doPatch([{
                            op: "add",
                            path: `/config/weeklyCoverDemands/${day}/${e.value}`,
                            value: emptyDemand(baseSh)
                        }], "Vaktkode lagt til");
                        setTimeout(() => {
                        }, 1000)
                      setShift(e.value)
                    }}
                    styles={{ control: base => ({ ...base, borderWidth: "3px", borderColor: "#222"}), placeholder: base => ({...base, color: "#222"})}}
                >
                </Select>
            </div>}
        </DayCard>

        {selectedShift && Object.keys(task.config.weeklyCoverDemands[day]).includes(selectedShift) &&
            <DayShift register={register} unregister={unregister} task={task} day={day}
                      shift={selectedShift} errors={errors} positions={positions} traitNames={traitNames} watch={watch}
                      patchTask={patchTask} doPatch={doPatch} setShift={setShift} setValue={setValue}
            />}
      {showStatsPage && <ShiftStats task={task} employees={employees}></ShiftStats>}
    </div>
  );
};

const DayCard = styled.div`
  display: flex;
  background-color: #FFF;
  border-radius: 10px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
  margin-bottom: 25px;
  padding: 25px;
  button {
    margin-right: 10px;
  }
  border-top-left-radius: 0px;
  width: 1350px;
`;