//TODO - refactor button functionality!!!!
import React, { useState } from "react";
import { Button } from "rendition";
import styled from "styled-components";
import { VariableRules } from "./variable-rules";
import { LocalRules } from "./local-rules";
import { TaskShiftSums } from "./task-shifts-sum"

const TaskSettings = ({
  register,
  task,
  control,
  watch,
  errors,
  shifts,
  setValue,
}) => {
  const [step, setStep] = useState(0);

  if (step === 1) {
    return (
      <TaskTab>
        <RuleCard>
          <Button mr="10px" onClick={() => setStep(0)}>Regler</Button>
        </RuleCard>
      </TaskTab>
    );
  }

  return (
    <TaskTab>
      <RuleCard style={{ display: "none" }}>
        <Button mr="10px" primary>Regler</Button>
        <Button onClick={() => setStep(1)}>Faste møter</Button>
      </RuleCard>

      <RuleCard style={{ borderTopLeftRadius: "0px" }}>
        <h3>Innstillinger</h3>
        <VariableRules register={register} config={task.config} control={control} errors={errors} watch={watch} />
        <h3>Antall vakter</h3>
        <TaskShiftSums register={register} config={task.config} control={control} errors={errors} watch={watch} shifts={shifts} />
      </RuleCard>

      <RuleCard>
        <h3>Lokale regler</h3>
        <LocalRules register={register} config={task.config} setValue={setValue} errors={errors}/>
      </RuleCard>
    </TaskTab>
  );
};

export default TaskSettings;

const RuleCard = styled.div`
  background-color: #FFF;
  border-radius: 10px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
  margin-bottom: 25px;
  padding: 25px;
  width: 1350px;
  button {
    margin-right: 10px; 
  }
`;

const TaskTab = styled.div`
  display: flex;
  flex-direction: column;
`;
