import React from "react";
import { Group } from "@visx/group";
import { scaleQuantile, scaleOrdinal, scaleLinear } from "@visx/scale";
import { range } from "@visx/vendor/d3-array";
import { format } from "@visx/vendor/d3-format";
import { timeFormat } from "@visx/vendor/d3-time-format";
import {
  timeMonday,
  timeMonth,
  timeMonths,
  timeDays,
} from "@visx/vendor/d3-time";
import {
  LegendOrdinal,
  LegendQuantile,
  LegendLinear,
  LegendItem,
  LegendLabel,
} from "@visx/legend";
import {
  fullSchemeRdBu,
  getDayOfWeek,
  getMonthWeek,
  daysInMonth,
} from "../../lib/common";
import moment from "moment";

export default class CalendarHeatmap extends React.Component {
  /**
   * Generates the path for the month based on the cell size and months week
   * composition.
   *
   * @param {type} t0 - the starting date
   * @param {type} cellSize - the size of each cell
   * @return {type} the month path string
   */
  getMonthPath(t0, cellSize) {
    const d0 = getDayOfWeek(t0);
    const t1 = new Date(t0.getFullYear(), t0.getMonth() + 1, 0);
    const d1 = getDayOfWeek(t1);
    const w1 = getMonthWeek(t1);

    return `
            M${cellSize},${d0 * cellSize}
            H0 V${7 * cellSize}
            H${w1 * cellSize} V${(d1 + 1) * cellSize}
            H${(w1 + 1) * cellSize} V0
            H${cellSize}Z
        `;
  }

  /**
   * Retrieves the domain for the given scale information and data.
   *
   * If no scale information is present for 'ordinal' scale, the domain is
   * the set of unique values in the data.
   *
   * If no scale information is present for 'quantile' or 'linear' scale,
   * the domain is minimum and maximum numberical values in the data.
   *
   * There is no check for data to be valid. For 'ordinal' scale, we assume
   * values to be string. For 'quantile' and 'linear' scale, we assume
   * values to be numbers.
   *
   * @param {string} scaleName - The name of the scale (ordinal, quantile, linear).
   * @param {object} scale - The scale object.
   * @param {object} data - The data object.
   * @return {array|null} The domain for the scale, or null if scaleName is not recognized.
   */
  getDomain(scaleName, scale, data) {
    switch (scaleName) {
      case "ordinal":
        return typeof scale.domain !== "undefined"
          ? scale.domain
          : [
              ...new Set(
                [...data.values()].reduce(
                  (accumulator, monthMap) =>
                    accumulator.concat([...monthMap.values()]),
                  []
                )
              ),
            ];
      case "quantile":
      case "linear":
        return typeof scale.domain !== "undefined"
          ? scale.domain
          : [...data.values()].reduce(
              (accumulator, monthMap) => {
                let monthMax = Math.max(...monthMap.values());
                let monthMin = Math.min(...monthMap.values());
                return [
                  accumulator[0] > monthMin ? monthMin : accumulator[0],
                  accumulator[1] < monthMax ? monthMax : accumulator[1],
                ];
              },
              [9999, -9999]
            );
      default:
        return null;
    }
  }

  /**
   * Render the calendar heatmap.
   *
   * @return {JSX.Element} - The rendered calendar heatmap.
   */
  render() {
    const {
      data,
      inMonths,
      width,
      height,
      margin = {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
      },
      ordinalScale,
      quantileScale,
      linearScale,
      colorScheme = fullSchemeRdBu,
      undefinedColor = "#dddddd",
      legendLabel = "Legend",
      hidden = true,
    } = this.props;

    if (hidden) {
      return <div></div>;
    }

    const formatMonth = timeFormat("%Y-%m");
    const formatDay = timeFormat("%Y-%m-%d");
    const formatValue = format(".0f");

    let tmpMonths = inMonths ? inMonths : [...data.keys()].sort();
    if (Array.isArray(tmpMonths) && tmpMonths.length === 0) {
      return <div>Det er ikke nok data til å generere visualisering</div>;
    }

    const firstDay = timeMonth(new Date(tmpMonths[0]));
    const lastDay = timeMonth(new Date(tmpMonths[tmpMonths.length - 1]));
    lastDay.setDate(
      lastDay.getDate() + daysInMonth(tmpMonths[tmpMonths.length - 1]) - 1
    );
    if (isNaN(firstDay.getTime()) || isNaN(lastDay.getTime())) {
      return <div>Ugyldig dato. Kan ikke generere visualisering</div>;
    }

    const months = timeMonths(firstDay, lastDay).map((day) => {
      return formatMonth(day);
    });

    const weeks = [
      ...timeDays(firstDay, lastDay)
        .reduce((weekSet, day) => {
          let mom = moment(day);
          weekSet.add(`${mom.isoWeekYear()}-${mom.isoWeek()}`);
          return weekSet;
        }, new Set())
        .values(),
    ];

    if (months.length > 13) {
      return <div>Visualiserings kan kun genereres over 12 måneder</div>;
    }

    const monthsStartWeek = months.map((month) => {
      return timeMonday.count(new Date(months[0]), new Date(month));
    });

    const widthCellsCount = timeMonday.count(firstDay, lastDay) + 1;
    const cellSize = Math.min(
      (height - margin.top - margin.bottom - 21 - 21) / 7,
      (width - margin.left - margin.right - 2) / widthCellsCount
    );
    if (cellSize < 1) {
      return <div>Ingen visualisering å vise</div>;
    }
    const calcHeight = cellSize * 7 + margin.top + margin.bottom + 21 + 21;
    const calcWidth =
      cellSize * widthCellsCount + margin.left + margin.right + 2;

    const scale =
      typeof ordinalScale !== "undefined"
        ? {
            name: "ordinal",
            opts: ordinalScale,
            scaleFunc: scaleOrdinal(),
            legend: LegendOrdinal,
          }
        : typeof quantileScale !== "undefined"
        ? {
            name: "quantile",
            opts: quantileScale,
            scaleFunc: scaleQuantile(),
          }
        : {
            name: "linear",
            opts: linearScale,
            scaleFunc: scaleLinear(),
          };

    let domain = this.getDomain(scale.name, scale.opts, data);
    if (domain[0] == 9999 && domain[1] == -9999) {
      domain = [0, 0];
    }
    const colRange =
      colorScheme[
        scale.name === "ordinal"
          ? domain.length
          : typeof scale.opts.categories !== "undefined"
          ? scale.opts.categories
          : Math.min(domain[1] - domain[0], 5)
      ];
    const color = scale.scaleFunc.domain(domain).range(colRange);

    return (
      <div>
        <svg
          width={calcWidth}
          height={calcHeight}
          key={"calendar-heatmap"}
          className="calendar-heatmap"
        >
          <Group
            transform={`translate(${margin.left},${margin.top})`}
            key={`calendar-heatmap-group`}
          >
            {months.map((month, monthId) => (
              <Group
                transform={`translate(${
                  monthsStartWeek[monthId] * cellSize
                },0)`}
                key={`month-group-${month}`}
              >
                <text
                  transform={`translate(${cellSize * 1.5},${10})`}
                  fontFamily="sans-serif"
                  key={`month-label-${formatDay(month)}`}
                >
                  {month}
                </text>
                <Group
                  transform="translate(0,20)"
                  key={`month-days-group-${formatDay(month)}`}
                >
                  {range(0, daysInMonth(month)).map((dayIndex) => {
                    const day = new Date(month + "-" + (dayIndex + 1));
                    const dayString = formatDay(day);
                    const value = data.has(month)
                      ? data.get(month).get(dayString)
                      : undefined;
                    return (
                      <rect
                        fill={
                          typeof value !== "undefined"
                            ? color(value)
                            : undefinedColor
                        }
                        stroke="#ccc"
                        width={cellSize}
                        height={cellSize}
                        x={getMonthWeek(day) * cellSize}
                        y={getDayOfWeek(day) * cellSize}
                        rx={cellSize / 5}
                        key={`day-rect-${dayString}`}
                      >
                        <title>
                          {dayString} :{" "}
                          {typeof value === "number"
                            ? formatValue(value)
                            : value}
                        </title>
                      </rect>
                    );
                  })}
                </Group>
                <Group fill="none" stroke="#000" transform="translate(0,20)">
                  {
                    <path
                      d={this.getMonthPath(new Date(month), cellSize)}
                      strokeWidth={2}
                      strokeLinejoin="round"
                      key={`month-outlier-${formatDay(month)}`}
                    />
                  }
                </Group>
              </Group>
            ))}
          </Group>
          <Group
            transform={`translate(${margin.left},${
              cellSize * 7 + margin.top + 21
            })`}
            key={`calendar-heatmap-group-weeks`}
          >
            {weeks.map((week, weekId) => (
              <Group
                transform={`translate(${cellSize * weekId},0)`}
                key={`week-label-${weekId}`}
                width={cellSize}
                height={cellSize}
              >
                <rect
                  fillOpacity={0}
                  stroke="#ccc"
                  width={cellSize}
                  height={cellSize}
                  rx={0}
                  ry={0}
                  strokeDasharray={[2 * cellSize, cellSize]}
                ></rect>
                <text
                  fontFamily="sans-serif"
                  x={cellSize / 2}
                  y={cellSize / 2}
                  dominantBaseline="middle"
                  textAnchor="middle"
                  fontSize={(cellSize * 2) / 3}
                >
                  {week.split("-")[1]}
                </text>
              </Group>
            ))}
          </Group>
        </svg>
        <div
          className="legend"
          style={{
            display: "flex",
            flexDirection: "row",
            width: Math.max((colRange.length + 1) * 100, calcWidth - 22),
            marginBottom:
              margin !== "undefined" && margin.bottom !== "undefined"
                ? margin.bottom
                : 0,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="title">{legendLabel}:</div>
          {scale.name === "ordinal" ? (
            <LegendOrdinal
              scale={color}
              labelFormat={(d) => {
                return typeof d === "number" ? formatValue(d) : d;
              }}
            >
              {(labels) =>
                labels.map((label, i) => (
                  <LegendItem key={`legend-${i}`}>
                    <svg
                      width={cellSize}
                      height={cellSize}
                      style={{ margin: "2px 0px 0px 15px" }}
                    >
                      <rect
                        fill={label.value}
                        stroke="#ccc"
                        width={cellSize}
                        height={cellSize}
                        rx={cellSize / 5}
                      />
                    </svg>
                    <LegendLabel margin="0 4px">{label.text}</LegendLabel>
                  </LegendItem>
                ))
              }
            </LegendOrdinal>
          ) : scale.name === "quantile" ? (
            <LegendQuantile
              scale={color}
              labelFormat={(d) => {
                return typeof d === "number" ? formatValue(d) : d;
              }}
            >
              {(labels) =>
                labels.map((label, i) => (
                  <LegendItem key={`legend-${i}`}>
                    <svg
                      width={cellSize + 1}
                      height={cellSize + 1}
                      style={{ margin: "2px 0px 0px 15px" }}
                    >
                      <rect
                        fill={label.value}
                        stroke="#ccc"
                        width={cellSize}
                        height={cellSize}
                        rx={cellSize / 5}
                      />
                    </svg>
                    <LegendLabel margin="0 4px">
                      {formatValue(label.extent[0]) !==
                      formatValue(label.extent[1])
                        ? label.text
                        : formatValue(label.extent[0])}
                    </LegendLabel>
                  </LegendItem>
                ))
              }
            </LegendQuantile>
          ) : (
            <LegendLinear
              scale={color}
              labelFormat={(d) => {
                return typeof d === "number" ? formatValue(d) : d;
              }}
            >
              {(labels) =>
                labels.map((label, i) => (
                  <LegendItem key={`legend-${i}`}>
                    <svg
                      width={cellSize}
                      height={cellSize}
                      style={{ margin: "2px 0px 0px 15px" }}
                    >
                      <rect
                        fill={label.value}
                        stroke="#ccc"
                        width={cellSize}
                        height={cellSize}
                        rx={cellSize / 5}
                      />
                    </svg>
                    <LegendLabel margin="0 4px">{label.text}</LegendLabel>
                  </LegendItem>
                ))
              }
            </LegendLinear>
          )}
        </div>
      </div>
    );
  }
}
