//TODO - refactor button functionality!!!!
import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { Button, Modal, notifications } from "rendition";
import { DayShift } from "./day-shift";
import styled from "styled-components";
import Tooltip from "../lib/tooltip";
import {createPatch} from "rfc6902";
import { useForm } from "react-hook-form";
import {
  emptyDemand,
  isTaskReadOnly, isTaskStatusReadOnly,
  shiftTypeLimitInCustomBp,
  sortShiftCodesByDaySegment
} from "../lib/common";
import { dynoapeAPI } from "../api/dynoape";
import _ from 'lodash';
import moment from 'moment';
import Select from "react-select";

export const DayInputCustom = ({
  isRedDay,
  day,
  date,
  selectedShift,
  setSelectedShift,
  task,
  paramDailyCoverPatches,
  positions,
  traitNames,
  customDate,
  setCoverDemands,
  doPatch,
  setTask,
  validateButton,
  setUpdateTaskOngoing
}) => {
  const {
    register: customRegister,
    unregister: customUnregister,
    handleSubmit: customHandleSubmit,
    trigger: customTrigger,
    setValue: customSetValue,
    formState: { errors: customErrors, isDirty: customIsDirty },
    watch: customWatch
  } = useForm({ defaultValues: task, mode: 'onBlur' });
  const [notification, setNotification] = useState("");
  const [dailyCoverPatches, setDailyCoverPatches] = useState(paramDailyCoverPatches ? JSON.parse(JSON.stringify(paramDailyCoverPatches)) : {});
  const [inputData, setInputData] = useState({});
  const [highlightedInputs, setHighlightedInputs] = useState();
  const [loaded] = useState(true);
  const [showResetShiftModal, setShowResetShiftModal] = useState(false);
  const { departmentId, taskId } = useParams();
  const data = customWatch();
  const dataRef = useRef(data);
  dataRef.current = data;
  const isDirtyRef = useRef(customIsDirty);
  isDirtyRef.current = customIsDirty;
  const [shifts, setShifts] = useState(Object.keys(task.config.weeklyCoverDemands[day]));
  const [isSaving, setIsSaving] = useState(false);

  const inputDataRef = useRef();
  inputDataRef.current = inputData;
  const dailyCoverPatchesRef = useRef();
  dailyCoverPatchesRef.current = dailyCoverPatches;

  const allTraitReqKeys = new Set(
    Object.values(task.config.weeklyCoverDemands)
      .flatMap(Object.entries)
      .map(([day, cd]) => [day, cd.traitRequirements])
      .flatMap(([day, trs]) => Object.keys(trs ? trs : {}).map(k => `${day}/${k}`))
  );

  useEffect(() => {
    setTimeout(() => {
      setHighlightedInputs(paramDailyCoverPatches?.[customDate]?.map(patch => {
        return patch.path;
      }));
      customSetValue(`config.weeklyCoverDemands`, JSON.parse(JSON.stringify(task.config.weeklyCoverDemands)));
      let shifts_tmp = Object.keys(task.config.weeklyCoverDemands[day]);
      paramDailyCoverPatches?.[customDate]?.forEach(patch => {
        if(patch.op == 'add' && (patch.path.match(new RegExp("/", "g")) || []).length == 1) {
          let code = patch.path.replaceAll("/", "");
          if(!shifts_tmp.includes(code)) {
            shifts_tmp.push(code);
          }
        }
        customSetValue(`config.weeklyCoverDemands.${!isRedDay ? moment(customDate).format("dddd").toUpperCase() : "HOLIDAYS"}${patch.path.split("/").join(".")}`, patch.value);
      });
      setShifts(shifts_tmp);
      setDailyCoverPatches(JSON.parse(JSON.stringify(paramDailyCoverPatches)));
    }, 0)
  }, [customDate, task, paramDailyCoverPatches]);

  useEffect(() => {
    if (notification == "") return;
    notifications.removeNotification(notification.id - 1);

    notifications.addNotification({
      id: notification.id,
      content: notification.msg,
      duration: 2000,
      container: "top-center",
      type: notification.type
    });
  }, [notification]);

  const save = async (data) => {
    setUpdateTaskOngoing(true);
    const ok = await customTrigger();
    if (ok && Object.keys(customErrors).length === 0 && !isTaskStatusReadOnly(task.status)) {
      const taskPatch = createPatch(task.config.weeklyCoverDemands[day], data.config.weeklyCoverDemands[day]).filter(operation => {
        return ((operation.op !== "remove" || operation.path.includes("employees")) && operation.path !== "/name")
      });
      await putCoverPatches(taskPatch, "Tilpasset bemanningsplan lagret", true);
    }
    setUpdateTaskOngoing(false);
  };

  const putCoverPatches = async (taskPatch, message, force) => {
    let newdailyCoverPatches = JSON.parse(JSON.stringify(dailyCoverPatchesRef.current));

    if (taskPatch.length > 0) {
      newdailyCoverPatches[customDate] = taskPatch;
    } else {
      delete newdailyCoverPatches[customDate];
    }

    const notPatchingDeletedTraitRequirement = operation => {
      const match = operation.path.match(/\/(.*)\/traitRequirements\/(.*)\/.*/);
      if (match) {
        const path = `${match[1]}/${match[2]}`;
        return allTraitReqKeys.has(path);
      }
      return true;
    }
    newdailyCoverPatches = Object.fromEntries(
      Object.entries(newdailyCoverPatches)
        .map(([key, value]) => [key, value.filter(notPatchingDeletedTraitRequirement)])
    );

    if (!_.isEqual(dailyCoverPatchesRef.current, newdailyCoverPatches) || force) {

      let patch = createPatch(dailyCoverPatchesRef.current, newdailyCoverPatches).map(p => {
        p.path = "/config/dailyCoverPatches" + p.path;
        return p;
      })

      const response = await dynoapeAPI.patch(`/api/v1/department/${departmentId}/task/${taskId}`, patch);

      if (response) {
        setDailyCoverPatches(response.config.dailyCoverPatches);
        setCoverDemands(response.config.dailyCoverPatches);
        setHighlightedInputs(response.config.dailyCoverPatches?.[customDate]?.map(patch => {
          return patch.path;
        }));
        setNotification({ "id": "custom", "msg": message, "type": "success" });
      }
    }
  };

  const deletePatch = async (input) => {
    setUpdateTaskOngoing(true)
    let path = "/".concat(input.replaceAll(day.concat("."), "").replaceAll(".", "/"))
    let newDailyCoverPatches = _.cloneDeep(dailyCoverPatchesRef.current);
    newDailyCoverPatches[customDate] = newDailyCoverPatches[customDate].filter(patch => {
      return !patch.path.startsWith(path);
    });

    let patch = createPatch(dailyCoverPatchesRef.current, newDailyCoverPatches).map(p => {
      p.path = "/config/dailyCoverPatches" + p.path;
      return p;
    })

    const response = await dynoapeAPI.patch(`/api/v1/department/${departmentId}/task/${taskId}`, patch);
    setTask(response)
    setUpdateTaskOngoing(false)
  };

  const shiftHighlighted = (sh) => {
    return highlightedInputs?.filter(p => p.startsWith("/".concat(sh).concat("/"))).length > 0;
  }
  const shiftButtons = sortShiftCodesByDaySegment(shifts, Object.values(task.config.shiftTypes)).map(shift =>
    <div key={shift} style={{float: "left", paddingBottom: "15px"}}>
      <Button className={
          !Object.keys(task.config.weeklyCoverDemands[day]).includes(shift) && selectedShift !== shift
              ? "input-highlight"
              : (shiftHighlighted(shift) && selectedShift !== shift ? "input-partial-highlight" : "")}

              primary={selectedShift === shift} data-for={shift} data-tip="Her ser du hvilken vakt du skal spesifisere/står i. Dersom du ikke har f.eks. “Langvakt/Mellomvakt”, så settes det null som tall i minimum- og maksimum antall ansatte" onClick={() => setSelectedShift(shift)}>{shift}</Button>
      <Tooltip id={shift} />
    </div>
  );

  const appendDefaultTraitsAndPositionCovers = (demand) => {
    if(traitNames) {
      Object.keys(traitNames).forEach(t => {
        demand.traitRequirements[t] = {traitId: t, operator: "min", value: 0}
      });
    }
    if(positions) {
      positions.forEach(p => {
        demand.positionRequirements[p.id] = 0;
      });
    }
    return demand;
  }

  const addShiftType = async (sh) => {
    setUpdateTaskOngoing(true)
    setIsSaving(true)
    let baseSh = task.config.shiftTypes[sh].daySegment;
    const taskPatch = createPatch(task.config.weeklyCoverDemands[day], data.config.weeklyCoverDemands[day]).filter(operation => {
      return ((operation.op !== "remove" || operation.path.includes("employees")) && operation.path !== "/name")
    });
    taskPatch.push({op: "add", path: `/${sh}`, value: appendDefaultTraitsAndPositionCovers(JSON.parse(JSON.stringify(emptyDemand(baseSh))))})

    await putCoverPatches(taskPatch, "Vaktkode lagt til");
    setInputData(task)
    setIsSaving(false)
    setUpdateTaskOngoing(false)
  }

  const removeShiftTypeCustom = async (sh) => {
    setIsSaving(true)
    setUpdateTaskOngoing(true)
    const taskPatch = createPatch(task.config.weeklyCoverDemands[day], data.config.weeklyCoverDemands[day]).filter(operation => {
      return ((operation.op !== "remove" || operation.path.includes("employees")) && operation.path !== "/name")
    }).filter(p => p.path !== ("/" + sh));
    await putCoverPatches(taskPatch, "Vakttype fjernet");

    setShifts(shifts.filter(s => s !== sh));
    setIsSaving(false)
    setUpdateTaskOngoing(false)
    setInputData(task)
  }

  useEffect(() => {
    return () => {
      if(isDirtyRef.current) {
        save(dataRef.current);
      }
    }
  }, [])

  return (
    <form onSubmit={customHandleSubmit(save)} onChange={() => { customTrigger(); }}>
      <DayCard>
        <div style={{maxWidth: "600px", minWidth: "600px", marginRight: "70px"}}>
          {shiftButtons}
        </div>
        <Button tertiary plain style={{ gap: "1em", float: "left", height: "40px"}}
                 disabled={!((!isTaskReadOnly(task) && Object.keys(task.config.weeklyCoverDemands[day]).includes(selectedShift)) && (
                     highlightedInputs?.filter(p => p.startsWith("/".concat(selectedShift).concat("/"))).length>0)) }
                 onClick={() => setShowResetShiftModal(true)}>
          <img src="/assets/icons/roll.png" />
          Tilbakestill vakt
          {showResetShiftModal && <Modal
            title="Er du sikker på at du vil fjerne tilpasningene for denne vakten?"
            cancel={() => setShowResetShiftModal(false)}
            done={() => {
              deletePatch(selectedShift);
              setShowResetShiftModal(false);
            }}
            action="Tilbakestill"
            cancelButtonProps={{
              children: 'Avbryt',
            }}
          />}
        </Button>
        <Button mr="5px" ml="5px" primary type="submit" >Lagre</Button>
        {!isTaskReadOnly(task) && validateButton}
        {!isTaskReadOnly(task) && shifts.length < shiftTypeLimitInCustomBp() && <div style={{marginLeft: "20px"}}>
          <Select
              isDisabled={isSaving}
              placeholder={`Legg til vaktkode (maks. ${shiftTypeLimitInCustomBp()})`}
              isSearchable={false}
              controlShouldRenderValue={false}
              options={sortShiftCodesByDaySegment(Object.keys(task.config.shiftTypes), Object.values(task.config.shiftTypes))
                  ?.filter(sh => !shifts.includes(sh))
                  ?.map(sh => ({label: sh, value: sh}))}
              onChange={(e) => {
                addShiftType(e.value);
                setSelectedShift(e.value)
              }}
              styles={{ control: base => ({ ...base, borderWidth: "3px", borderColor: "#222"}), placeholder: base => ({...base, color: "#222"})}}
          >
          </Select>
        </div>}
      </DayCard>

      {loaded && selectedShift && (shifts.includes(selectedShift)) &&
          <DayShift date={date} custom={true} register={customRegister} unregister={customUnregister} task={task} day={!isRedDay ? day : "HOLIDAYS"} shift={selectedShift} errors={customErrors}
                    positions={positions} traitNames={traitNames} watch={customWatch} highlightedInputs={highlightedInputs} deletePatch={deletePatch}
                    doPatch={doPatch} dailyCoverPatches={dailyCoverPatches} setValue={customSetValue}
                    removeShiftTypeCustom={removeShiftTypeCustom}/>}
    </form>
  );
};

const DayCard = styled.div`
  display: flex;
  background-color: #FFF;
  border-radius: 10px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
  margin-bottom: 25px;
  padding: 25px;
  button {
    margin-right: 10px;
  }
  border-top-left-radius: 0px;
  width: 1350px;
`;