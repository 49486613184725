import React from "react";
import ReactTooltip from 'react-tooltip';

const Tooltip = ({ id }) => {
  return (
    <ReactTooltip
      id={id}
      effect="solid"
      delayHide={200}
      delayShow={800}
      delayUpdate={200}
      border={true}
      multiline={true}
      type="light"
      globalEventOff='click'
    />
  )
}

export default Tooltip;