import React, {useContext, useEffect, useState} from "react";
import {Alert, Box, Button, Flex, Spinner, Txt} from "rendition";
import Accordion from "../accordion";
import {singleDaysDatePattern} from "../../lib/common";
import {dynoapeAPI} from "../../api/dynoape";
import {PatternCase} from "../../lib/pattern-case";
import dynomiteContext from "../dynomite";
import moment from "moment";
import DatePatternPicker from "./date-pattern-picker";

export default ({
                    departmentId,
                    employee,
                    setEmployees}) => {

    const [newInProgress, setNewInProgress] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [blockedPatterns, setBlockedPatterns] = useState(employee.blockedPatterns);
    const [vacationPatterns, setVacationPatterns] = useState(employee.vacationPatterns);
    const [dirties, setDirties] = useState([]);
    const placeholderIdPrefix = "placeholderId";
    const dynomite = useContext(dynomiteContext);
    const patternCase = PatternCase.BLOCKED_PATTERN;

    useEffect(() => {
        setBlockedPatterns(employee.blockedPatterns);
        setVacationPatterns(employee.vacationPatterns);
    }, [employee])

    /**
     * Function for adding a dirty pattern to dirties and blockedPatterns for display.
     * Requested when an update has been made and the given pattern has not been saved
     * @param pattern
     */
    const setPattern = (pattern) => {
        setDirties([...dirties.filter(id => id !== pattern.id), pattern.id])
        setBlockedPatterns(blockedPatterns.map(p => p.id === pattern.id ? pattern : p));
    }

    /**
     * Function for validating a pattern using dynomite
     * @param pattern
     * @returns {[boolean,undefined]}
     */
    const validatePattern = (pattern) => {
        try {
            dynomite.dynomite.parseDatePattern(JSON.stringify(pattern));
        } catch (e) {
            return e.payload.reduce((acc, err) => {
                if (Object.hasOwn(err, 'tag') && err.path) {
                    acc[err.path] = dynomite.dynomite.formatError(err)
                } else if (err.tag === "Syntax") {
                    acc['Syntax'] = "Noen påkrevde felter er tomme. Vennligst korriger"
                }
                return acc;
            }, {});
        }
        return undefined;
    }

    /**
     * Function for either posting or putting a blocked pattern.
     * Will post if id contains 'placeholderIdPrefix', and put if not
     * @param patt
     * @returns {Promise<void>}
     */
    const save = async (patt) => {
        const errors = validatePattern(patt);
        if (errors) {
            return;
        }

        setIsSaving(true)
        let newPattern = JSON.parse(JSON.stringify(patt));
        ['id', 'registered', 'updated'].forEach(field => delete newPattern[field])
        let blocked = blockedPatterns;
        if(patt.id.includes(placeholderIdPrefix)) {
            const resp = await dynoapeAPI.post(`/api/v1/department/${departmentId}/employee/${employee.id}/pattern?type=${patternCase}`, newPattern);
            if(resp) blocked = blockedPatterns.map(p => p.id.includes(placeholderIdPrefix) ? resp : p);
        } else {
            const resp = await dynoapeAPI.put(`/api/v1/department/${departmentId}/employee/${employee.id}/pattern/${patt.id}?type=${patternCase}`, newPattern);
            if(resp) blocked = blockedPatterns.map(p => p.id === resp.id ? resp : p);
        }
        setBlockedPatterns(blocked);
        employee.blockedPatterns = blocked;
        setEmployees((prev) => ([
            ...prev.filter(e => e.id !== employee.id),
            employee
        ].sort(function (a, b) {
            return a.priority === b.priority
                ? new Date(a.registered) - new Date(b.registered)
                : a.priority - b.priority
        })))
        setNewInProgress(false)
        setIsSaving(false);
        setDirties(dirties.filter(id => id !== patt.id));
    }

    /**
     * Method for deleting a pattern
     * @param patt
     * @param type
     * @returns {Promise<void>}
     */
    const del = async (patt, type=patt) => {
        setIsSaving(true)
        if(patt.id.includes(placeholderIdPrefix)) {
            setNewInProgress(false)
            setBlockedPatterns(blockedPatterns.filter(p => !p.id.includes(placeholderIdPrefix)));
        } else {
            await dynoapeAPI.delete(`/api/v1/department/${departmentId}/employee/${employee.id}/pattern/${patt.id}?type=${type || patternCase}`)
            if(type === PatternCase.VACATION) setVacationPatterns(vacationPatterns.filter(p => p.id !== patt.id));
            else setBlockedPatterns(blockedPatterns.filter(p => p.id !== patt.id));
        }
        setIsSaving(false);
    }

    const accordionConfig = (pattern) => {
        return {
            patternCase: patternCase,
            startOpen: pattern.id.includes(placeholderIdPrefix),
            scrollIntoView: pattern.id.includes(placeholderIdPrefix),
            pattern: pattern,
            setPattern: setPattern,
            error: validatePattern(pattern),
            readOnly: false,
            save: save,
            del: del,
            isSaving: isSaving,
            dirties: dirties
        }
    }

    /**
     * Method for sorting a list of pattern by update time
     * @param patterns
     * @returns {*}
     */
    const sortByUpdated = (patterns) => {
        return patterns?.sort(function (a, b) {
            if(!a.updated) {
                return -1
            } else if(!b.updated) {
                return 1
            } else {
                return new Date(b.updated) - new Date(a.updated);
            }
        });
    }

    return(
        <>
            <Button disabled={newInProgress} style={{marginBottom: "20px"}} onClick={() => {
                const p = {...singleDaysDatePattern("", [moment().format('YYYY-MM-DD')]), id: placeholderIdPrefix, includeDays: []};
                setNewInProgress(true);
                setBlockedPatterns([...blockedPatterns, p]);
            }}
            >
                Legg til ansattilpasninger
            </Button>
            {(blockedPatterns || []).length > 0 && <Accordion
                hidePanelDivider={true}
                keyFunc={(item) => item.id}
                items={sortByUpdated(blockedPatterns).map(p => PatternRow({...accordionConfig(p), patternCase: PatternCase.BLOCKED_PATTERN}))}
            />}
            {(vacationPatterns || []).length > 0 && <Accordion
                hidePanelDivider={true}
                keyFunc={(item) => item.id}
                items={vacationPatterns.map(p => PatternRow({...accordionConfig(p), readOnly: true, patternCase: PatternCase.VACATION}))}
            />}
        </>
    )
}

/**
 * Component for rendering an element in the accordion, where each element represents a blocked pattern.
 * @param patternCase
 * @param startOpen
 * @param scrollIntoView
 * @param pattern
 * @param setPattern
 * @param error
 * @param readOnly
 * @param save
 * @param del
 * @param isSaving
 * @param dirties
 * @returns {{scrollIntoView, label: JSX.Element, panel: JSX.Element, key, startOpen}}
 * @constructor
 */
const PatternRow = ({patternCase, startOpen, scrollIntoView, pattern, setPattern, error, readOnly, save, del, isSaving, dirties }) => {

    return {
        startOpen: startOpen,
        key: pattern.id,
        label: <Flex alignItems="center" justifyContent="space-between" style={{fontFamily: "Montserrat Alternates"}}>
            <Box style={{ "flex": "1 0 10%"}}><Txt style={{ "marginBottom": "1em" }}>{pattern.summary}</Txt></Box>
            <Box style={{ padding: "1em", marginLeft: "auto", gap: "1em", alignItems: "center" }}>
                {readOnly && <Alert plaintext info style={{ whiteSpace: "nowrap" }}>Kan kun endres i <i>{PatternCase.translate(patternCase)}</i></Alert>}
                {(pattern.id.includes("placeholder") || dirties.includes(pattern.id)) && <Alert plaintext warning style={{ whiteSpace: "nowrap" }}>Ikke lagret</Alert>}
                {pattern.disabled && <Alert plaintext danger style={{ whiteSpace: "nowrap" }}>Ikke aktiv</Alert>}
                {!pattern.disabled && <Alert plaintext success style={{ whiteSpace: "nowrap" }}>Aktiv</Alert>}
            </Box>
        </Flex>,
        scrollIntoView: scrollIntoView,
        panel: <>
            <Box style={{
                "margin": "0em 1em 1em 1em",
                "padding": "2em",
                "borderRadius": "10px",
                "boxShadow": "inset 0 1px 3px rgba(0,0,0,0.10), inset 0 1px 4px rgba(0,0,0,0.16)",
                "background": readOnly ? "rgb(245,245,245)": ""
            }}>
                <DatePatternPicker
                    pattern={pattern}
                    setPattern={setPattern}
                    error={error}
                    readOnly={readOnly}
                />
            </Box>

                    <Flex style={{ "padding": "2em", "gap": "1em", "alignItems": "center" }}>
                        {pattern.disabled && !readOnly && <Button
                            style={{ "marginLeft": "15px", "marginRight": "15px" }}
                            underline
                            success
                            disabled={isSaving}
                            onClick={() => setPattern({...pattern, disabled: false})}>Aktiver</Button>}
                        {!pattern.disabled && !readOnly && <Button
                            style={{ "marginLeft": "15px", "marginRight": "15px" }}
                            underline
                            warning
                            disabled={isSaving}
                            onClick={() => setPattern({...pattern, disabled: true})}>Deaktiver</Button>}
                        <Button
                            style={{ "marginLeft": "15px", "marginRight": "15px" }}
                            underline
                            danger
                            disabled={isSaving}
                            onClick={() => del(pattern, patternCase)}>Slett</Button>
                        <Button
                            style={{ "marginLeft": "15px", "marginRight": "15px" }}
                            primary
                            underline
                            disabled={isSaving || readOnly || error}
                            onClick={() => save(pattern)}>Lagre</Button>
                        {error && Object.values(error).map((err, idx) => (<Txt key={`${error}-${idx}`} style={{color: "red"}}><i>{err}</i></Txt>))}
                        <Spinner show={isSaving} />
                    </Flex>
        </>
    }
}