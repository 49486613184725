import styled from "styled-components";
import {Flex, Input} from "rendition";
import InputMask from "react-input-mask";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft} from "@fortawesome/free-solid-svg-icons/faChevronLeft";
import {faChevronRight} from "@fortawesome/free-solid-svg-icons/faChevronRight";
import React from "react";
import Tooltip from "./tooltip";

export const Card = styled.div`
  min-height: 180px;
  background-color: #FFF;
  border-radius: 10px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
  margin: 0 10px;
  padding-left: 25px;
`;

export const CardRow = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 25px;
`;

export const CardColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InputDivider = styled.div`
  min-width: 300px;
  display: flex;
  justify-content: space-between;
`;

export const InputWithValidation = styled.div`  
  display: flex;
  flex-flow: wrap;  
  position: relative;

  span {
    width: ${props => props.width || "400px"};   
    color: #BF3D2B;
    top: ${props => props.top || "40px"};    
    position: absolute;
  }
`;

export const InputWithValidationAddSpace = styled.div`  
  display: flex;
  flex-flow: wrap;    

  span {
    width: 100%;    
    color: #BF3D2B;   
  }
`;

export const CardHeader = styled.h3`
  margin-left: 85px;
  margin-top: 10px;
`;

export const CardTimeMessage = styled.span`
  float: right;
  margin: 18px 24px 8px 0px;
  font-size: 14px;
  color: rgb(48 105 112);
`;

export const CardLetter = styled.span`
  font-size: 26px;
  font-weight: 500;
  color: #FFF;
  padding-left: 14px;
`;

export const CardLetterBox = styled.div`
  position: absolute;
  color: white;
  background: #26565B;
  border-radius: 10px 0px 10px 0px;
  width: 100px;
  height: 45px;
  padding-top: 5px;
`;

export const AvatarCardEmployeePosition = styled.div`
  border-radius: 20px;
  width: 260px;
  padding: 5px;
  text-align: center;
  overflow-wrap: break-word;
`;

export const SolidInput = styled(Input)`
  border-color: rgb(204, 204, 204);
  padding: 10px;
`;

export const NumberInput = styled(SolidInput).attrs({type: "number", className: 'NumberInput'})`
  ::-webkit-inner-spin-button{
      -webkit-appearance: none; 
      margin: 0; 
  }
  ::-webkit-outer-spin-button{
      -webkit-appearance: none; 
      margin: 0; 
  }
  -moz-appearance: textfield;
`;

export const SettingsContainer = styled.div`
  background-color: white;
  padding: 0px 20px 20px 20px;
`;

export const InputCard = styled(Card)`
  display: flex;
  flex-direction: column;
  row-gap: 2em;
  padding: 20px 50px 30px 50px;
  min-width: 464px;
  max-width: 464px;
  min-height: 50px;
`;

export const TransparentCard = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 50px 0px 50px;
  min-width: 464px;
  max-width: 464px;
  min-height: 40px;
`;

export const EmployeeNumberInput = styled(SolidInput)`
  width: 100px;
  float: right;
`;

export const StyledInputMask = styled(InputMask).attrs({ className: 'StyledInputMask' })`
  background-color: #FFF;
  width: 80px;
  height: 38px;
  padding: 10px;
  box-sizing: border-box;
  font-size: inherit;
  font-family: inherit;
  margin: 0;
  border: 1px solid #E5E7f0;
  border-color: rgb(204, 204, 204);
  border-radius: 4px;
  &: focus {
    outline: none !important;
    border:1px solid #306970;
    box-shadow: 0 0 5px #306970;
  }
`;
export const InputRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  column-gap: 2em;
  background-color: #E2ECED;
  padding-left: 20px;
  padding-bottom: 10px;
`;
export const TextArea = styled.textarea`
    padding: 14px 20px;
    font-size: inherit;
    border: 1px solid rgb(229, 231, 240);
    border-radius: 4px;
    width: 100%;
    resize: vertical;
     width: inherit;
`;
export const YearAndMonthBox = styled.div`
    border: 1px solid #ccc;
    box-sizing: border-box;
    text-align: center;
    width: ${props => props.width}px;
    height: 50px;
    line-height: 50px;
    font-family: Montserrat Alternates;
    position: sticky;
    top: 0;
    background: rgb(245,245,245);
    z-index: 10;
`;

export const WeekBox = styled.div`
    border: 1px solid #ccc;
    box-sizing: border-box;
    text-align: center;
    width: ${props => props.width}px;
    height: 30px;
    line-height: 30px;
    font-family: Montserrat Alternates;
    color: ${props => props.fontColor};
`;

export const StickyWeekBox = styled(WeekBox)`
    position: sticky;
    top: 50px;
    z-index: 10;
    background: rgba(255, 255, 255, 1);
`;

export const WeekJumper = ({flipSize, setFlipSize, viewStartIndex, setViewStartIndex, nrInView, bottom="10px", marginLeft="20px",
                                jumpTitle="Hopp antall uker", totalNrOfColumns}) => {
    return (
        <div style={{ bottom: bottom, marginLeft: `${marginLeft}`}}>
            <Flex data-for="weekJumper"
                  data-tip={jumpTitle}>
                <FontAwesomeIcon
                    onClick={() => {
                        if(viewStartIndex > 0) {
                            setViewStartIndex(Math.max(viewStartIndex - parseInt(flipSize), 0))
                        }
                    }}
                    icon={faChevronLeft}
                    size="3x"
                    style={{ color: viewStartIndex > 0 ? "#26565B" : "#E5E7f0", cursor: "pointer"}} />
                <div style={{marginLeft: "10px", height: "50px"}}>
                    <Input
                        min={1}
                        max={52}
                        defaultValue={flipSize}
                        style={{height: "40px", width: "78px", fontSize: "20px", fontFamily: "Montserrat Alternates"}}
                        type="number" onBlur={(e) => {
                            e.target.value = Math.min(Math.max(1, e.target.value), 52);
                            setFlipSize(e.target.value);
                        }}>
                    </Input>
                </div>
                <FontAwesomeIcon
                    onClick={() => {
                        if(viewStartIndex < (totalNrOfColumns - nrInView)) {
                            let val = viewStartIndex + parseInt(flipSize);
                            setViewStartIndex(Math.min(totalNrOfColumns - nrInView, val));
                        }}}
                    icon={faChevronRight}
                    size="3x"
                    style={{marginLeft: "10px", color: viewStartIndex < (totalNrOfColumns - nrInView) ? "#26565B" : "#E5E7f0",  cursor: "pointer"}} />
            </Flex>
            <Tooltip id="weekJumper" />
        </div>
    )
}
